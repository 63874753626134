<template lang="">
  <div>
    <ContactForm />
    <div class="mt-5 mb-5 map" data-aos="fade" data-aos-duration="1000">
      <h3>{{ $t('find_us_on_map') }}</h3>
    </div>
    <TheLocationMap class="map-outside"></TheLocationMap>
  </div>
</template>
<script>
import ContactForm from '@/components/ContactView/ContactForm.vue'
import AboutMap from '@/components/AboutView/AboutMap.vue'
import TheLocationMap from '@/components/TheLocationMap.vue'
export default {
  components: {
    ContactForm,
    AboutMap,
    TheLocationMap
  }
}
</script>
<style lang="scss" scoped>
.map-outside {
  margin-bottom: 40px;
  margin-right: 40px;
}
.map {
  margin-left: 12.9%;
}

@media screen and (max-width: 567px) {
  .map {
    padding: 10px 0px 25px 10px !important;
    margin-bottom: 7px !important;
  }
}
</style>
