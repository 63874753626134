<template lang="">
  <div class="layout">
    <router-view class="mt-5" v-slot="{ Component }">
      <transition name="slide-fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
@import '@/styles/variables.scss';

.layout {
  display: flex;
  min-height: 65vh;
}

.content {
  margin: 0px;
  height: 80vh;
  width: 15%;
  margin-bottom: 5%;
  // background: linear-gradient(to right,
  //     rgb(2, 21, 37),
  //     rgba(8, 40, 67, 0.776),
  //     rgba(50, 138, 210, 0.806));
}

.items {
  width: 90%;
  height: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 10px;
  border-radius: 5px;

  a {
    width: 100%;
    padding: 10px 15px;
    color: rgb(63, 59, 80);
    text-decoration: underline;
  }

  .router-link-active {
    background-color: rgba(131, 238, 255, 0.129);
    color: rgb(63, 59, 80);
  }
}

@media only screen and (max-width: 1050px) {
  .content {
    width: 30%;
  }

  .items {
    padding: 0px 0px;
    margin: 0px;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-in;
}

.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-20px);
  opacity: 0;
}
</style>
