<template lang="">
  <page-layout>
    <div class="col-12">
      <h2 data-aos="slide-left" data-aos-duration="1000">
        {{ $t("products_hr_heading") }}
      </h2>
      <h4
        class="text-muted mb-4"
        data-aos="slide-right"
        data-aos-duration="1000"
      >
        {{ $t("products_hr_heading_2") }}
      </h4>
      <h5 class="mb-4" data-aos="slide-right" data-aos-duration="1000">
        {{ $t("products_hr_text") }}
      </h5>
    </div>
    <div class="col-12">
      <div v-for="(r, index) in resources" :key="r">
        <div
          class="d-inline-flex gap-3 align-items-center bg-light mb-2 w-100"
          data-aos="flip-right"
          data-aos-duration="1000"
        >
          <img class="ml-4" src="@/assets/images/check.png" alt="" />
          <div v-if="index == 2" class="mt-3 mb-3" data-aos="slide-right">
            <div>
              <h5>{{ $t(r.heading_1) }}</h5>
            </div>
          </div>

          <div v-else class="mt-3 mb-3" data-aos="slide-left">
            <div>
              <h5>{{ $t(r.heading_1) }}</h5>
              <h5>{{ $t(r.heading_2) }}</h5>
              <h5>{{ $t(r.heading_3) }}</h5>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/PageLayout.vue"
export default {
  components: {
    PageLayout
  },
  name: "TheHumanRescources",
  data() {
    return {
      resources: [
        {
          heading_1: "hr_heading_1_1",
          heading_2: "hr_heading_1_2",
          heading_3: "hr_heading_1_3"
        },
        {
          heading_1: "hr_heading_2_1",
          heading_2: "hr_heading_2_2",
          heading_3: "hr_heading_2_3"
        },
        { heading_1: "hr_heading_3_1" },
        {
          heading_1: "hr_heading_4_1",
          heading_2: "hr_heading_4_2",
          heading_3: "hr_heading_4_3"
        },
        {
          heading_1: "hr_heading_5_1",
          heading_2: "hr_heading_5_2",
          heading_3: "hr_heading_5_3"
        },
        {
          heading_1: "hr_heading_6_1",
          heading_2: "hr_heading_6_2",
          heading_3: "hr_heading_6_3"
        },
        {
          heading_1: "hr_heading_7_1",
          heading_2: "hr_heading_7_2",
          heading_3: "hr_heading_7_3"
        }
      ]
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
h2 {
  color: #25435b;
}
img {
  width: 45px;
  padding-right: 15px;
  padding-left: 10px;
}
</style>
