@author Halid Lihovac
<template>
  <div
    class="footer"
    data-aos="fade-in"
    data-aos-duration="500"
    data-aos-easing="ease-in"
    data-aos-anchor-placement="top-bottom"
  >
    <footer class="text-start text-dark text-lg-start text-muted">
      <section class="">
        <div class="footer-container text-md-start mt-5">
          <div class="d-flex mt-3 mobile-footer">
            <div class="col-md-3 mb-4 text-start">
              <RouterLink to="/">
                <img src="../assets/svg/logo_white.svg" alt="" />
              </RouterLink>
            </div>
            <div class="col-md-3 mx-auto mb-4 text-start">
              <RouterLink to="/products/purchase">
                <h6 class="text-uppercase fw-bold text-light mb-4">
                  {{ $t("footer_forspace_products") }}
                </h6>
              </RouterLink>
              <p class="text-reset">
                {{ $t("web_development") }}
              </p>
              <p class="text-reset">
                {{ $t("footer_mobile_app") }}
              </p>
              <p class="text-reset">
                {{ $t("footer_all_in_one") }}
              </p>
              <p class="text-reset">
                {{ $t("fintech") }}
              </p>
              <p class="text-reset">
                {{ $t("support") }}
              </p>

              <p class="text-reset">
                {{ $t("footer_controlling") }}
              </p>
            </div>
            <div
              class="col-md-3 mb-md-0 mb-4 uppercase text-end mobile-footer-bottom"
            >
              <RouterLink to="/contact">
                <h6 class="text-uppercase fw-bold text-light mb-4">
                  {{ $t("footer_contact") }}
                </h6>
              </RouterLink>
              <p>
                <font-awesome-icon icon="fa-solid fa-home" />
                Bulevar Meše Selimovića 16, 71000 Sarajevo, Bosna i Hercegovina
              </p>
              <p>
                <font-awesome-icon icon="fa-solid fa-envelope" />
                info@forspace.ba
              </p>
              <p>
                <font-awesome-icon icon="fa-solid fa-phone" />
                +387 33 770 987
              </p>
            </div>
          </div>
          <hr class="footer-divider" />
          <div class="footer-row footer-year">
            <div class="social-icons justify-content-center mt-2">
              <p>2023 Forspace. All rights reserved.</p>
            </div>

            <div class="footer-social-icons">
              <TheSocialIcons></TheSocialIcons>
            </div>
          </div>
        </div>
      </section>
    </footer>
  </div>
</template>
<script>
import TheSocialIcons from "./TheSocialIcons.vue";
export default {
  name: "TheFooter",
  components: {
    TheSocialIcons,
  },
  data() {
    return {
      selected: "bs",
      languages: [
        { language: "Bosanski", abr: "bs" },
        { language: "Engleski", abr: "en" },
        { language: "Njemački", abr: "de" },
      ],
    };
  },
  methods: {
    translatePage(lang) {
      this.selected = lang.abr;
      console.log(this.selected);
      this.language = lang.language;

      return (this.$i18n.locale = this.selected);
    },
  },
};
</script>
<style lang="scss" scoped>
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

* {
  color: rgb(196, 196, 196);
  animation: fadeInAnimation 1s;
}

img {
  width: 170px;
}

p {
  font-size: 13px;
}

.footer-container {
  margin-left: 13%;
  margin-right: 13.3%;
}

footer {
  padding: 10px 5px;
  background-color: rgba(8, 40, 67, 0.87);
  position: relative;
  bottom: 0;

  @media screen and (max-width: 567px) {
    height: 100%;
  }

  .square {
    width: 50px;
    height: 50px;

    border: 10px solid rgba(116, 116, 116, 0.363);
    position: absolute;
    top: 20%;
    right: 35%;
    transform: rotate(35deg);
  }

  .circle {
    width: 250px;
    height: 250px;
    border: 20px solid rgba(116, 116, 116, 0.363);
    position: absolute;
    top: 10%;
    left: 18%;
  }

  .round {
    width: 50px;
    height: 50px;
    border: 5px solid rgba(116, 116, 116, 0.363);
    border-radius: 50%;
    position: absolute;
    top: 20%;
    left: 42%;
  }

  .text-reset {
    text-transform: capitalize;
  }
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}

.footer-divider {
  margin: 10px 0;
  border: none;
  border-top: 1px solid white;
}

.footer-year {
  margin-top: 5px;
}

.footer-column {
  flex: 1;
}

.footer-row {
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
}

footer-social-icons {
  margin-left: 20px !important;
}

@media screen and (max-width: 567px) {
  .mobile-footer {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
  }

  .mobile-footer > div {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center;
  }

  .mobile-footer-bottom > p {
    text-align: center;
  }
}
</style>
