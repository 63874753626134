//@author Halid Lihovac
<template>
  <layout>
    <router-view />
  </layout>
</template>
<script>
import Layout from './components/Layout.vue'

export default {
  components: {
    Layout
  },
  data() {
    return {}
  },
  methods: {
    onHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>
