<template>
  <p
    style="
      width: 60px;
      height: 2px;
      background-color: rgba(8, 40, 67, 0.87);
      margin: auto;
    "
  ></p>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
