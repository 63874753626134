<template lang="">
  <page-layout>
    <div class="mb-4">
      <h2>{{ $t("products_production_header_one") }}</h2>
      <h4 class="text-muted mb-4">
        {{ $t("products_production_header_two") }}
      </h4>
      <h5 class="spacing">{{ $t("products_production_text") }}</h5>
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/PageLayout.vue"
export default {
  components: {
    PageLayout
  },
  name: "TheProduction"
}
</script>
<style lang="scss" scoped>
h2 {
  color: #25435b;
}

.spacing {
  margin-bottom: 200px;
}
</style>
