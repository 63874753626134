<template>
  <div class="the_next">
    <div class="the_next_heading">
      <h2 class="text-secondary mb-4">{{ $t("about_us_next_heading") }}</h2>
      <Line />
    </div>

    <div class="the_next_content">
      <div
        v-for="next in for_next"
        :class="next.class"
        :key="next"
        class="text-light d-flex justify-content-center align-items-center text-center"
        :data-aos="next.animate"
        data-aos-duration="1000"
      >
        <div>
          <h3>{{ $t(next.h3) }}</h3>
          <p>
            {{ $t(next.p) }}
          </p>
          <div class="read-more d-inline-flex justify-content-center">
            <BaseButton
              class="button-"
              :to="next.src"
              :name="$t(next.read_more)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Line from "@/particles/Line.vue";
import BaseButton from "../Forms/buttons/BaseButton.vue";
export default {
  name: "AboutUsNext",
  components: { Line, BaseButton },
  data() {
    return {
      for_next: [
        {
          src: "/digital-transformation-advantages",
          class: "item_one",
          h3: "about_us_next_overlay_left_heading",
          p: "about_us_next_overlay_left_paragraph",
          read_more: "read_more",
          animate: "fade-right",
        },
        {
          src: "/whats-an-api",
          class: "item_two",
          h3: "about_us_next_overlay_right_heading",
          p: "about_us_next_overlay_right_paragraph",
          read_more: "read_more",
          animate: "fade-left",
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.button-read {
  position: absolute;
  bottom: 10%;
}

.the_next {
  width: 100%;

  .the_next_heading {
    text-align: center;
  }

  .the_next_content {
    width: 74%;
    margin: 5% auto;
    padding-right: 0.4%;
    height: 370px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;

    .item_one {
      width: 100%;
      height: 100%;

      background: linear-gradient(
          to right,
          rgba(8, 40, 67, 0.6),
          rgba(8, 40, 67, 0.6),
          rgba(8, 40, 67, 0.6)
        ),
        url("@/assets/images/product_item_1.jpg") no-repeat center center/cover;
      position: relative;
      padding: 0 15px;
    }

    .item_two {
      width: 100%;
      height: 100%;
      background: linear-gradient(
          to right,
          rgba(8, 40, 67, 0.6),
          rgba(8, 40, 67, 0.6),
          rgba(8, 40, 67, 0.6)
        ),
        url("@/assets/images/product_item_2.jpg") no-repeat center center/cover;
      position: relative;
      padding: 0 15px;
    }

    .overlay {
      position: absolute;
      bottom: 0;
      width: 90%;
      margin: auto;
      height: 18vh;
      background-color: rgb(51, 51, 51);
      transform: translate(6%, 50%);
      padding: 20px;

      h3,
      p {
        color: white;
      }

      .read-more {
        padding: 10px 20px;
        background-color: rgb(255, 255, 255);
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }

  @media screen and (max-width: 567px) {
    height: 100%;

    .the_next_heading {
      text-align: center;
      position: relative;
      top: 150px;
    }

    .the_next_content {
      margin-top: 210px;
      width: 95%;
      flex-direction: column;
      height: 550px;

      // .overlay {
      //   height: 23vh;
      // }
    }

    .item_one,
    .item_two {
      width: 90%;
      margin-bottom: 5px;
    }
  }
}
</style>
