<template>
  <navigation-bar />
  <slot></slot>
  <BaseScrollToTop></BaseScrollToTop>
  <the-footer />
</template>
<script>
import NavigationBar from './NavigationTrack/NavigationBar.vue'
import TheFooter from './TheFooter.vue'
import BaseScrollToTop from './BaseScrollToTop.vue'
export default {
  components: { NavigationBar, TheFooter ,BaseScrollToTop}
}
</script>

<style></style>
