<template lang="">
  <page-layout>
    <h2>{{ $t("products_dms_heading_one") }}</h2>
    <h4 class="text-muted mb-4">{{ $t("products_dms_heading_two") }}</h4>
    <h5>
      {{ $t("products_dms_text_one") }}
    </h5>
    <h5>
      {{ $t("products_dms_text_two") }}
    </h5>

    <div class="col-12">
      <div v-for="product in products" :key="product">
        <div class="d-inline-flex pt-2">
          <div class="align-self-center">
            <img src="@/assets/images/check.png" alt="check" />
          </div>
          <div>
            <h5 class="pt-1">{{ $t(product.text) }}</h5>
          </div>
        </div>
      </div>
    </div>
    <br />
  </page-layout>
</template>
<script>
import PageLayout from "@/components/PageLayout.vue"
export default {
  components: { PageLayout },
  name: "TheDms",
  data() {
    return {
      products: [
        { text: "products_row_one" },
        { text: "products_row_two" },
        { text: "products_row_three" },
        { text: "products_row_four" },
        { text: "products_row_five" },
        { text: "products_row_six" },
        { text: "products_row_seven" },
        { text: "products_row_eight" },
        { text: "products_row_nine" }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
img {
  width: 35px;
  padding-right: 15px;
}
.subtext {
  font-size: 10px !important;
}
</style>
