<template lang="">
  <page-layout>
    <div class="d-flex justify-content-center orange-text-main">
      <div class="text-start">
        <h2>{{ $t("products_finance_heading_one") }}</h2>
        <h4 class="text-muted mb-5">
          {{ $t("products_finance_heading_two") }}
        </h4>
        <h5>
          {{ $t("products_finance_text_one") }}
        </h5>
        <div class="orange-box">
          <div class="white-text-main p-3 pb-5">
            <h5>
              <font-awesome-icon icon="fa-check" />
              {{ $t("products_finance_text_1") }}
            </h5>
            <h5>
              <font-awesome-icon icon="fa-check" />
              {{ $t("products_finance_text_2") }}
            </h5>
          </div>
        </div>
      </div>
    </div>

    <TheFinanceFixedAsset />
    <TheFinancePaydesk />
    <TheFinanceBankStatement />
    <TheFinanceTax />
  </page-layout>
</template>
<script>
import TheFinanceCards from "@/components/ProductsViews/TheFinance/TheFinanceCards.vue"
import TheFinancePaydesk from "@/components/ProductsViews/TheFinance/TheFinancePaydesk.vue"
import TheFinanceBankStatement from "@/components/ProductsViews/TheFinance/TheFinanceBankStatement.vue"
import TheFinanceTax from "@/components/ProductsViews/TheFinance/TheFinanceTax.vue"
import TheFinanceFixedAsset from "@/components/ProductsViews/TheFinance/TheFinanceFixedAsset.vue"
import PageLayout from "@/components/PageLayout.vue"

export default {
  name: "TheFinance",
  components: {
    TheFinanceCards,
    TheFinancePaydesk,
    TheFinanceBankStatement,
    TheFinanceTax,
    TheFinanceFixedAsset,
    PageLayout
  },
  data() {
    return {}
  },
  computed: {
    img_box() {
      if (this.$i18n.locale == "bs") {
        return [
          { url: require("@/assets/images/COMPARISON-WITH-PREVIOUS-YEAR.png") }
        ]
      } else {
        return [{ url: require("@/assets/images/transparence.png") }]
      }
    },
    planned() {
      if (this.$i18n.locale == "bs") {
        return [{ url: require("@/assets/images/planned-BH.png") }]
      } else {
        return [{ url: require("@/assets/images/planned.png") }]
      }
    },
    infograph() {
      if (this.$i18n.locale == "bs") {
        return [{ url: require("@/assets/images/infografika-bh.png") }]
      } else {
        return [{ url: require("@/assets/images/infografika.png") }]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.workflow-cover {
  width: 100%;
  background-color: $navy;
  border-radius: 20px;
}

.infografika {
  width: 100%;
  height: 100%;
}

h2 {
  color: #25435b;
}

.workflow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
</style>
