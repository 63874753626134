<template>
  <div>
    <page-layout>
      <div>
        <h2 class="mb-4 mt-4">{{ $t("about_us_news_link_one") }}</h2>
      </div>
      <h4 class="mb-4 title text-muted">
        {{ $t("about_us_news_link:hadding_more") }}
      </h4>
      <p>
        {{ $t("about_us_news_link_paragraf") }}
      </p>
      <!-- todo translations-->
      <div class="mt-5 mb-5">
        <base-link-button link="/services"></base-link-button>
      </div>
    </page-layout>
  </div>
</template>
<script>
import BaseLinkButton from "../BaseLinkButton.vue"
import PageLayout from "../PageLayout.vue"
export default {
  components: { BaseLinkButton, PageLayout },
  name: "ErpAllInOne"
}
</script>
<style lang="scss" scoped>
.title {
  color: black;
}
</style>
