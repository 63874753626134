<template>
  <div class="">
    <router-link
      :to="{
        path: link
      }"
    >
      <button class="button" type="submit">
        {{ '< ' + $t('back') }}
      </button>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'BaseLinkButton',
  props: {
    link: {
      type: String,
      default: () => '/'
    }
  }
}
</script>

<style scoped>
.button {
  position: relative;
  transition: all 0.5s;
  padding: 12px 40px;
  color: rgb(2, 2, 2);
  border: 1px solid rgb(0, 0, 0);
}

.button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.141);
  transition: all 0.3s;
}

.button:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}

.button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.763);
  transform: scale(1.2, 1.2);
}

.button:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}
</style>
