<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.container {
  max-width: 100%;
  margin-left: 12.5%;
  text-align: justify;

  margin-top: 8% !important;
  padding-right: 13.4%;
  @media screen and (max-width: 567px) {
    margin-left: 0%;
    margin-right: 0% !important;
    margin-top: 26% !important;
    text-align: start;
     padding-right: 3%;
  }
   @media screen and (max-width: 1024px) {
    margin-left: 0%;
    margin-right: 0% !important;
    padding-top: 2% !important;
    text-align: start;
     padding-right: 1.5%;
  }

   
}
</style>
