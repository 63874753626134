<template lang="">
  <page-layout>
    <div>
      <h2>{{ $t("products_purchase_header") }}</h2>
      <h4 class="text-muted mb-4">
        {{ $t("products_purchase_header_2") }}
      </h4>
      <h5>
        {{ $t("products_purchase_text") }}
      </h5>
    </div>
    <div class="container text-center mb-4 purchase-img">
      <img
        class="img-fluid"
        src="@/assets/images/nabava_animacija.gif"
        alt="animation"
      />
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/PageLayout.vue"
export default {
  components: {
    PageLayout
  },
  name: "ThePurchase"
}
</script>
<style lang="scss" scoped>
h2 {
  color: #25435b;
}

.purchase-img {
  @media screen and (max-width: 567px) {
    display: none;
  }
}
</style>
