<template>
  <div class="row">
    <div class="col-12">
      <div class="paydesk">
        <div class="paydesk white-text-main">
          <div class="paydesk-header text-start pt-3">
            <h2>{{ $t('products_paydesk_header_1') }}</h2>
            <h4 class="text-muted">{{ $t('products_paydesk_header_2') }}</h4>
          </div>
          <div class="d-flex justify-content-center paydesk-items pt-5">
            <div class="col-6">
              <div class="d-flex align-items-center mb-2" v-for="pay in paydesk_1" :key="pay">
                <h1 class="text-secondary margin-01 display-1" v-if="pay.num === '01'">
                  {{ pay.num }}
                </h1>
                <h1 class="text-secondary me-3 display-1" v-else>
                  {{ pay.num }}
                </h1>
                <h5 class="segeo-semi-bold desc">
                  {{ $t(pay.text) }}
                </h5>
              </div>
            </div>
            <div class="col-6">
              <div class="d-flex align-items-center mb-2" v-for="pay in paydesk_2" :key="pay">
                <h1 class="text-secondary me-3 display-1">{{ pay.num }}</h1>
                <h5 class="segeo-semi-bold desc">
                  {{ $t(pay.text) }}
                </h5>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFinancePaydesk',
  data() {
    return {
      paydesk_1: [
        {
          num: '01',
          text: 'products_paydesk_01_text'
        },
        {
          num: '02',
          text: 'products_paydesk_02_text'
        },
        { num: '03', text: 'products_paydesk_03_text' }
      ],
      paydesk_2: [
        {
          num: '04',
          text: 'products_paydesk_04_text'
        },
        {
          num: '05',
          text: 'products_paydesk_05_text'
        },
        {
          num: '06',
          text: 'products_paydesk_06_text'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.desc {
  margin-right: 65px !important;
}

.margin-01 {
  margin-right: 30px;
}

.paydesk-items {
  @media screen and (max-width: 567px) {
    flex-direction: column;
    justify-content: center;

    .col-6 {
      width: 90%;
      margin: auto;

      .d-flex {
        gap: 20px;
        /*  border: 1px solid #ccc; /* Dodan border na mobilnoj verziji */
        padding: 10px;
        border-radius: 20px;
      }
    }
  }
}

.display-1 {
  font-size: 35px;
}
</style>
