@author Halid Lihovac
<template>
  <div class="landing-page" style="position: relative; z-index: 1">
    <div class="div">
      <div class="header-text">
        <div class="head-1">
          <h2 class="header-erp">
            {{ $t("home_forspace_erp_accounting_text") }}
          </h2>
          <h2 class="design-develop">
            {{ $t("home_forspace_erp_accounting_text2") }}
          </h2>
        </div>
        <div class="head-2">
          <h5>
            {{ $t("header_secondary_text") }}
          </h5>
        </div>
        <div
          class="d-inline-flex justify-content-center align-items-center down-button"
          @click="scrollDown"
        >
          <font-awesome-icon icon="fa-solid fa-chevron-down" />
        </div>
        <div class="head-3">
          <BaseButton
            :to="routes.route"
            :name="routes.name"
            @click="window.scrollTo(0, 500)"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "@/components/Forms/buttons/BaseButton.vue";

export default {
  name: "HeaderHome",
  components: {
    BaseButton,
  },
  data() {
    return {
      routes: { route: "/contact", name: "presentation" },
    };
  },
  methods: {
    scrollDown() {
      window.scrollTo(0, 720);
    },
  },
  mounted() {
    window.addEventListener("scroll", (e) => {});
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/variables.scss";

.small-logo {
  width: 60px;
}

.rotate {
  animation: rotation 13s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.header-text {
  margin-top: 10vh;
}

@keyframes backgroundFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.company-title {
  color: orange;
}

.down-button {
  z-index: 5;
  position: relative;
  margin-top: 30px;
  padding: 11px;
  background-color: rgba(255, 255, 255, 0);
  border: 2px solid white;
  border-radius: 50%;
  transform: translate();
  cursor: pointer;
  font-size: 20px;
  color: white;
  transition: 1s ease-in-out;

  &:hover {
    opacity: 0.7;
    border: 2px solid orange;
    color: orange;
    transform: scale(1.1);
  }
}

.landing-page {
  width: 100%;
  height: 100vh;

  @media screen and (max-width: 567px) {
    padding-top: 5%;
    height: 100vh;
    text-align: left;
  }

  background: linear-gradient(
      to right,
      rgba(15, 71, 117, 0.8),
      rgba(27, 61, 92, 0.776),
      rgba(17, 65, 104, 0.6)
    ),
    url("@/assets/images/landing.jpg") no-repeat center center/cover;

  animation: backgroundFade 0.5s ease-in;

  .background {
    height: 500px;
    margin-top: 0%;
    border-radius: 2px;
  }

  .div {
    display: flex;
    justify-content: center;
    width: 60%;
    text-align: center;
    gap: 200px;
    height: 100%;
    margin: auto;
    padding-top: 15%;

    h2 {
      font-size: 50px;
      color: white;
      margin-top: 5vh;
      margin: 0;
      padding: 0;
      font-weight: bold;
    }

    .head-2 {
      color: white;
      margin: 2% 0%;
      row-gap: 15px;

      p {
        font-size: 35px;
        row-gap: 10px;
      }

      h5 {
        display: block;
        width: 80%;
        font-size: 20px;
        margin: auto;
        row-gap: 10px;
      }
    }

    .head-3 {
      padding-top: 10%;
      position: absolute;
      bottom: 5%;
      right: 13.5%;
    }

    h5 {
      color: #dddddd;
    }

    @media screen and (max-width: 567px) {
      width: 100%;
      justify-content: center;
      padding-left: 1%;
      padding-top: 30%;

      .head-1 {
        font-size: 45px;
      }

      .head-2 {
        text-align: center;

        h5 {
          display: none;
        }
      }

      .head-2 {
        small {
          width: 100%;
        }
      }

      .head-3 {
        position: unset;
        margin-top: 65px;
      }

      h2 {
        font-size: 25px;
      }

      .design-develop {
        font-size: 17px;
      }

      .background {
        display: none;
      }
    }
  }
}

.nav {
  height: 80px;
  background-color: rgb(247, 247, 247);
  display: none;

  .middle {
    width: 75%;
    height: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      width: 40%;
      height: 100%;
      padding-left: 0%;

      ul {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        li {
          padding: 20px 40px;
          color: rgb(117, 117, 117);
          transition: 0.2s;

          &:hover {
            color: white;
            cursor: pointer;
          }
        }
      }
    }

    .right {
      width: 20%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 1%;

      a {
        padding: 20px 40px;
        border: 1px solid gray;
        border-radius: 50px;
        color: gray;
        transition: 0.3s;

        &:hover {
          background-color: gray;
          color: white;
        }
      }
    }
  }

  @media screen and (max-width: 567px) {
    height: 140px;
    padding: 10px 0px;

    .middle {
      width: 100%;
      gap: 20px;
      flex-direction: column;

      .left {
        width: 100%;
        padding-left: 0%;

        ul {
          justify-content: center;
          gap: 40px;
          margin: 0;
          padding: 0;

          li {
            padding: 0px;
          }
        }
      }

      .right {
        width: 100%;
      }
    }
  }
}

.circle-1 {
  width: 450px;
  height: 500px;
  border: 20px solid rgba(255, 255, 255, 0.107);
  position: absolute;
  top: 20%;
  left: 23%;

  @media screen and (max-width: 567px) {
    width: 90vw;
    left: 0%;
  }
}
</style>
