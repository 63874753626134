<template>
  <div class="content-news">
    <slot></slot>
  </div>
</template>
<style lang="scss" scoped>
.content-news {
  width: 78%;
  margin: auto;
  margin-top: 80px;
  padding-top: 5%;
  padding-left: 2%;
  padding-right: 2.5%;
  min-height: 50vh;
  text-align: justify;
  @media screen and (max-width: 567px) {
    text-align: start;

    h4 {
      --bs-text-opacity: 1;
      color: #6c757d !important;
      margin-top: 8%;
      margin-bottom: 5%;
    }
    h2 {
      margin-top: 5%;
      margin-bottom: 5%;
    }
  }

  h4 {
    --bs-text-opacity: 1;
    color: #6c757d !important;
    margin-top: 2%;
    margin-bottom: 2%;
  }
  h2 {
    margin-top: 2%;
    margin-bottom: 2%;
  }
}
</style>
