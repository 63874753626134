@author Halid Lihovac
<template>
  <div class="form-div contact-container">
    <h3 class="title">{{ $t("contact_header") }}</h3>
    <form ref="form">
      <div class="d-flex form-flex">
        <div class="form-group">
          <div class="form-group-flex">
            <div class="inner-input-group">
              <div class="flex-item">
                <label for="name" :class="{ active: activeName }">{{
                  $t("contact_form_name")
                }}</label>
                <input
                  @input="validateName"
                  @click="nameActive"
                  @focusout="nameFocusOut"
                  type="text"
                  v-model="name"
                  id="name"
                  class="form-control-custom"
                  name="from_name"
                />
                <div class="form-group-flex">
                  <div class="flex-item">
                    <div class="warning" v-if="nameInvalid">
                      <small style="color: orangered">{{
                        $t("contact_form_nameInvalid")
                      }}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-item second">
                <label for="email" :class="{ active: activeEmail }">{{
                  $t("contact_form_email")
                }}</label>
                <input
                  @input="validateEmail"
                  @click="emailActive"
                  @focusout="emailFocusOut"
                  type="email"
                  v-model="email"
                  id="email"
                  class="form-control-custom"
                  name="from_email"
                />
                <div class="flex-item">
                  <div class="warning" v-if="emailInvalid">
                    <small style="color: orangered">{{
                      $t("contact_form_emailInvalid")
                    }}</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="inner-input-group">
              <div class="flex-item">
                <label for="company" :class="{ active: activeCompany }">{{
                  $t("company")
                }}</label>
                <input
                  @click="companyNameActive"
                  @focusout="companyNameFocusOut"
                  type="text"
                  v-model="companyName"
                  id="company"
                  class="form-control-custom"
                  name="company"
                />
              </div>
              <div class="flex-item second fourth">
                <label
                  for="company-url"
                  :class="{ active: activeCompanyUrl }"
                  >{{ $t("company_url") }}</label
                >
                <input
                  @click="companyUrlActive"
                  @focusout="companyURlFocusOut"
                  type="text"
                  v-model="companyUrl"
                  id="company-url"
                  class="form-control-custom"
                  name="company-url-form"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="text" :class="{ active: activeMessage }">{{
            $t("contact_form_text")
          }}</label>
          <textarea
            @input="validateMessage"
            @click="messageActive"
            @focusout="messageFocusOut"
            name="user_message"
            id="text"
            cols="30"
            max-cols="50"
            rows="6"
            v-model="message"
            class="form-control-custom"
          ></textarea>
          <div class="warning" v-if="messageInvalid">
            <small style="color: orangered">{{
              $t("contact_form_messageInvalid")
            }}</small>
          </div>
        </div>
      </div>
      <div class="form-group d-flex gap-3 submit">
        <button class="read-more" type="submit" @click.prevent="submitForm">
          {{ $t("submit") }}
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import BaseButton from "../Forms/buttons/BaseButton.vue";
import emailjs from "@emailjs/browser";

export default {
  name: "ContactForm",
  components: { BaseButton },
  data() {
    return {
      routes: { route: "form", name: "form" },
      activeName: false,
      activeEmail: false,
      activeCompany: false,
      activeCompanyUrl: false,
      activeMessage: false,
      email: "",
      name: "",
      companyUrl: "",
      companyName: "",
      message: "",
      emailInvalid: false,
      nameInvalid: false,
      messageInvalid: false,
    };
  },
  methods: {
    messageFocusOut() {
      this.message.length > 0
        ? (this.activeMessage = true)
        : (this.activeMessage = false);
    },
    messageActive() {
      this.activeMessage = true;
    },
    emailFocusOut() {
      this.email.length > 0
        ? (this.activeEmail = true)
        : (this.activeEmail = false);
    },
    emailActive() {
      this.activeEmail = true;
    },
    nameActive() {
      this.activeName = true;
    },

    companyNameActive() {
      this.activeCompany = true;
    },
    companyUrlActive() {
      this.activeCompanyUrl = true;
    },
    nameFocusOut() {
      this.name.length > 0
        ? (this.activeName = true)
        : (this.activeName = false);
    },
    companyNameFocusOut() {
      this.companyName.length > 0
        ? (this.activeCompany = true)
        : (this.activeCompany = false);
    },
    companyURlFocusOut() {
      this.companyUrl.length > 0
        ? (this.activeCompanyUrl = true)
        : (this.activeCompanyUrl = false);
    },

    submitForm(event) {
      this.validateMessage();
      this.validateName();
      this.validateEmail();
      if (!this.emailInvalid && !this.nameInvalid && !this.messageInvalid) {
        this.sendEmail();
      } else {
        this.$toast.error(this.$t("fill_the_form"));
      }
    },
    sendEmail() {
      console.log(this.$refs.form);
      emailjs
        .sendForm(
          "service_95gkufz",
          "template_jwe8dym",
          this.$refs.form,
          "7-4dFatCCTacDJXk0"
        )
        .then(
          (result) => {
            this.$toast.success(this.$t("email_sent"));
            (this.email = ""),
              (this.name = ""),
              (this.message = ""),
              (this.companyName = ""),
              (this.companyUrl = "");
          },
          (error) => {
            this.$toast.error(this.$t("error"));
          }
        );
    },
    validateName() {
      this.name.trim() === ""
        ? (this.nameInvalid = true)
        : (this.nameInvalid = false);

      this.active_name = true;
    },
    validateEmail() {
      if (
        this.email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) &&
        this.email.trim() !== ""
      ) {
        this.emailInvalid = false;
      } else {
        this.emailInvalid = true;
      }
    },
    validateMessage() {
      this.message.trim() === ""
        ? (this.messageInvalid = true)
        : (this.messageInvalid = false);
    },
    showMap() {
      if (this.$store.getters.getMapState) {
        this.$store.commit("setMapState", false); // vuex store must be used like this with getters and mutations and separate modules!!
      } else {
        this.$store.commit("setMapState", true);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/variables.scss";

.read-more {
  position: relative;
  transition: all 0.5s;
  padding: 12px 40px;
  color: rgb(2, 2, 2);
  border: 1px solid rgb(0, 0, 0);
  background-color: transparent;
}

.read-more::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.141);
  transition: all 0.3s;
}

.read-more:hover::before {
  opacity: 0;
  transform: scale(0.5, 0.5);
}

.read-more::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s;
  border: 1px solid rgba(0, 0, 0, 0.763);
  transform: scale(1.2, 1.2);
}

.read-more:hover::after {
  opacity: 1;
  transform: scale(1, 1);
}

h2 {
  text-align: center;
}

.map-wrapper {
  padding: 0px;
  margin: 0px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.form-div {
  align-items: center;
  margin: auto;
  padding: 25px;

  form {
    margin-top: 5%;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0% 100%;
    color: darkgray;
    transform: translate(7px, 20px);
    transition: transform 0.2s ease-out, color 0.2s ease-out;
  }

  h3 {
    border-radius: 5px;
  }

  .form-group {
    width: 100%;
    position: relative;
    margin: 10px 10px 20px 10px;

    .form-group-flex {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      gap: 20px;

      .flex-item {
        position: relative;
        width: 100%;
      }
    }

    .form-control-custom {
      background: rgba(218, 215, 215, 0.552);
      width: 100%;
      margin: 10px 0px 20px 0px;
      padding: 10px 2px 10px 2px;
      border: none;
      border-radius: 2px;
    }

    input,
    textarea {
      &:focus {
        outline: none;
      }
    }
    .form-flex {
      flex-direction: column;
    }
    .active {
      transform: translate(0px, -13px) scale(0.8);
      color: gray;
      transition: transform 0.2s ease-out, color 0.2s ease-out;
    }
  }

  @media screen and (max-width: 567px) {
    width: 100%;
    height: 100%;
    margin-left: 0% !important;
    margin-right: 0% !important;
    padding-left: 4% !important;
    padding-right: 4% !important;
    padding-top: 25% !important;

    h3 {
      margin-bottom: 30px !important;
    }
    .form-flex {
      flex-direction: column;
    }
    .form-group-flex {
      flex-direction: column;
    }

    .form-group {
      margin: 0;

      .form-control-custom {
        margin: 15px 0px 10px 0px;
      }
    }

    label {
      transform: translate(6px, 27px);
    }

    .active {
      transform: translate(0px, -11px) scale(0.8) !important;
    }

    .inner-input-group {
      margin-top: -17px !important;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .second {
      margin: 0 !important;
      align-self: flex-start;
    }

    .flex-item {
      margin-bottom: 0px;
    }

    .fourth {
      margin-bottom: 25px !important;
    }
  }
}

input {
  background-color: white !important;
  border-bottom: 1px solid rgb(207, 205, 205) !important;
}

textarea {
  height: 100%;
  padding: 15px !important;
  background-color: rgb(243, 243, 243) !important;
}

.inner-input-group {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.inner-input-group > div {
  margin-right: 20px;
}

.contact-container {
  margin-left: 11%;
  margin-right: 12.7%;
  padding-top: 8%;
}

.title {
  margin-left: 0.7% !important;
}
</style>
