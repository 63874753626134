<template>
  <li v-for="(navItem, index) in routes" :key="navItem">
    <router-link
      v-if="navItem.name !== 'contact'"
      :to="navItem.to"
      class="nav"
      :class="[{ sidenav_r: sidenav_class }, { navy: navy }]"
      @click="showSideNav"
      @mouseover="giveindex(index)"
      ref="mainRoutes"
    >
      <div class="d-flex justify-content-center">
        <div
          class="link-title"
          @click="
            checkIsRouteActive(navItem.name);
            checkIsProductActive(navItem.name);
          "
          @mouseover="showDropDown(navItem.name, $event)"
          :class="navItem.name === 'products' ? 'products-class' : ''"
        >
          {{ $t(navItem.name) }}
          <div
            v-if="navItem.name === 'products' && isDropDownOpen && $isMobile"
            class="content"
            ref="products"
            @mouseleave="fadeOutDropDown"
          >
            <div class="items" data-aos="fade" data-aos-duration="800">
              <div class="marker"></div>
              <router-link
                to="/products/purchase"
                @click="
                  $router.push('/products/purchase');
                  checkIsRouteActive('purchase');
                "
                data-aos="fade"
                data-aos-duration="800"
                >{{ $t("purchase") }}</router-link
              >
              <router-link
                to="/products/sales"
                @click="
                  $router.push('/products/sales');
                  checkIsRouteActive('sales');
                "
                data-aos="fade"
                data-aos-duration="800"
                >{{ $t("sales") }}</router-link
              >
              <router-link
                to="/products/production"
                @click="
                  $router.push('/products/production');
                  checkIsRouteActive('production');
                "
                data-aos="fade"
                data-aos-duration="800"
                >{{ $t("production") }}</router-link
              >
              <router-link
                to="/products/warehouse"
                @click="
                  $router.push('/products/warehouse');
                  checkIsRouteActive('warehouse');
                "
                data-aos="fade"
                data-aos-duration="800"
                >{{ $t("warehouse") }}</router-link
              >
              <router-link
                to="/products/dms"
                @click="
                  $router.push('/products/dms');
                  checkIsRouteActive('dms');
                "
                data-aos="fade"
                data-aos-duration="800"
                >{{ $t("dms") }}</router-link
              >
              <router-link
                to="/products/finance"
                @click="
                  $router.push('/products/finance');
                  checkIsRouteActive('finance');
                "
                data-aos="fade"
                data-aos-duration="800"
                >{{ $t("finance") }}</router-link
              >
              <router-link
                to="/products/human-resources"
                @click="
                  $router.push('/products/human-resources');
                  checkIsRouteActive('hr');
                "
                data-aos="fade"
                data-aos-duration="800"
                >{{ $t("hr") }}</router-link
              >
            </div>
          </div>
        </div>
        <div
          :class="
            navItem.name === 'products' &&
            $isMobile() &&
            !this.getIsProductsActive()
              ? 'active'
              : 'inactive'
          "
          @click="
            checkIsProductActive('products');
            checkIsRouteActive('products');
          "
          class="arrow"
        >
          <img
            src="@/assets/svg/arrow-down.svg"
            data-aos="fade"
            data-aos-duration="300"
          />
        </div>
        <div
          :class="
            navItem.name === 'products' &&
            $isMobile() &&
            this.getIsProductsActive()
              ? 'active'
              : 'inactive'
          "
          @click="
            checkIsProductActive('products');
            checkIsRouteActive('products');
          "
          class="arrow-up"
        >
          <img
            src="@/assets/svg/arrow-down.svg"
            data-aos="fade"
            data-aos-duration="300"
          />
        </div>
      </div>
    </router-link>
    <router-link
      v-if="navItem.name === 'contact' && !$isMobile()"
      :to="'/contact'"
      class="nav"
      :class="[{ sidenav_r: sidenav_class }, { navy: navy }]"
      @click="showSideNav"
      @mouseover="giveindex(5)"
      ref="mainRoutes"
    >
      <div class="link-title" @click="checkIsRouteActive('contact')">
        {{ $t("contact") }}
      </div>
    </router-link>
  </li>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  name: "Links",
  props: {
    routes: { type: Array },
    sidenav_class: { type: Boolean },
    showSideNav: { type: Function },
  },
  data() {
    return {
      visible: false,
      language: "Bosanski",
      selected: "bs",
      languages: [
        { language: "Bosanski", abr: "bs" },
        { language: "Engleski", abr: "en" },
        { language: "Njemački", abr: "de" },
      ],
      navy: false,
      isDropDownOpen: false,
      subRoutes: [
        "sales",
        "purchase",
        "production",
        "products",
        "warehouse",
        "dms",
        "finance",
        "hr",
      ],
      counter: 0,
      isExpanded: false,
    };
  },
  computed: {
    fetchDropData() {
      return this.languages;
    },
  },
  methods: {
    ...mapMutations({
      setIsProductsActive: "setIsProductsActive",
      setIsProductsActive: "setIsProductsActive",
      setSideNav: "setSideNav",
    }),
    ...mapGetters({ getIsProductsActive: "getIsProductsActive" }),

    giveindex(index) {
      this.$emit("index", index);
    },
    translatePage(lang) {
      this.selected = lang.abr;
      this.language = lang.language;

      return (this.$i18n.locale = this.selected);
    },
    toggleActive(index, bin) {
      if (bin) {
        this.routes.forEach((item) => {
          item.isActive = index == item.id ? true : false;
        });
      } else {
        this.sidenavRoutes.forEach((item) => {
          item.isActive = index == item.id ? true : false;
        });
      }
    },
    displayContent(index) {
      this.contents.forEach((item) => {
        item.state = index == item.id ? true : false;
      });

      this.toggleActive(index, false);
    },
    showLanguages() {
      if (!this.visible) {
        this.visible = true;
      }
    },
    showDropDown(routeName, ev) {
      const activeSubRoute = this.$router.currentRoute._value.name;
      if (routeName === "products") {
        this.isDropDownOpen = true;
        if (this.subRoutes.includes(activeSubRoute)) {
          this.$router.push(`/products/${activeSubRoute}`);
        }
      } else {
        this.isDropDownOpen = false;
      }
    },
    fadeOutDropDown() {
      this.isDropDownOpen = false;
    },

    checkIsRouteActive(routeName) {
      //counter is added due to rendering issues.
      //it controls how many times it is allowed to go through the function per click
      if (this.counter > 0 && this.counter < 2) {
        this.counter = 0;
      }
      this.counter++;
      if (this.subRoutes.includes(routeName) && this.counter == 1) {
        this.$refs.mainRoutes[3].$el.classList.add("artificially-active");
      } else if (!this.subRoutes.includes(routeName) && this.counter == 1) {
        this.$refs.mainRoutes[3].$el.classList.remove("artificially-active");
      }
    },
    checkIsProductActive(name) {
      if (name === "products") {
        if (!this.getIsProductsActive()) {
          this.setSideNav(false);
          this.setIsProductsActive(true);
        } else {
          this.setSideNav(false);
          this.setIsProductsActive(false);
        }
      } else {
        this.setIsProductsActive(false);
        this.setSideNav(true);
      }
    },
  },
  mounted() {
    this.isProductsSubmenuActive = false;
    window.addEventListener("scroll", () => {
      if (window.scrollY > 40) {
        this.navy = true;
      } else {
        this.navy = false;
      }
    });
    window.addEventListener("click", (ev) => {
      const classList = ev.target.className.split(" ");
      if (classList.includes("products-class")) {
        this.isDropDownOpen = true;
      } else {
        this.isDropDownOpen = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.link-title {
  white-space: nowrap;
}

.arrow > img {
  height: 17px;
}

.arrow-up {
  transform: rotate(180deg);
  top: -2px;
}

.arrow-up > img {
  height: 17px;
  position: relative;
  left: -7px;
  top: -2px;
}

.arrow {
  position: relative;
  left: 7px;
}

li {
  list-style: none;
  position: relative;

  svg {
    width: 17px;
  }

  .nav {
    color: rgb(255, 255, 255);
    font-size: 20px;
    border-radius: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .underline {
      width: 100%;

      height: 3px;
    }

    &:hover {
      color: tomato;
      width: 100%;
      transition: width 0.3s ease-out;
    }
  }

  .navy {
    color: #333;
  }

  .sidenav {
    font-size: 23px;

    &:hover {
      cursor: pointer;
    }
  }

  .sidenav_r {
    color: rgb(156, 156, 156);
  }

  .router-link-active {
    color: tomato;

    &:hover {
      color: tomato;
      width: 100%;
      transition: width 0.3s ease-out;
    }
  }

  .router-link {
    text-align: left;
  }
}

@media screen and (max-width: 1000px) {
  .content {
    display: none;
  }
}

@media screen and (max-width: 567px) {
  li {
    svg {
      display: none;
    }
  }

  .products-class {
    margin-left: 20px;
  }
}

.inactive {
  display: none;
}

.active {
  display: block;
}

.search {
  font-size: 20px;
}

.box {
  overflow: hidden;
  color: white;
  font-size: 20px;
  padding: 0px 10px;

  &:hover {
    cursor: pointer;
  }

  .menu {
    padding: 5px;
    background-color: white;
    color: #333;
    display: none;
    position: absolute;
    transform: translate(0%, 5%);

    p {
      padding-right: 30px;
      font-size: 17px;

      &:hover {
        background-color: rgb(190, 213, 255);
        cursor: pointer;
      }
    }
  }

  .visible {
    display: block;
  }
}

.items {
  margin-top: 3px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.97);
  padding: 20px 0px 0px 0px;
  width: 200px;
  color: rgb(63, 99, 146);
  border-left: 1px solid rgb(226, 225, 225);
  border-right: 1px solid rgb(226, 225, 225);
  border-bottom: 1px solid rgb(226, 225, 225);
  border-radius: 0 4px 4px 4px;

  color: #7a7a7a;

  & :hover {
    color: rgb(46, 78, 148);
  }

  a {
    color: rgb(114, 114, 114);
    margin: 3px 2px 12px 20px;
    font-size: 15px;
  }

  .router-link-active {
    color: rgb(90, 79, 59);
  }
}

.marker {
  position: absolute;
  top: 0px;
  left: 25px;
  background-color: rgba(53, 93, 145, 0.8);
  width: 35px;
  height: 4px;
}

.artificially-active > div {
  color: tomato;
}
</style>
