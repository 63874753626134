export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appointment"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Team"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact "])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reserve"])},
  "nav_news_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do companies need DMS?"])},
  "nav_news_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-based ERP system"])},
  "nav_news_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Integration"])},
  "nav_news_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalization"])},
  "nav_news_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can an ERP system help a small business grow?"])},
  "nav_news_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signs that your business needs a more advanced software solution"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "faq-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotions"])},
  "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want a presentation"])},
  "about_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want a presentation"])},
  "widget_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "widget_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace Solutions"])},
  "widget_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By digitizing your business processes with Forspace's ERP program, we want to enable you to run the company more efficiently, and continuously monitor, plan and control business activities at every moment, and in this connection prevent mistakes that would otherwise lead to complication."])},
  "widget_small_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For all questions"])},
  "widget_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33 770 991"])},
  "widget_icon_one_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experts"])},
  "widget_icon_two_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successful Projects"])},
  "widget_icon_three_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Years of experience"])},
  "home_icon_square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quick and easy solutions"])},
  "home_icon_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your company under one roof"])},
  "home_icon_companies_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central process management."])},
  "home_icon_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web-based ERP system"])},
  "home_icon_cloud_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, WEB based, MySQL."])},
  "home_icon_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All processes in one stream"])},
  "home_icon_config_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapping real processes to digital ones."])},
  "home_icon_circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modern concept, simple design"])},
  "home_icon_circle_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports easy integration with other solutions."])},
  "home_forspace_erp_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP & Accounting"])},
  "home_forspace_erp_accounting_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Decisions based on facts, not assumptions!"])},
  "recension_primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviews"])},
  "recension_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What our clients say about us"])},
  "recension_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We proudly highlight all our clients and build partnership relationships for mutual satisfaction. If some of our clients are not yet added to the list, it does not mean that we value them less or that they are less important to us."])},
  "recension_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have any questions?"])},
  "recension_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us."])},
  "about_us_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome"])},
  "services_grid_div4_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment systems"])},
  "services_grid_div1_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-Systems"])},
  "services_grid_div2_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS Systems"])},
  "services_grid_div3_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS Systems"])},
  "services_grid_div3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS (Warehouse Management System) is a business program for warehouse management that aims to automate, simplify and rationalize work tasks and activities within the warehouse, thereby increasing productivity and reducing the possibility of error to a minimum"])},
  "about_us_news_link:hadding_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" A selection of our products or a 'custom made' solution, which is best? We decide together with you!"])},
  "about_us_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti, atque amet architecto vitae sapiente explicabo."])},
  "about_us_news_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["News"])},
  "services_grid_div1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP (Enterprise Resource Planning) system is a business program that enables companies to plan their resources and manage the entire business and covers all process flows of service, sales or production activities."])},
  "about_us_news_link_paragraf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP (Enterprise Resource Planning) is a computer program that allows companies to plan and manage their resources. It consists of modules that digitally monitor current processes and mostly cover all process flows in the company (e.g. from customer order to delivery) . ERP has the concept of all business under one roof and this enables the flow of information in one platform and the consistency of information and reports from the bottom to the top. It also allows companies to digitally transform and run their processes through ERP without significant use of other tools and software. Taking all with this in mind, ERP is the main tool with which you manage today's business, whether you are in manufacturing, sales, service or anything else. While it is not a question of the software itself, there are many challenges in the implementation itself. It is an extensive and complex process, and there are numerous cases of failed implementations. Based on our experience in various industries and numerous implementations, the Forspace team can carry out a successful ERP implementation together with you!'"])},
  "about_us_news_link_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP unique all-in-one cloud software solution for standardization and digitization of business processes"])},
  "about_us_news_link_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why do companies need DMS?"])},
  "about_us_news_link_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Running a business can be stressful in many ways, and a special challenge in this regard is the way the company manages documents."])},
  "about_us_news_link_heading_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why is a web-based business program the right choice?"])},
  "about_us_news_right_flex_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type of entrepreneur are you?"])},
  "about_us_news_right_flex_one_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneurship"])},
  "about_us_news_right_flex_one_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An entrepreneur is usually considered an innovator, a source of new ideas, goods, services and/or business procedures."])},
  "about_us_news_right_flex_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signs Your Business Needs a More Advanced Software Solution"])},
  "about_us_news_right_flex_two_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Solutions"])},
  "about_us_news_right_flex_two_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most companies try to improve overall performance by implementing ERP software."])},
  "about_us_news_right_flex_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can ERP help a small business grow?"])},
  "about_us_news_right_flex_three_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP system"])},
  "about_us_news_right_flex_three_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise resource planning system, so-called ERP, manages all business segments, including production, procurement, sales, distribution, accounting, human resources, management system.."])},
  "about_us_news_right_flex_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How does digital transformation make accountants' jobs easier and faster?"])},
  "about_us_news_right_flex_four_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Transformation"])},
  "about_us_news_right_flex_four_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionals in all industries are facing major changes, many of which are driven by new technologies."])},
  "about_us_next_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])},
  "about_us_next_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace Preview"])},
  "about_us_next_overlay_left_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits of Digital Transformation"])},
  "about_us_next_overlay_left_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The acceleration of the digital world requires adaptation of organizations, which is why most companies in the world are working on digital transformation..."])},
  "about_us_next_overlay_right_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is the API?"])},
  "about_us_next_overlay_right_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The term “API” is used a lot in web development. Updates to the operating system, web browsers, and applications often announce new APIs for developers. So, what is an API and how do developers.."])},
  "about_us_choose_small_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why choose us"])},
  "about_us_choose_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business from one source"])},
  "about_us_additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In our company"])},
  "about_us_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The Forspace team provides unreserved support at all stages of use, and we are especially proud of our after-sales service, because by accepting this software, you can count on our constant engagement and contact. We are the first domestic brand on the ERP software market with a sign of German technology, which allows reducing the use of written documentation, saving time and money, and represents a completely customized and understandable software solution for the end user."])},
  "about_check_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports easy integration with other solutions."])},
  "about_check_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapping real processes to digital ones."])},
  "about_check_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central process management."])},
  "about_us_video_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace Preview"])},
  "about_us_video_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Watch our short video"])},
  "about_us_nums_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace in numbers"])},
  "about_us_nums_established": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Established"])},
  "about_us_nums_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
  "about_us_nums_employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employed"])},
  "about_us_client_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"When it comes to accounting automation and full customization to our needs, look no further than the Forspace Solutions team. Their competitive landscape is steep, but Forspace makes it achievable.\" "])},
  "about_us_client_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"When it comes to accounting automation and full customization to our needs, look no further than the Forspace Solutions team. Their competitive landscape is steep, but Forspace makes it achievable.\" "])},
  "about_us_client_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"When it comes to accounting automation and full customization to our needs, look no further than the Forspace Solutions team. Their competitive landscape is steep, but Forspace makes it achievable.\" "])},
  "about_us_reserve_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Book an appointment"])},
  "about_us_reserve_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to book an appointment with our team for more information about our products or about processes in your company that require a software solution and consulting. We may have just the perfect solution for you based on our best practices and experiences."])},
  "products_purchase_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
  "products_purchase_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Forspace procurement module allows you to easily order everything you need for your daily business. The system takes care of the correctness of the process: who should approve the order? (an automatic workflow for approving budget overruns by the responsible person is also enabled here, so that the process is not at a standstill). Allows multiple approval of predefined persons."])},
  "products_sales_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wholesale"])},
  "products_sales_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retail - Wholesale"])},
  "products_sales_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Our standardized wholesale or retail module will satisfy all your business needs. The retail module is additionally adapted to work with POS devices and all other legal needs and obligations. Contact us for more details."])},
  "products_production_header_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "products_production_header_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How to get production under control?"])},
  "products_production_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The production module is the biggest challenge in the implementation of an ERP system. With that comes the need for a cultural change within the organization. Employees need to adapt to the various changes and process optimizations that are coming. ERP systems are built on best practices in different industries, but once the software is not very well adapted to the processes that a particular company has. And in those cases we have a solution through custom-made adjustments that we implement quickly and efficiently. The Forspace team is not only with you during the implementation, we work with you afterwards and share knowledge about procedures and methodologies with which you will continue to successfully use the EPR system."])},
  "products_warehouse_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "products_warehouse_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Central point of logistics activities"])},
  "products_warehouse_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Warehousing is the art of moving and keeping materials in warehouse and production. With this module, we monitor the movement of materials, semi-finished products and products in a transparent way and in accordance with the plan and defined processes. It includes physical space and placement of materials, as well as software monitoring of all activities and transactions."])},
  "products_warehouse_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of the main functionalities are:"])},
  "products_warehouse_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ability to create one or more warehouses within the company, with multiple storage levels."])},
  "products_warehouse_text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defining warehouse cells by the types of items that will be located on those cells (cells of materials, goods, spare parts and similar)"])},
  "products_warehouse_text_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt of items from transit, scanners or manually, to the appropriate warehouse cells, with full tracking of each transaction via item barcode."])},
  "products_warehouse_text_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic creation of purchase orders for received items (connection of incoming invoices and delivery notes). - Printing of labels and barcodes of items."])},
  "products_warehouse_text_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moving items between warehouse cells, with accompanying reports. Insight into warehouse conditions with information such as: ident, description, quantity, price, supplier, batch, warehouse cell and similar."])},
  "products_warehouse_text_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory. Insight into the history of movement of warehouse items with a transparent display of all necessary information."])},
  "products_dms_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Management System (DMS)"])},
  "products_dms_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affordable and practical"])},
  "products_dms_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" The Document Management System (DMS) is used to manage all documents in the company. It allows storing all types of documents using the same principle as we store physical documentation. If we receive physical documentation and classify it in binders, we do the same in DMS using electronic binders ."])},
  "products_dms_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This is only one small part of the functionality of our DMS system, which is fully integrated with the financial flow of the documents we store, so that we enter all important data (customers, suppliers, amounts, document numbers, etc.) into the system we still use them for accounting and reporting."])},
  "products_row_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering incoming invoices and starting the approval process through a predefined workflow"])},
  "products_row_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Issuing outgoing invoices and preparing the necessary documents"])},
  "products_row_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ability to clone recurring outgoing invoices."])},
  "products_row_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ability to link incoming invoices to approved orders."])},
  "products_row_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic check of budget consumption"])},
  "products_row_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration with POS terminals"])},
  "products_row_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Goods reservation (inbound and outbound warehouse transactions)"])},
  "products_row_eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventory tracking - detailed information about the flow of goods based on invoice items"])},
  "products_row_nine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic accounting based on predefined booking profiles"])},
  "products_finance_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparent and accurate"])},
  "products_finance_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budget Request"])},
  "products_finance_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our Finance and Accounting module is built on the basis of the world's best practices, where you plan and spend according to the plan. Also, compare the planned with the realized. Forspace makes sure that everything is accurate and transparent."])},
  "products_finance_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of the company's budget according to the types of expenses"])},
  "products_finance_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of the company's budget according to cost centers and types of costs"])},
  "products_finance_workflow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Work with flow or Work according to process flow"])},
  "products_finance_workflow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incoming documents - Steps during invoice servicing"])},
  "products_finance_assets_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixed Assets"])},
  "products_finance_assets_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procurement, use and depreciation of fixed assets"])},
  "products_paydesk_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cashier"])},
  "products_paydesk_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt and Payout"])},
  "products_paydesk_header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Everything you need to manage your cash register accurately, transparently, and in line with approvals and budget."])},
  "products_paydesk_01_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering and confirming the receipt of cash according to a predefined workflow"])},
  "products_paydesk_02_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entering and verifying cash payments according to a predefined workflow"])},
  "products_paydesk_03_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cash receipt and payment calculation"])},
  "products_paydesk_04_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View cash register and generate document"])},
  "products_paydesk_05_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View cashier journal entries and generate documents"])},
  "products_paydesk_06_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic posting of daily transactions to the general ledger based on the accounts assigned to the cash desk"])},
  "products_bank_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bank Statements"])},
  "products_bank_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic processing and posting"])},
  "products_bank_1_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import in different formats"])},
  "products_bank_1_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import of electronic bank statements in different formats"])},
  "products_bank_1_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electronic bank format"])},
  "products_bank_1_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic conversion programs for special electronic bank formats"])},
  "products_bank_1_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workflow"])},
  "products_bank_1_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic posting and processing of bank statements"])},
  "products_bank_2_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General Journal Entries"])},
  "products_bank_2_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic journal entries from bank statement"])},
  "products_bank_2_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partner Register"])},
  "products_bank_2_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy access to all information about partner banks and bank accounts"])},
  "products_bank_2_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Master data"])},
  "products_bank_2_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ability to recognize bank statement item types and associated accounts"])},
  "products_tax_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT RETURN"])},
  "products_tax_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["General books of invoices issued and received"])},
  "products_tax_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT REFUND DOCUMENT"])},
  "products_tax_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic VAT refund document creation."])},
  "products_tax_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT POSTING"])},
  "products_tax_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic posting of VAT"])},
  "products_tax_heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTROL REPORTS"])},
  "products_tax_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Books of issued and received invoices"])},
  "products_hr_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources and Payroll"])},
  "products_hr_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using the Forspace HR module and the PAYROLL CALCULATION module, your company has the ability to keep records of employees according to the most modern standards. From customizable data in the personnel file, business career history, and the like, to an automated contract creation module that greatly facilitates the work of your HR employees. For the payroll module, you have numerous benefits and automations, as well as regular settings that meet the standards of legislation and GDPR."])},
  "hr_heading_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register of employees"])},
  "hr_heading_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records of family members, non-residents/foreign persons, work permits, various certificates"])},
  "hr_heading_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameters for salary calculation"])},
  "hr_heading_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monitoring various absences"])},
  "hr_heading_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Record of working hours"])},
  "hr_heading_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee Health Information"])},
  "hr_heading_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records on indebtedness, debt repayments, child support"])},
  "hr_heading_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible definition of calculation settings - salary calculation profile"])},
  "hr_heading_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different types of calculations based on the type of employment"])},
  "hr_heading_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different methods for types of calculations depending on the type of institution"])},
  "hr_heading_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Different methods for calculating the basic salary"])},
  "hr_heading_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter, edit or delete data for multiple employees simultaneously using importers"])},
  "hr_heading_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The possibility of recalculation for an individual employee or for all employees"])},
  "hr_heading_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Managing short- and long-term loans and other deductions"])},
  "hr_heading_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Management of additional payments (bonuses, allowances for hot meal and transport, increase in seniority)"])},
  "hr_heading_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The possibility of printing payment slips in PDF or sending by e-mail"])},
  "hr_heading_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic posting to general ledger"])},
  "hr_heading_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control of the simultaneous use and editing of data in employee files during the preparation of payroll"])},
  "hr_heading_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents on electronic payment"])},
  "services_slider_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developing Web and Desktop Applications"])},
  "services_slider_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web and desktop applications"])},
  "services_slider_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easier business processes with mobile applications"])},
  "services_slider_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Apps"])},
  "services_slider_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving business solutions with API integration"])},
  "services_slider_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API Integration"])},
  "services_container_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developer Services and Development"])},
  "services_icon_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web and desktop applications"])},
  "services_icon_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Apps"])},
  "services_icon_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api Integration"])},
  "services_grid_div4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider applications for payment systems in the eCommerce sector and integration into the entire process chain."])},
  "services_grid_div5_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom solutions"])},
  "services_grid_div5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are experts in converting various business needs/ideas of our clients into digital products intended for the improvement of their business processes, development and digital transformation."])},
  "contact_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send us a message"])},
  "contact_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
  "contact_form_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
  "contact_form_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message text"])},
  "contact_form_nameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your name"])},
  "contact_form_emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email"])},
  "contact_form_messageInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a message"])},
  "contact_form_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Google Map "])},
  "footer_forspace_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE Products and services"])},
  "footer_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accounting"])},
  "footer_controlling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["controlling"])},
  "footer_human_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["human resources"])},
  "footer_all_in_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all-in-one erp solution"])},
  "footer_sales_menagment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sales management"])},
  "footer_mobile_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile applications"])},
  "footer_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR"])},
  "footer_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE Services"])},
  "footer_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])},
  "footer_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulevar Meše Selimovića 16"])},
  "footer_address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnia and Herzegovina"])},
  "footer_address_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33/770-949"])},
  "footer_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 Forspace - All Rights Reserved"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send message"])},
  "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Purchase"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sales"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehouse"])},
  "dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])},
  "services-_icon_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linux, Unix, macOS, Windows"])},
  "services-_icon_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supported on Android and iOS operating systems"])},
  "services-_icon_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On server"])},
  "why_companies_need_dms_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Running a business can be stressful in many ways, and a special challenge in this regard is the way the company manages documents. Fortunately, today there are software solutions that take care of the company's documentation."])},
  "why_companies_need_dms_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is a DMS document management system?"])},
  "why_companies_need_dms_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A document management system (DMS) is software that enables companies to create, store, modify, manage and download digital documents necessary for business. The majority of such software is based on the cloud, which means that company employees can have available, create and change them at any time and in any place. A document management system facilitates the flow of critical information throughout the organization because documents can be shared digitally with all employees in the company."])},
  "why_companies_need_dms_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Why does a company need a DMS - document management system?"])},
  "why_companies_need_dms_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A document management system is an indispensable tool for any type of business nowadays. The first biggest advantage is, of course, reduced paper consumption. Since you can store and share documents digitally in a DMS, paper consumption will be significant reduction and your contribution to environmental protection will increase. There are other advantages of using DMS for your business, and we present some of them below."])},
  "why_companies_need_dms_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secure storage of files and data"])},
  "why_companies_need_dms_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most documents related to your business contain key information that you need to access from time to time. Physical documents are always at risk of being lost during storage or transportation, or being damaged due to unforeseen accidents such as fire or flood. S over time, even physical documents wear out and need to be recreated to keep them in good condition. With a document management system, these problems are solved forever. Since there are no physical documents to take care of, you don't have to worry about loss or damage. So , your data cannot be lost unless you want to delete it. Also, with Forspace DMS you can decide who should have access to which document so that all sensitive data will be secure, allowing access only to those people you choose to share it with."])},
  "why_companies_need_dms_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physical documentation is becoming a thing of the past"])},
  "why_companies_need_dms_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For some jobs, such as accounting and legal issues, this kind of software is a real lifesaver. Instead of having to go through a pile of paper for every little thing, you can simply digitize your documents and trust the leading OCR search to help you find all your files even if they are in read-only formats like images, scans or PDFs."])},
  "why_companies_need_dms_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data is easy to share"])},
  "why_companies_need_dms_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication between employees should be as simple as if they were sitting next to each other. The Forspace DMS system will allow you to quickly, easily and easily share all information and documents with your colleagues so that you will not have to spend time writing e-mails or calling call."])},
  "why_companies_need_dms_h6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased productivity in the workplace"])},
  "why_companies_need_dms_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When a company physically stores its documents, a lot of time and manpower is wasted in the process. Storing and sharing documents becomes a cumbersome process that requires a lot of unnecessary effort. This in turn reduces the overall productivity of employees. Physically searching for a document and then delivering it to the desk of the employee who needs it is a waste of time and resources. A document management system simplifies document retrieval, as it allows employees to access what they need from anywhere through a simple search. Using a DMS, you can easily share a file within seconds with everyone you need. Your employees are freed from the time spent physically searching for files and that time can instead be used more wisely, increasing their productivity."])},
  "why_companies_need_dms_h7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Significant savings"])},
  "why_companies_need_dms_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Using a document management system also saves you a lot of money. At first it may seem like an investment because you would need to buy software, but the return on investment is also significant. First, you can save a lot on paper and related products. In addition, as employees become more productive by eliminating the traditional file management system, this results in improved efficiency and higher profits."])},
  "why_companies_need_dms_h8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP comes with an integrated management systemwith DMS documents, which enables the storage, archiving and processing of all types of documents. Forspace DMS enables connection between users, fully harmonized data and an overview of the entire business."])},
  "why_companies_need_dms_h8_additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For a free presentation of Forspace DMS, contact us by phone 033/774-873 or e-mail"])},
  "why_web_based_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cloud-based applications have advanced significantly in the past few years and are increasingly used as they enable companies in various industries to create, edit, access and share critical business data. As companies advance, cloud computing is becoming a dominant player for business data in terms of greater security, faster implementation and upgrades, and reduction of total costs. More and more companies are transferring their ERP system to the cloud, and below, find out why this is so."])},
  "why_web_based_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lower setup and implementation costs"])},
  "why_web_based_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-based ERP systems have low implementation costs in contrast to the traditional, hosted model. On-premise implementation usually requires significant investment in hardware and professional staff. With cloud-based software, off-site hosting and provider expertise eliminate the need for additional hardware or personnel. Reducing the total cost is the first and key reason why companies switch to cloud-based ERP."])},
  "why_web_based_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible data storage"])},
  "why_web_based_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional hardware must be purchased when on-site servers are full. With cloud-based ERP, companies can quickly increase or decrease storage space as needed."])},
  "why_web_based_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easier data backup and restore"])},
  "why_web_based_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-premises ERP involves a complex data backup process and usually requires a maintenance plan in SQL Server, plus other products/services. With cloud-based ERP, a regular backup of the system is made daily and is available to users, with the possibility creation of backup copies of users."])},
  "why_web_based_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased security and scalability"])},
  "why_web_based_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local environments are usually less secure and require expensive additional monitoring and a dedicated IT administrator. Data centers owned by Forspace are secure, certified and compliant with international standards. Scalability is the biggest problem with local ERP systems because they are connected to existing hardware capabilities of the enterprise. Cloud-based ERP systems offer scalability options, such as adding new licenses and more users when the company hires new employees. In addition, they provide additional storage space and provide opportunities to accommodate new production processes and facilities ."])},
  "why_web_based_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Data Platform"])},
  "why_web_based_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud ERP systems provide a common platform where different departments, including manufacturing, sales, marketing, finance and accounting, customer relationship management and supplier relations departments, can share key data anywhere, anytime and on any device. In addition, local solutions are usually more difficult to integrate with other applications than cloud-based solutions."])},
  "what_is_api_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The term “API” is used a lot in web development. Updates to the operating system, web browsers, and applications often announce new APIs for developers. So, what is an API and how do developers use it?"])},
  "what_is_api_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improved collaboration"])},
  "what_is_api_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API is an acronym for Application Programming Interface - a software intermediary that allows two applications to talk to each other. Every time you use an app like Facebook, send a message, or check the weather on your phone, you're using an API. Purpose API specification is the standardization of data exchange between web services. In this case, standardization means the ability of different systems, written in different programming languages and running on different operating systems, or using different technologies, to seamlessly communicate with each other."])},
  "what_is_api_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easier Innovations"])},
  "what_is_api_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs sit between the application and the web server, acting as an intermediary layer that handles data transfer between the systems."])},
  "what_is_api_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data Monetization"])},
  "what_is_api_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The functioning of the API can be explained in several steps:"])},
  "what_is_api_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional security"])},
  "what_is_api_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The client application initiates an API call to retrieve information and this operation is known as a request. This request is processed from the application to the web server via the API's Uniform Resource Identifier (URI) and includes a request subject, a header, and sometimes an explanation of the request."])},
  "what_is_api_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Common Data Platform"])},
  "what_is_api_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["After receiving a valid request, the API makes a call to an external program orweb server."])},
  "what_is_api_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server sends a response to the API with the requested information."])},
  "what_is_api_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The API passes data to the originating application that made the request"])},
  "what_is_api_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Some of the main benefits of the API include:"])},
  "what_is_api_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs enable the integration of two platforms so that they can seamlessly communicate with each other. Through this integration, companies can automate workflows and improve collaboration in the workplace. Without APIs, many businesses would lack connectivity and suffer from information silos that threaten productivity and performance."])},
  "what_is_api_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs offer flexibility, enabling companies to connect with new business partners, offer new services in an existing market and, ultimately, access new markets that can generate huge returns and drive digital transformation."])},
  "what_is_api_p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Many companies choose to offer APIs for free, at least initially, so that they can build an audience of developers around their brand and establish relationships with potential business partners. However, if the API grants access to valuable digital assets, it can be monetized through sales of access (this is called the API economy)."])},
  "what_is_api_p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["As stated earlier, APIs create an additional layer of protection between your data and the server. Developers can further strengthen API security by using tokens, signatures, and Transport Layer Security (TLS) encryption by implementing an API gateway for managing and authenticating traffic and practicing efficient API management."])},
  "digita_trans_adv_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The concept of digital transformation implies the use of digital technologies in a strategic way that simplifies and accelerates your business, from your business model to your internal processes. By digitizing your company, your goal is to gain the benefits of digital transformation, which may include increasing your revenue , improving the user experience and gaining a competitive advantage."])},
  "digita_trans_adv_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improved efficiency"])},
  "digita_trans_adv_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One of the biggest advantages of implementing an ERP system in your business is a dramatic improvement in efficiency. The way your employees communicate from department to department, the continuous flow of data throughout the organization, the smooth transition from phase to phase during the user's lifetime - all these advantages contribute to a more efficient business process that can save time, money and resources."])},
  "digita_trans_adv_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost Savings"])},
  "digita_trans_adv_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With the right ERP software, there are many opportunities to save costs, especially in operational processes. For example, a manufacturing company has better insight into the required quantities and timelines of raw materials. By ordering raw materials in a strategic, data-driven way, savings become inevitable ."])},
  "digita_trans_adv_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit growth"])},
  "digita_trans_adv_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By minimizing costs, it is easier to increase profits. Maximizing opportunities by identifying gaps in current business processes is essential for the company's progress. By improving transparency in various aspects of the business, employees and management can improve their best practices and increase revenue."])},
  "digita_trans_adv_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improving competitive advantage"])},
  "digita_trans_adv_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By improving the infrastructure of your business, you improve your advantage in the market. The best way to do this is to plan in advance the features and functionalities that your new system will need to make your company stronger. Get involved in what you are good at and find the parts of the business that you can improve and work on it. The features and functionality of the software you choose should reflect the needs and enhance your differentiating factors."])},
  "digita_trans_adv_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increased Agility"])},
  "digita_trans_adv_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With increased automation and improved productivity comes greater agility. Only in developing a digital strategy can companies become truly agile within projects and operational areas. Addressing the use of digital technologies that do the heavy lifting in your processes ensures that teams across your organizations become agile. This accelerates results, information, reporting and decision-making."])},
  "digita_trans_adv_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The server sends a response to the API with the requested information."])},
  "entrepeneur_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The process of establishing a business is known as entrepreneurship. An entrepreneur is usually considered an innovator, a source of new ideas, goods, services and/or business procedures."])},
  "entrepeneur_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovators"])},
  "entrepeneur_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovators are the types of entrepreneurs who come up with completely new ideas and turn them into viable businesses. In most cases, these entrepreneurs change the way people think and do things differently. Such entrepreneurs tend to be extremely passionate and obsessive, because they derive their motivation from the unique nature of their poshunting ideas. Innovative entrepreneurs also find new ways to market their products by choosing product differentiation strategies that make their company stand out from the crowd. Sometimes it's not just standing out from the crowd, but actually creating a new crowd."])},
  "entrepeneur_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hustlers"])},
  "entrepeneur_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlike innovators whose vision is gas in their engine, hustlers work harder and are willing to get their hands dirty. These types of entrepreneurs focus on small goals in the beginning with the desire to go bigger in the future. Hustlers are motivated by their dreams and they will work extremely hard to make them come true. However, their dreams take much longer to come true than most other types of entrepreneurs."])},
  "entrepeneur_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imitators"])},
  "entrepeneur_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imitators are the types of entrepreneurs who copy certain business ideas and improve upon them. They are always looking for ways to improve a certain product in order to gain an advantage in the market. Imitators are part innovators and part hunters who do not adhere to the conditions set by other people and they have a lot of self-confidence."])},
  "entrepeneur_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorers"])},
  "entrepeneur_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Even after they have an idea, researchers will take the time to gather all the relevant information about it. For them, failure is not an option because they analyze the idea from all angles. This type of entrepreneur usually believes in starting a business that has a high chance of success because they invest a lot of time and resources to understand all aspects of a business idea. As a result, these types of entrepreneurs usually take a long time to launch products to make decisions because they need a foundation of deep understanding. These entrepreneurs rely much more on data and facts but on instincts and intuition."])},
  "entrepeneur_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customers"])},
  "entrepeneur_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The main characteristic of this type of entrepreneur is their wealth. These types of entrepreneurs have money and are specialized in buying promising businesses. Buyer entrepreneurs will identify the business and assess its viability, proceed with the acquisition and find the most suitable person to lead and develop it ."])},
  "HowErp_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP systems are intended for companies of all sizes, whether small, medium or large, that want to integrate their business processes and data in one place. No company can be too small to put the business under complete control and using smart business solutions to simplify their business processes. Small businesses use ERP software to improve communication between departments and optimize business processes with complete transparency of all operations."])},
  "HowErp_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sharing information"])},
  "HowErp_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrating business processes into one platform will make it easier to share data and information between departments."])},
  "HowErp_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increasing efficiency"])},
  "HowErp_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Increasing efficiency without hiring new staff or expanding operations by automating processes and eliminating redundant work. The real picture of small companies is that one employee always does multiple jobs. Everyone gets involved wherever and whenever the need arises. ERP systems aimed at small companies, they automate key business functions and facilitate the work of your employees so that they can focus on more important business tasks."])},
  "HowErp_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Intelligence"])},
  "HowErp_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By using an ERP system, you will always have easily accessible and accurate data. These types of systems allow you to provide advanced reporting and monitoring of progress, productivity and effects. This way you will always have complete insight into your business for better decision-making. Without a comprehensive system that collects data, reports on your processes and systems and looks at the bigger picture, how can you know exactly where you are?"])},
  "HowErp_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complete transparency and business control"])},
  "HowErp_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To run a profitable business, you must have complete control over your business resources, i.e. what your business has in terms of cash, raw materials, finished goods, goods, personnel, production capacity. Your business resources are what you use to maintaining your business and you need to have complete control over them at all times. One of the biggest advantages of an ERP system is its ability to provide complete visibility into your entire process, giving you insight into how your process is working. With ERP, what was once required hours of careful data collection and report creation can be done automatically, giving you a comprehensive view of your business, allowing you to measure critical performance metrics through real-time dashboards. You can use this information to always know where you are, to optimize production schedules and to maximize capacity and increase your efficiency."])},
  "software_solutions_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most companies try to improve overall performance by implementing ERP software.In many cases, the original driver that leads to the purchase of ERP software, that is, the replacement of the old for the new, is the goal of simplifying business processes for a sustainable competitive advantage. Not infrequently, ERP software implementations are unsuccessful. Instead of bringing cost reduction, business agility and performance improvement, bad ERP systems create complexity, duplication of effort, and in the worst case, poor quality and customer service, and a dangerous lack of business insight that can lead a company to ruin."])},
  "software_solutions_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It happens that implemented ERP systems do not keep pace with changes. The manufacturing sector is faced with constantly changing business processes, data and requirements that make it almost impossible for a typical, inflexible ERP system to keep up with what the business really is necessary. Below are some warning signs that tell you that your company's business is at risk because the ERP software is not working as it really should or because it lacks timely upgrades."])},
  "software_solutions_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ERP system does not integrate business data important for achieving the defined mission. Your data is \"locked\" in your outdated ERP system and is difficult to access. Because of this, you cannot analyze the data necessary for decision-making. Information and data in the system do not \"circulate\" through departments, but are trapped within them and exist independently of each other. This increases complexity and duplicates efforts with different versions of the truth, which compromises the quality, reliability and availability of vital information. Without transparency of your entire business software and seamless structure that connects all the reporting functions and departments of your software, you can't gather all the key information your business needs to succeed."])},
  "software_solutions_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every change in the system is expensive and represents a long-term process. The software vendor does not provide you with the software upgrades that you need. Also, software maintenance is too expensive for such outdated software that does not bring any innovations, which could cost a loss of competitive advantage. You're stuck between dysfunctional software that doesn't deliver improvements and skyrocketing costs."])},
  "software_solutions_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP software upgrades disrupt business. We've already noted that software vendor upgrades typically come out every 12-24 months on average. They often require updating the operating system, database management system, disk space, hardware, etc,.. takes time to plan and execute. You're in trouble if you have to ignore your business for a while to upgrade your software. Additionally, software upgrades very often don't deliver what your business really needs. If, and after a software upgrade, the software doesn't offer the settings that you and your business need for growth, it's time for changes."])},
  "software_solutions_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can't access your business software while traveling to a business meeting? That means your business software doesn't allow you to access data on the go. It's all too obvious that work doesn't stop while you're traveling. Smartphones help you stay connected, but if your ERP software doesn't have mobile app access, you're out of luck. Wireless connections are everywhere, but you're still limited because you can't stay in touch with business activities. Your customers aren't all in one place, and that means you aren't always either. place. It's imperative that you can access data at the touch of a button while on the go. Smartphones are half the solution, but if your ERP vendor doesn't offer a connected app that lets you see all of your data in real time, you won't get the full picture of your business when you need."])},
  "software_solutions_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It takes too much time for new employees to learn how to use the system because it is too complicated. Many older ERP systems are difficult to learn, and workers can easily become stressed when told to \"press F1 to inquire\" or \"press \"Enter to accept\""])},
  "software_solutions_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP software is a comprehensive business solution that has found its application in various industries and business activities. Hence the need for Forspace to be constantly updated, upgraded and improved, in order to provide its users with the satisfaction of use and quality assistance in solving business problems tasks. To ensure the smooth and successful performance of business activities, choose Forspace. We are with our clients at every stage, and our work does not stop as long as you use our services. Improving your business is what we strive for every day, because: We do not work for you We work with you!"])},
  "digital_trans_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most companies implement ERP softwareand tends to improve overall performance. In many cases, the original driver that leads to the purchase of ERP software, that is, the replacement of the old for the new, is the goal of simplifying business processes for a sustainable competitive advantage. Not infrequently, ERP software implementations are unsuccessful. Instead of bringing cost reduction, business agility and performance improvement, bad ERP systems create complexity, duplication of effort, and in the worst case, poor quality and customer service, and a dangerous lack of business insight that can lead a company to ruin."])},
  "digital_trans_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technology will support, not replace, accountants"])},
  "digital_trans_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It happens that implemented ERP systems do not keep up with changes. The manufacturing sector is faced with constantly changing business processes, data and requirements that make it almost impossible for a typical, inflexible ERP system to keep up with what the business really is necessary. Below are some warning signs that tell you that your company's business is at risk because the ERP software is not working as it really should or because it lacks timely upgrades."])},
  "digital_trans_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The benefits of enterprise resource planning (ERP), cloud business and automation software"])},
  "digital_trans_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your ERP system does not integrate business data important for achieving the defined mission. Your data is \"locked\" in your outdated ERP system and is difficult to access. Because of this, you cannot analyze the data necessary for decision-making. Information and data in the system do not \"circulate\" through departments, but are trapped within them and exist independently of each other. This increases complexity and duplicates efforts with different versions of the truth, which compromises the quality, reliability and availability of vital information. Without transparency of your entire business software and seamless structure that connects all the reporting functions and departments of your software, you can't gather all the key information your business needs to succeed."])},
  "digital_trans_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Future accountants: More relevant, productive and creative than ever"])},
  "digital_trans_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Every change in the system is expensive and represents a long-term process. The software vendor does not provide you with the software upgrades that you need. Also, software maintenance is too expensive for such outdated software that does not bring any innovations, which could cost a loss of competitive advantage. You're stuck between dysfunctional software that doesn't deliver improvements and skyrocketing costs."])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn more"])},
  "appointment_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make an appointment"])},
  "appointment_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you would like a presentation of the software, leave your information and we will contact you."])},
  "appointment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feel free to book an appointment with our team for more information about our products or about processes in your company that require a software solution and consulting. We may have just the perfect solution for you based on our best practices and experiences."])},
  "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to form"])},
  "slogan_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We don't work for you!"])},
  "slogan_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We wrok with you!"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])},
  "get_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get connected with us on social networks!"])},
  "find_us_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find us on map"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])},
  "company_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company URL"])},
  "products_tax_header_main_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
  "products_tax_header_main_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatic processing and posting"])},
  "products_fixed_asset_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request for the acquisition of a permanent asset"])},
  "products_fixed_asset_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register of fixed assets"])},
  "products_fixed_asset_header_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Locating and identifying a fixed asset"])},
  "products_fixed_asset_header_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculation of depreciation"])},
  "products_fixed_asset_header_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple inventory of fixed assets"])},
  "products_fixed_asset_header_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posting of fixed assets"])},
  "products_purchase_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simple ordering for everyday business"])},
  "products_hr_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efficient employee record keeping and process automation"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
  "fill_the_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill the form properly"])},
  "header_secondary_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The first domestic brand on the ERP software market, which allows reducing the use of written documentation, saving time and money, and presents a completely customized and understandable software solution for the end user"])},
  "fintech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fintech"])},
  "fintech_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" We are delivering cutting-edge solutions in the fintech sector, catering to the unique needs and requirements of financial institutions, fintech startups, and businesses operating in the financial services industry."])},
  "fintech_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We combine finance and technology"])},
  "digital_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Payments"])},
  "payment_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Payment processing, money transfer platforms, mobile wallets,contactless technologies."])},
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking"])},
  "banking_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solutions for digital banking services."])},
  "finance_and_wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance and Wealth Management"])},
  "finance_and_wealth_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tools and platforms for individuals to manage finances effectively."])},
  "bi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BI"])},
  "bi_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wide range of financial reports."])},
  "fintech_bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Fintech revolutionizes the financial landscape by leveraging technology to deliver innovative and accessible financial solutions."])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "web_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web aplications"])},
  "services_grid_div2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS (Document Management System) is a business program for tracking, managing and digitally archiving documents. Keeps records of different types of documents created and archived by different users (history tracking)."])}
}