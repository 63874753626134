<template lang="">
  <div class="wrapper">
    <h1>{{ $t("fintech") }}</h1>
    <h3>{{ $t("fintech_heading_2") }}</h3>
    <div class="d-flex mb-5 gap-4 card-wrap">
      <div class="card" data-aos="flip-left">
        <div class="img-wrap">
          <img src="@/assets/images/digtial-payments.png" alt="" />
        </div>
        <div class="text-wrap">
          <h3>{{ $t("digital_payments") }}</h3>
          <p>
            {{ $t("payment_processing") }}
          </p>
        </div>
      </div>
      <div class="card" data-aos="flip-left">
        <div class="img-wrap">
          <img src="@/assets/images/bank.png" alt="" />
        </div>
        <div class="text-wrap">
          <h3>{{ $t("banking") }}</h3>
          <p>{{ $t("banking_p") }}</p>
        </div>
      </div>
      <div class="card" data-aos="flip-left">
        <div class="img-wrap">
          <img src="@/assets/images/finance-and-wealth.png" alt="" />
        </div>
        <div class="text-wrap">
          <h3>{{ $t("finance_and_wealth") }}</h3>
          <p>
            {{ $t("finance_and_wealth_p") }}
          </p>
        </div>
      </div>
      <div class="card" data-aos="flip-left">
        <div class="img-wrap">
          <img src="@/assets/images/bi.png" alt="" />
        </div>
        <div class="text-wrap">
          <h3>{{ $t("bi") }}</h3>
          <p>{{ $t("bi_p") }}</p>
        </div>
      </div>
    </div>
    <h3>
      {{ $t("fintech_bottom") }}
    </h3>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scooped>
.img-wrap {
  background-color: #25435b;
  padding: 20px;
}
.wrapper {
  margin-left: 13%;
  margin-right: 13.3%;
  justify-content: center;
  h1 {
    margin-top: 100px;
    margin-bottom: 50px;
  }
  h3 {
    margin-bottom: 50px;
  }
}

.card {
  min-width: 25%;
  width: 25%;
  box-sizing: border-box;
  flex: 1 1 1 1;
  img {
    filter: invert(1);
    width: 100%;
  }
}
.text-wrap {
  margin-top: 30px;
  padding: 5px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  p {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 200px;
  }
}
.card-wrap {
  flex: 1 1 1 1;
}
@media screen and (max-width: 1050px) {
  .card-wrap {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
}
@media screen and (max-width: 500px) {
  .wrapper {
    margin: 0px;
  }
}
</style>
