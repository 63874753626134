<template lang="">
  <div class="col-12 mt-5">
    <div class="bank-statment orange-text-main mt-5">
      <h2 class="text-start">{{ $t('products_tax_header_main_1') }}</h2>
      <h4 class="text-start text-muted">
        {{ $t('products_tax_header_main_2') }}
      </h4>
    </div>
    <div
      class="d-flex col-12 justify-content-center align-items-center bank-statment-items orange-text-main pt-5"
    ></div>
    <div class="workflow">
      <div class="step">
        <div class="step-icon">
          <font-awesome-icon
            icon="fa-check"
            class="text-light"
          ></font-awesome-icon>
        </div>
        <div class="step-content">
          <h4>{{ $t(pdv[0].heading) }}</h4>
          <p class="step-description">
            {{ $t(pdv[0].text) }}
          </p>
        </div>
      </div>
      <div class="step">
        <div class="step-icon">
          <font-awesome-icon
            icon="fa-file-alt"
            class="text-light"
          ></font-awesome-icon>
        </div>
        <div class="step-content">
          <h4>{{ $t(pdv[1].heading) }}</h4>
          <p class="step-description">
            {{ $t(pdv[0].text) }}
          </p>
        </div>
      </div>
      <div class="step">
        <div class="step-icon">
          <font-awesome-icon
            icon="fa-calculator"
            class="text-light"
          ></font-awesome-icon>
        </div>
        <div class="step-content">
          <h4>{{ $t(pdv[2].heading) }}</h4>
          <p class="step-description">{{ $t(pdv[2].text) }}</p>
        </div>
      </div>
      <div class="step">
        <div class="step-icon">
          <font-awesome-icon
            icon="fa-chart-bar"
            class="text-light"
          ></font-awesome-icon>
        </div>
        <div class="step-content">
          <h4>{{ $t(pdv[3].heading) }}</h4>
          <p class="step-description">{{ $t(pdv[3].text) }}</p>
        </div>
      </div>
    </div>
  </div>
  <br />
</template>
<script>
export default {
  name: 'TheFinanceTax',
  data() {
    return {
      pdv: [
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/icons8-profile.png',
          heading: 'products_tax_heading_1',
          text: 'products_tax_text_1'
        },
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/icons8-manual_page_rotation.png',
          heading: 'products_tax_heading_2',
          text: 'products_tax_text_2'
        },
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/icons8-tab_mac.png',
          heading: 'products_tax_heading_3',
          text: 'products_tax_text_3'
        },
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/icons8-math_book.png',
          heading: 'products_tax_heading_4',
          text: 'products_tax_text_4'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.workflow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 120px;
}

.step {
  width: 48%;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.step:nth-child(odd) {
  margin-right: 2%;
}

.step-icon {
  background-color: #25435b;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.step-icon i {
  color: white;
  font-size: 30px;
}

.step-content {
  margin-left: 20px;
}

.step-content h4 {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.step-content p {
  margin: 0;
  font-size: 14px;
  color: gray;
}

@media (max-width: 767px) {
  .step-icon {
    display: none;
  }
}
</style>
