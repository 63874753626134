<template lang="">
  <div class="col-12 mt-4">
    <div class="bank-statment orange-text-main mt-5">
      <h2 class="text-start">{{ $t('products_bank_header_1') }}</h2>
      <h4 class="text-start text-muted">{{ $t('products_bank_header_2') }}</h4>
    </div>
    <div
      class="d-flex col-12 justify-content-center align-items-center bank-statment-items orange-text-main pt-4"
    ></div>

    <div class="grid-container pb-5">
      <div class="grid-item">
        <div class="bank">
          <font-awesome-icon
            icon="fa-solid fa-file-csv"
            style="color: tomato"
            size="2xl"
          />
        </div>
        <h4>{{ $t(bank_1[0].heading) }}</h4>
        <p class="description">
          {{ $t(bank_1[0].text) }}
        </p>
        <br />
      </div>
      <div class="grid-item">
        <div class="bank">
          <font-awesome-icon
            icon="fa-solid fa-rectangle-list"
            style="color: tomato"
            size="2xl"
          />
        </div>
        <h4>{{ $t(bank_2[0].heading) }}</h4>
        <p class="description">
          {{ $t(bank_2[0].text) }}
        </p>
      </div>
      <div class="grid-item">
        <div class="bank">
          <font-awesome-icon
            icon="fa-solid fa-money-bill-transfer"
            style="color: tomato"
            size="2xl"
          />
        </div>
        <h4>{{ $t(bank_1[1].heading) }}</h4>
        <p class="description">
          {{ $t(bank_1[1].text) }}
        </p>
      </div>
      <div class="grid-item">
        <div class="bank">
          <font-awesome-icon
            icon="fa-solid fa-address-card"
            style="color: tomato"
            size="2xl"
          />
        </div>
        <h4>{{ $t(bank_2[1].heading) }}</h4>
        <p class="description">
          {{ $t(bank_2[1].text) }}
        </p>
      </div>
      <div class="grid-item">
        <div class="bank">
          <font-awesome-icon
            icon="fa-solid fa-diagram-next"
            style="color: tomato"
            size="2xl"
          />
        </div>
        <h4>{{ $t(bank_1[2].heading) }}</h4>
        <p class="description">
          {{ $t(bank_1[2].text) }}
        </p>
      </div>
      <div class="grid-item">
        <div class="bank">
          <font-awesome-icon
            icon="fa-solid fa-warehouse"
            style="color: tomato"
            size="2xl"
          />
        </div>
        <h4>{{ $t(bank_2[2].heading) }}</h4>
        <p class="description">
          {{ $t(bank_2[2].text) }}
        </p>
      </div>
      <!-- Dodajte preostale elemente ovdje -->
    </div>
  </div>
</template>
<script>
export default {
  name: 'TheFinanceBankStatement',
  data() {
    return {
      bank_1: [
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/1.png',
          heading: 'products_bank_1_heading_1',
          text: 'products_bank_1_text_1'
        },
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/2.png',
          heading: 'products_bank_1_heading_2',
          text: 'products_bank_1_text_2'
        },
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/3.png',
          heading: 'products_bank_1_heading_3',
          text: 'products_bank_1_text_3'
        }
      ],
      bank_2: [
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/4.png',
          heading: 'products_bank_2_heading_1',
          text: 'products_bank_2_text_1'
        },
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/5.png',
          heading: 'products_bank_2_heading_2',
          text: 'products_bank_2_text_2'
        },
        {
          url: 'https://forspace.ba/wp-content/uploads/2020/09/6.png',
          heading: 'products_bank_2_heading_3',
          text: 'products_bank_2_text_3'
        }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
.bank {
  width: 100px !important;
  padding-bottom: 10px;
}
.description {
  color: #a7b3bd;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Dvije kolone */
  grid-gap: 20px; /* Odstojanje između elemenata */
}

.grid-item {
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 20px;
}

@media (max-width: 767px) {
  /* Stilovi za mobilnu verziju */
  .grid-container {
    grid-template-columns: 1fr; /* Samo jedna kolona */
  }
}
</style>
