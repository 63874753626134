<template lang="">
  <page-layout>
    <div>
      <h2>{{ $t("products_sales_heading") }}</h2>
      <h4 class="text-muted mb-4">{{ $t("products_sales_header") }}</h4>
      <h5>
        {{ $t("products_sales_text") }}
      </h5>
    </div>
    <div class="container sales-img text-center mb-4">
      <img class="img-fluid" src="@/assets/images/saless.png" alt="" />
    </div>
  </page-layout>
</template>
<script>
import PageLayout from "@/components/PageLayout.vue"
export default {
  components: {
    PageLayout
  },
  name: "TheSales"
}
</script>
<style lang="scss" scoped>
h2 {
  color: #25435b;
}

.sales-img {
  @media screen and (max-width: 567px) {
    display: none;
  }
}
</style>
