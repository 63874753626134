export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über uns"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkte"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dienste"])},
  "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laufzeit"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Team"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reservieren"])},
  "nav_news_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum brauchen Unternehmen DMS?"])},
  "nav_news_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbasiertes ERP-System"])},
  "nav_news_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Integration"])},
  "nav_news_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalisierung"])},
  "nav_news_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ein ERP-System einem kleinen Unternehmen beim Wachstum helfen?"])},
  "nav_news_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzeichen dafür, dass Ihr Unternehmen eine fortschrittlichere Softwarelösung benötigt"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "faq-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorschau"])},
  "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
  "Präsentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Präsentation"])},
  "about_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Präsentation"])},
  "widget_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "widget_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace-Solutions"])},
  "widget_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Digitalisierung Ihrer Geschäftsprozesse mit dem ERP-Programm von Forspace möchten wir es Ihnen ermöglichen, das Unternehmen effizienter zu führen, Geschäftsaktivitäten in jedem Moment kontinuierlich zu überwachen, zu planen und zu steuern und in diesem Zusammenhang Fehler zu vermeiden, die sonst dazu führen würden zu Komplikationen."])},
  "widget_small_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei allen Fragen anrufen"])},
  "widget_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33 774 873"])},
  "widget_icon_one_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experte"])},
  "widget_icon_two_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfolgreiche Projekte"])},
  "widget_icon_three_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jahre Erfahrung"])},
  "home_icon_square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnelle und einfache Lösungen"])},
  "home_icon_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Unternehmen unter einem Dach"])},
  "home_icon_companies_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrales Prozessmanagement."])},
  "home_icon_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webbasiertes ERP-System"])},
  "home_icon_cloud_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, WEB-basiert, MySQL."])},
  "home_icon_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Prozesse in einem Stream"])},
  "home_icon_config_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echte Prozesse auf digitale abbilden."])},
  "home_icon_circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modernes Konzept, schlichtes Design"])},
  "home_icon_circle_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützt die einfache Integration mit anderen Lösungen."])},
  "home_forspace_erp_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP & Buchhaltung"])},
  "home_forspace_erp_accounting_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entscheidungen basieren auf Fakten, nicht auf Annahmen!"])},
  "recension_primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertungen"])},
  "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte eine Präsentation"])},
  "about_us_nums_employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mitarbeiter"])},
  "services_grid_div2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS (Document Management System) ist ein praktisches Programm, das die Dokumentation digitalisiert und archiviert. Es gibt Beweise dafür, dass die von Ihnen erstellte Dokumentation und Archivierung eine Reihe weiterer historischer Dokumente enthält (geschichtliche Praktik)."])},
  "about_us_nums_established": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegründet"])},
  "services_grid_div4_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungssysteme"])},
  "services_grid_div1_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-Systeme"])},
  "services_grid_div2_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS Systeme"])},
  "services_grid_div3_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS Systeme"])},
  "services_grid_div3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS (Warehouse Management System) ist ein Geschäftsprogramm zur Lagerverwaltung, das darauf abzielt, Arbeitsaufgaben und -aktivitäten innerhalb des Lagers zu automatisieren, zu vereinfachen und zu rationalisieren und dadurch die Produktivität zu steigern und die Fehlerwahrscheinlichkeit auf ein Minimum zu reduzieren."])},
  "services_grid_div1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das ERP-System (Enterprise Resource Planning) ist ein Geschäftsprogramm, das Unternehmen die Planung ihrer Ressourcen und die Verwaltung des gesamten Unternehmens ermöglicht und alle Prozessabläufe von Service-, Vertriebs- oder Produktionsaktivitäten abdeckt."])},
  "recension_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was unsere Kunden über uns sagen"])},
  "recension_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir heben stolz alle unsere Kunden hervor und bauen partnerschaftliche Beziehungen zur gegenseitigen Zufriedenheit auf. Wenn einige unserer Kunden noch nicht auf der Liste stehen, bedeutet das nicht, dass wir sie weniger schätzen oder dass sie für uns weniger wichtig sind. "])},
  "recension_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haben Sie Fragen?"])},
  "recension_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns."])},
  "about_us_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen"])},
  "about_us_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti, atque amet architecto vitae sapiente explicabo."])},
  "about_us_news_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuigkeiten"])},
  "about_us_news_link_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP einzigartige All-in-One-Cloud-Softwarelösung zur Standardisierung und Digitalisierung von Geschäftsprozessen"])},
  "about_us_news_link_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum brauchen Unternehmen DMS?"])},
  "about_us_news_link_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Führung eines Unternehmens kann in vielerlei Hinsicht stressig sein, und eine besondere Herausforderung in dieser Hinsicht ist die Art und Weise, wie das Unternehmen Dokumente verwaltet."])},
  "about_us_news_link_heading_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum ist ein webbasiertes Business-Programm die richtige Wahl?"])},
  "about_us_news_right_flex_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcher Unternehmertyp sind Sie?"])},
  "about_us_news_right_flex_one_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmertum"])},
  "about_us_news_right_flex_one_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Unternehmer wird normalerweise als Innovator betrachtet, als Quelle neuer Ideen, Waren, Dienstleistungen und/oder Geschäftsverfahren."])},
  "about_us_news_right_flex_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeichen, dass Ihr Unternehmen eine fortschrittlichere Softwarelösung benötigt"])},
  "about_us_news_right_flex_two_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softwarelösungen"])},
  "about_us_news_right_flex_two_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Unternehmen versuchen, die Gesamtleistung durch die Implementierung von ERP-Software zu verbessern."])},
  "about_us_news_right_flex_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann ERP einem kleinen Unternehmen beim Wachstum helfen?"])},
  "about_us_news_right_flex_three_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-System"])},
  "about_us_news_right_flex_three_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise-Resource-Planning-System, sogenanntes ERP, verwaltet alle Geschäftsbereiche, einschließlich Produktion, Beschaffung, Verkauf, Vertrieb, Buchhaltung, Personalwesen, Managementsystem.."])},
  "about_us_news_right_flex_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie macht die digitale Transformation die Arbeit von Buchhaltern einfacher und schneller?"])},
  "about_us_news_right_flex_four_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale Transformation"])},
  "about_us_news_right_flex_four_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profis in allen Branchen stehen vor großen Veränderungen, von denen viele durch neue Technologien vorangetrieben werden."])},
  "about_us_next_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiter"])},
  "about_us_next_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace-Vorschau"])},
  "about_us_next_overlay_left_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorteile der digitalen Transformation"])},
  "about_us_next_overlay_left_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Beschleunigung der digitalen Welt erfordert eine Anpassung der Organisationen, weshalb die meisten Unternehmen weltweit an der digitalen Transformation arbeiten..."])},
  "about_us_next_overlay_right_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist die API?"])},
  "about_us_next_overlay_right_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Begriff \"API\" wird oft in der Webentwicklung verwendet. Betriebssystem-Updates, im WebDie App und die App kündigen häufig neue APIs für Entwickler an. Was ist also eine API und wie programmiert man sie.."])},
  "about_us_choose_small_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum uns wählen"])},
  "about_us_choose_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business aus einer Hand"])},
  "about_us_additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In unserem Unternehmen"])},
  "about_us_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Das Forspace-Team bietet uneingeschränkte Unterstützung in allen Phasen der Nutzung, und wir sind besonders stolz auf unseren After-Sales-Service, denn wenn Sie diese Software akzeptieren, können Sie auf unser ständiges Engagement und unseren Kontakt zählen. Wir sind die erste einheimische Marke auf dem ERP-Softwaremarkt mit einem Zeichen deutscher Technologie, die es ermöglicht, den Einsatz von schriftlicher Dokumentation zu reduzieren, Zeit und Geld zu sparen und eine vollständig maßgeschneiderte und verständliche Softwarelösung für den Endanwender darstellt."])},
  "about_check_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützt die einfache Integration mit anderen Lösungen."])},
  "about_check_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echte Prozesse auf digitale abbilden."])},
  "about_check_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrales Prozessmanagement."])},
  "about_us_video_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace-Vorschau"])},
  "about_us_video_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sehen Sie sich unser kurzes Video an"])},
  "about_us_nums_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Leerzeichen in Zahlen"])},
  "about_us_nums_ETABLIERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegründet"])},
  "about_us_nums_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
  "about_us_nums_ended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beschäftigt"])},
  "about_us_client_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Wenn es um Buchhaltungsautomatisierung und vollständige Anpassung an unsere Bedürfnisse geht, ist das Team von Forspace Solutions genau das Richtige für Sie. Ihre Wettbewerbslandschaft ist steil, aber Forspace macht es erreichbar.\" "])},
  "about_us_client_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Wenn es um Buchhaltungsautomatisierung und vollständige Anpassung an unsere Bedürfnisse geht, suchen Sie nicht weiter als das Team von Forspace Solutions. Ihre Wettbewerbslandschaft ist steil, aber Forspace macht es erreichbar.\" "])},
  "about_us_client_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Wenn es um Buchhaltungsautomatisierung und vollständige Anpassung an unsere Bedürfnisse geht, suchen Sie nicht weiter als das Team von Forspace Solutions. Ihre Wettbewerbslandschaft ist steil, aber Forspace macht es erreichbar.\" "])},
  "about_us_reserve_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin reservieren"])},
  "about_us_reserve_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinbaren Sie gerne einen Termin mit unserem Team, um weitere Informationen zu unseren Produkten oder zu Prozessen in Ihrem Unternehmen zu erhalten, die eine Softwarelösung und Beratung erfordern. Möglicherweise haben wir aufgrund unserer Best Practices und Erfahrungen genau die perfekte Lösung für Sie ."])},
  "products_purchase_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
  "products_purchase_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Mit dem Beschaffungsmodul von Forspace können Sie einfach alles bestellen, was Sie für Ihr tägliches Geschäft benötigen. Das System kümmert sich um die Korrektheit des Prozesses: Wer muss die Bestellung genehmigen? Informiert die Beschaffungsabteilung, dass Gebote eingeholt werden müssen . Es prüft, ob die Einkäufe im Rahmen des Budgets liegen (auch hier wird ein automatischer Workflow zur Genehmigung von Budgetüberschreitungen durch die verantwortliche Person aktiviert, damit der Prozess nicht ins Stocken gerät)."])},
  "products_sales_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkäufe"])},
  "products_sales_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einzelhandel - Großhandel"])},
  "products_sales_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Unser standardisiertes Großhandels- oder Einzelhandelsmodul erfüllt alle Ihre geschäftlichen Anforderungen. Das Einzelhandelsmodul ist zusätzlich angepasst, um mit POS-Geräten und allen anderen gesetzlichen Anforderungen und Verpflichtungen zu arbeiten. Kontaktieren Sie uns für weitere Informationen."])},
  "products_production_header_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "products_production_header_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie kann man die Produktion unter Kontrolle bringen?"])},
  "products_production_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Produktionsmodul ist die größte Herausforderung bei der Implementierung eines ERP-Systems. Damit einher geht die Notwendigkeit eines kulturellen Wandels innerhalb der Organisation. Die Mitarbeiter müssen sich an die verschiedenen Änderungen und Prozessoptimierungen anpassen, die anstehen. ERP-Systeme basieren auf Best Practices in verschiedenen Branchen, aber sobald die Software nicht sehr gut an die Prozesse eines bestimmten Unternehmens angepasst ist, haben wir in diesen Fällen eine Lösung durch maßgeschneiderte Anpassungen, die wir schnell und effizient umsetzen Team begleitet Sie nicht nur während der Implementierung, wir arbeiten auch danach mit Ihnen zusammen und teilen Wissen über Vorgehensweisen und Methoden, mit denen Sie das EPR-System weiterhin erfolgreich einsetzen werden."])},
  "products_warehouse_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "products_warehouse_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zentrale Stelle der Logistikaktivitäten"])},
  "products_warehouse_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warehousing ist die Kunst, Materialien in Lager und Produktion zu bewegen und zu halten. Mit diesem Modul überwachen wir die Bewegungen von Materialien, Halbzeugen und Produkten transparent und nach Plan und definierten Prozessen . Es umfasst den physischen Raum und die Platzierung von Materialien sowie die Softwareüberwachung aller Aktivitäten und Transaktionen."])},
  "products_warehouse_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige der Hauptfunktionalitäten sind:"])},
  "products_warehouse_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Die Möglichkeit, innerhalb des Unternehmens ein oder mehrere Lager mit mehreren Lagerebenen zu erstellen."])},
  "products_warehouse_text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definition von Lagerzellen nach Art der Artikel, die sich in diesen Zellen befinden (Material-, Waren-, Ersatzteilzellen und ähnliches)"])},
  "products_warehouse_text_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artikel empfangen vonund Transport, mit Scannern oder manuell, zu den entsprechenden Lagerzellen, mit vollständiger Verfolgung jeder Transaktion über den Barcode des Artikels."])},
  "products_warehouse_text_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Erstellung von Bestellungen für eingegangene Artikel (Verbindung von Eingangsrechnungen und Lieferscheinen). Druck von Etiketten und Barcodes von Artikeln."])},
  "products_warehouse_text_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewegen von Artikeln zwischen Lagerzellen, mit begleitenden Berichten. Einblick in die Lagerbedingungen mit Informationen wie: Ident, Beschreibung, Menge, Preis, Lieferant, Charge, Lagerzelle und ähnliches."])},
  "products_warehouse_text_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventar. Einblick in die Bewegungsgeschichte von Lagerartikeln mit transparenter Darstellung aller notwendigen Informationen."])},
  "products_dms_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentenverwaltungssystem (DMS)"])},
  "products_dms_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erschwinglich und praktisch"])},
  "products_dms_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Dokumenten-Management-System (DMS) dient der Verwaltung aller Dokumente im Unternehmen. Es ermöglicht die Ablage aller Arten von Dokumenten nach dem gleichen Prinzip wie wir physische Dokumente aufbewahren. Wenn wir physische Dokumente erhalten und klassifizieren Ordnern, wir machen dasselbe in DMS mit elektronischen Ordnern."])},
  "products_dms_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist nur ein kleiner Teil der Funktionalität unseres DMS-Systems, das vollständig in den Finanzfluss der von uns gespeicherten Dokumentation integriert ist, sodass wir alle wichtigen Daten (Kunden, Lieferanten, Beträge, Belegnummern, usw.) in das System, wir verwenden sie immer noch für die Buchhaltung und das Berichtswesen."])},
  "products_row_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassen eingehender Rechnungen und Starten des Genehmigungsprozesses durch einen vordefinierten Workflow"])},
  "products_row_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Ausgangsrechnungen ausstellen und notwendige Dokumente vorbereiten"])},
  "products_row_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglichkeit wiederkehrende ausgehende Rechnungen zu klonen."])},
  "products_row_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglichkeit, eingehende Rechnungen mit genehmigten Bestellungen zu verknüpfen."])},
  "products_row_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Überprüfung des Budgetverbrauchs"])},
  "products_row_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integration mit POS-Terminals"])},
  "products_row_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warenreservierung (ein- und ausgehende Lagertransaktionen)"])},
  "products_row_eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsverfolgung - detaillierte Informationen zum Warenfluss anhand von Rechnungspositionen"])},
  "products_row_nine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Abrechnung basierend auf vordefinierten Reservierungsprofilen"])},
  "products_finance_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparent und genau"])},
  "products_finance_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Budgetanfrage"])},
  "products_finance_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unser Finanz- und Buchhaltungsmodul basiert auf den weltweit besten Praktiken, bei denen Sie planmäßig planen und ausgeben. Vergleichen Sie auch das Geplante mit dem Realisierten. Forspace stellt sicher, dass alles genau und transparent ist. "])},
  "products_finance_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung des Firmenbudgets nach Art der Ausgaben"])},
  "products_finance_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Verwaltung des Unternehmensbudgets nach Kostenstellen und Kostenarten"])},
  "products_finance_workflow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeiten mit Ablauf oder Arbeiten nach Ablauf"])},
  "products_finance_workflow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eingehende Dokumente - Schritte im Rechnungsbearbeitungsprozess"])},
  "products_finance_assets_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagevermögen"])},
  "products_finance_assets_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf, Nutzung und Abschreibung von Anlagevermögen"])},
  "products_paydesk_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasse"])},
  "products_paydesk_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quittung und Zahlung"])},
  "products_paydesk_header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles was Sie brauchen, um Ihre Kasse exakt, transparent, genehmigungs- und budgetgerecht zu führen."])},
  "products_paydesk_01_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassung und Bestätigung des Geldeingangs nach vordefiniertem Workflow"])},
  "products_paydesk_02_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erfassen und Prüfen von Barzahlungen nach einem vordefinierten Workflow"])},
  "products_paydesk_03_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasseneingang und Zahlungsberechnung"])},
  "products_paydesk_04_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassenjournal und Dokumentenerstellung"])},
  "products_paydesk_05_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kassenjournalbuchungen einsehen und Belege generieren"])},
  "products_paydesk_06_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatisches Verbuchen der täglichen Transaktionen im Hauptbuch auf Basis der der Kasse zugeordneten Konten"])},
  "products_bank_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoauszüge"])},
  "products_bank_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Verarbeitung und Verbuchung"])},
  "products_bank_1_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In verschiedenen Formaten importieren"])},
  "products_bank_1_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import von elektronischen Kontoauszügen in verschiedenen Formaten"])},
  "products_bank_1_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronisches Bankformat"])},
  "products_bank_1_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programme zur automatischen Konvertierung für spezielle elektronische Bankformate"])},
  "products_bank_1_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitsablauf"])},
  "products_bank_1_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Buchung und Verarbeitung von Kontoauszügen"])},
  "products_bank_2_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Journalbuchungen"])},
  "products_bank_2_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Journalbuchungen vom Kontoauszug"])},
  "products_bank_2_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnerregister"])},
  "products_bank_2_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfacher Zugriff auf alle Informationen zu Partnerbanken und Bankkonten"])},
  "products_bank_2_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stammdaten"])},
  "products_bank_2_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fähigkeit, Arten von Kontoauszugspositionen und zugehörigen Konten zu erkennen"])},
  "prodts_tax_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MwSt.-Registrierung"])},
  "products_tax_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allgemeine Bücher ausgestellter und erhaltener Rechnungen"])},
  "products_tax_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT REFUND DOCUMENT"])},
  "products_tax_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Erstellung des MwSt.-Erklärungsdokuments."])},
  "products_tax_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MwSt.-Buchung"])},
  "products_tax_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Verbuchung der Mehrwertsteuer"])},
  "products_tax_heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTROLLBERICHTE"])},
  "products_tax_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bücher ausgestellter und erhaltener Rechnungen"])},
  "products_hr_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalwesen und Gehaltsabrechnung"])},
  "products_hr_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit dem Modul Forspace HR und dem Modul GEHALTSABRECHNUNG hat Ihr Unternehmen die Möglichkeit, Personalakten nach modernsten Standards zu führen automatisiertes Vertragserstellungsmodul, das die Arbeit Ihrer HR-Mitarbeiter erheblich erleichtert. Für das Gehaltsabrechnungsmodul haben Sie zahlreiche Vorteile und Automatisierungen sowie regelmäßige Einstellungen, die den Standards der Gesetzgebung und der DSGVO entsprechen."])},
  "hr_heading_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalregister"])},
  "hr_heading_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufzeichnungen von Familienangehörigen, Gebietsfremden/Ausländern, Arbeitserlaubnis, diverse Zeugnisse"])},
  "hr_heading_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parameter für Gehaltsberechnung"])},
  "hr_heading_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überwachung diverser Abwesenheiten"])},
  "hr_heading_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arbeitszeitnachweis"])},
  "hr_heading_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen zur Mitarbeitergesundheit"])},
  "hr_heading_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufzeichnungen über Verschuldung, Schuldentilgung, Kindesunterhalt"])},
  "hr_heading_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible Definition der Berechnungseinstellungen - Gehaltsberechnungsprofil"])},
  "hr_heading_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschiedliche Berechnungsarten je nach Art des Arbeitsverhältnisses"])},
  "hr_heading_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschiedliche Methoden für Berechnungsarten je nach Art der Einrichtung"])},
  "hr_heading_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschiedliche Methoden zur Berechnung des Grundgehalts"])},
  "hr_heading_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten für mehrere Mitarbeiter gleichzeitig über Importer erfassen, bearbeiten oder löschen"])},
  "hr_heading_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Möglichkeit der Neuberechnung für einen einzelnen Mitarbeiter oder für alle Mitarbeiter"])},
  "hr_heading_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von kurz- und langfristigen Darlehen und sonstigen Abzügen"])},
  "hr_heading_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwaltung von Zusatzzahlungen (Prämien, Zulagen für warmes Essen und Transport, Dienstalterserhöhung)"])},
  "hr_heading_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglichkeit Einzahlungsscheine als PDF auszudrucken oder per E-Mail zu versenden"])},
  "hr_heading_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Buchung im Hauptbuch"])},
  "hr_heading_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Steuerung der gleichzeitigen Verwendung und Bearbeitung von Daten in Mitarbeiterakten bei der Lohnabrechnung"])},
  "hr_heading_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumente zum elektronischen Zahlungsverkehr"])},
  "services_slider_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklung von Web- und Desktop-Anwendungen"])},
  "services_slider_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web- und Desktop-Anwendungen"])},
  "services_slider_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erleichterung von Geschäftsprozessen mit mobilen Anwendungen"])},
  "services_slider_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Apps"])},
  "services_slider_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserung von Geschäftslösungen durch API-Integration"])},
  "services_slider_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Integration"])},
  "services_container_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwicklerdienste und Entwicklung"])},
  "services_icon_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web- und Desktop-Anwendungen"])},
  "services_icon_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Apps"])},
  "services_icon_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-Integration"])},
  "services_grid_div4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment-Provider-Anwendungen für Zahlungssysteme im eCommerce-Bereich und Integration in die gesamte Prozesskette."])},
  "services_grid_div5_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundenspezifische Lösungen"])},
  "services_grid_div5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir sind Experten darin, verschiedene geschäftliche Anforderungen/Ideen unserer Kunden in digitale Produkte umzusetzen, die zur Verbesserung ihrer Geschäftsprozesse, Entwicklung und digitalen Transformation bestimmt sind."])},
  "contact_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Senden Sie uns eine Nachricht"])},
  "contact_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Name"])},
  "contact_form_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre E-Mail"])},
  "contact_form_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachrichtentext"])},
  "contact_form_nameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihren Namen ein"])},
  "contact_form_emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie Ihre E-Mail-Adresse ein"])},
  "contact_form_messageInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie eine Nachricht ein"])},
  "contact_form_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karte"])},
  "footer_forspace_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frospace Produkte und Dienstleistungen"])},
  "footer_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buchhaltung"])},
  "footer_controlling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrolle"])},
  "footer_human_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal"])},
  "footer_all_in_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-in-One ERP-Lösung"])},
  "footer_sales_menagment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vertriebsmanagement"])},
  "footer_mobile_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Anwendungen"])},
  "footer_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DSGVO"])},
  "footer_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE-Dienste"])},
  "footer_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktieren Sie uns"])},
  "footer_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulevar Meša Selimovića 16"])},
  "footer_address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosnien und Herzegowina"])},
  "footer_address_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33/770-949"])},
  "footer_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 Forspace - Alle Rechte vorbehalten"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachricht senden"])},
  "Kauf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kauf"])},
  "Verkauf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf"])},
  "Produktion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "Lager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS"])},
  "Finanzen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzen"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])},
  "company_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firmen-URL"])},
  "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufen"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verkauf"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produktion"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lager"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzen"])},
  "services-_icon_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linux, Unix, macOS, Windows"])},
  "services-_icon_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützt auf Android- und iOS-Betriebssystemen"])},
  "services-_icon_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf Server"])},
  "why_companies_need_dms_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Unternehmen zu führen kann sehr stressig seinWege, und eine besondere Herausforderung dabei ist die Art und Weise, wie das Unternehmen Dokumente verwaltet. Glücklicherweise gibt es heute Softwarelösungen, die sich um die Dokumentation des Unternehmens kümmern."])},
  "why_companies_need_dms_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Was ist ein DMS-Dokumentenmanagementsystem?"])},
  "why_companies_need_dms_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Dokumentenmanagementsystem (DMS) ist eine Software, die es Unternehmen ermöglicht, digitale Dokumente zu erstellen, zu speichern, zu ändern, zu verwalten und herunterzuladen, die für das Geschäft notwendig sind. Die Mehrheit dieser Software basiert auf der Cloud, was bedeutet, dass die Mitarbeiter des Unternehmens dies können jederzeit und überall verfügbar haben, erstellen und ändern. Ein Dokumentenmanagementsystem erleichtert den Fluss kritischer Informationen in der gesamten Organisation, da Dokumente digital mit allen Mitarbeitern im Unternehmen geteilt werden können."])},
  "why_companies_need_dms_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Warum braucht ein Unternehmen ein DMS - Dokumentenmanagementsystem?"])},
  "why_companies_need_dms_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Dokumentenmanagementsystem ist heutzutage ein unverzichtbares Werkzeug für jede Art von Unternehmen. Der erste größte Vorteil ist natürlich der reduzierte Papierverbrauch. Da Sie Dokumente digital in einem DMS speichern und teilen können, wird der Papierverbrauch erheblich sein reduzieren und Ihr Beitrag zum Umweltschutz wird steigen. Es gibt noch weitere Vorteile des Einsatzes von DMS für Ihr Unternehmen, von denen wir im Folgenden einige vorstellen."])},
  "why_companies_need_dms_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sichere Speicherung von Dateien und Daten"])},
  "why_companies_need_dms_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Dokumente, die Ihr Unternehmen betreffen, enthalten Schlüsselinformationen, auf die Sie von Zeit zu Zeit zugreifen müssen. Physische Dokumente laufen immer Gefahr, während der Lagerung oder des Transports verloren zu gehen oder durch unvorhergesehene Unfälle wie Feuer oder Überschwemmung beschädigt zu werden ... Im Laufe der Zeit nutzen sich sogar physische Dokumente ab und müssen neu erstellt werden, um sie in gutem Zustand zu halten. Mit einem Dokumentenmanagementsystem sind diese Probleme für immer gelöst. Da es keine physischen Dokumente gibt, um die Sie sich kümmern müssen, haben Sie es nicht um sich über Verlust oder Beschädigung Sorgen zu machen. Ihre Daten können also nicht verloren gehen, es sei denn, Sie möchten sie löschen. Außerdem können Sie mit Forspace DMS entscheiden, wer Zugriff auf welches Dokument haben soll, sodass alle sensiblen Daten sicher sind und nur diesen Zugriff gewährt wird Personen, mit denen Sie es teilen möchten."])},
  "why_companies_need_dms_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Physische Dokumentation gehört der Vergangenheit an"])},
  "why_companies_need_dms_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für manche Jobs, wie Buchhaltung und Rechtsfragen, ist diese Art von Software ein echter Lebensretter. Anstatt für jede Kleinigkeit einen Stapel Papier durchforsten zu müssen, können Sie Ihre Dokumente einfach digitalisieren und den Führenden vertrauen OCR-Suche, damit Sie alle Ihre Dateien finden können, selbst wenn sie in schreibgeschützten Formaten wie Bildern, Scans oder PDFs vorliegen."])},
  "why_companies_need_dms_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten können einfach geteilt werden"])},
  "why_companies_need_dms_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kommunikation zwischen Mitarbeitern sollte so einfach sein, als ob sie nebeneinander sitzen würden. Das Forspace DMS-System ermöglicht es Ihnen, alle Informationen und Dokumente schnell, einfach und unkompliziert mit Ihren Kollegen zu teilen, ohne dass Sie dies tun müssen Zeit damit verbringen, E-Mails zu schreiben oder anzurufen."])},
  "why_companies_need_dms_h6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesteigerte Produktivität am Arbeitsplatz"])},
  "why_companies_need_dms_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn ein Unternehmen seine Dokumente physisch speichert, wird dabei viel Zeit und Arbeitskraft verschwendet. Das Speichern und Teilen von Dokumenten wird zu einem umständlichen Prozess, der viel unnötigen Aufwand erfordert. Dies verringert wiederum die Gesamtproduktivität der Mitarbeiter . Ein Dokument physisch zu suchen und es dann an den Schreibtisch des Mitarbeiters zu liefern, der es benötigt, stellt Zeit- und Ressourcenverschwendung dar. Ein Dokumentenmanagementsystem vereinfacht das Auffinden von Dokumenten, da es den Mitarbeitern ermöglicht, von überall durch eine einfache Suche auf das zuzugreifen, was sie benötigen . Mit einem DMS können Sie eine Datei innerhalb von Sekunden ganz einfach mit jedem teilen, den Sie brauchen. Ihre Mitarbeiter werden von der Zeit befreit, die sie physisch nach Dateien suchen müssen, und diese Zeit kann stattdessen sinnvoller genutzt werden, wodurch ihre Produktivität gesteigert wird."])},
  "why_companies_need_dms_h7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhebliche Einsparungen"])},
  "why_companies_need_dms_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Verwendung eines Dokumentenmanagementsystems spart Ihnen auch viel Geld. Auf den ersten Blick mag es wie eine Investition erscheinen, weil Sie Software kaufen müssten, aber der Return on Investment ist ebenfalls erheblich. Erstens können Sie viel sparen auf Papier und verwandten Produkten. Darüber hinaus führt die zunehmende Produktivität der Mitarbeiter durch den Wegfall des traditionellen Dateiverwaltungssystems zu einer verbesserten Effizienz und höheren Gewinnen."])},
  "why_companies_need_dms_h8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP verfügt über ein integriertes Dokumentenmanagementsystem DMS, das die Speicherung, Archivierung und Verarbeitung aller Arten von Dokumenten ermöglicht. Forspace DMS ermöglicht die Verbindung zwischen Benutzern, vollständig harmonisierte Daten und einen Überblick über das gesamte Unternehmen."])},
  "warum_unternehmen_brauchen_dms_h8_additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für eine kostenlose Präsentation von Forspace DMS kontaktieren Sie uns telefonisch unter 033/774-873 oder per E-Mail"])},
  "why_web_based_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-basierte Anwendungen haben sich in den letzten Jahren erheblich weiterentwickelt und werden zunehmend eingesetzt, da sie es Unternehmen in verschiedenen Branchen ermöglichen, kritische Geschäftsdaten zu erstellen, zu bearbeiten, darauf zuzugreifen und zu teilen. Mit dem Voranschreiten von Unternehmen wird Cloud Computing immer dominanter Player für Geschäftsdaten in Bezug auf mehr Sicherheit, schnellere Implementierung und Upgrades sowie Reduzierung der Gesamtkosten. Immer mehr Unternehmen verlagern ihr ERP-System in die Cloud, und erfahren Sie im Folgenden, warum das so ist."])},
  "why_web_based_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geringere Einrichtungs- und Implementierungskosten"])},
  "why_web_based_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-basierte ERP-Systeme haben im Gegensatz zum traditionellen, gehosteten Modell niedrige Implementierungskosten. Die Implementierung vor Ort erfordert normalerweise erhebliche Investitionen in Hardware und professionelles Personal. Mit Cloud-basierter Software, externem Hosting und Anbieter-Know-how Dadurch entfällt der Bedarf an zusätzlicher Hardware oder Personal. Die Reduzierung der Gesamtkosten ist der erste und wichtigste Grund, warum Unternehmen auf Cloud-basiertes ERP umsteigen."])},
  "why_web_based_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flexible Datenspeicherung"])},
  "why_web_based_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Hardware muss gekauft werden, wenn die Server vor Ort voll sind. Mit Cloud-basiertem ERP können Unternehmen den Speicherplatz je nach Bedarf schnell erhöhen oder verringern."])},
  "why_web_based_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfachere Datensicherung und -wiederherstellung"])},
  "why_web_based_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On-Premises-ERP beinhaltet einen komplexen Datensicherungsprozess und erfordert normalerweise einen Wartungsplan in SQL Server sowie andere Produkte/Dienstleistungen Benutzer, mit der Möglichkeit Sicherungskopien von Benutzern zu erstellen."])},
  "why_web_based_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhte Sicherheit und Skalierbarkeit"])},
  "why_web_based_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokale Umgebungen sind normalerweise weniger sicher und erfordern eine teure zusätzliche Überwachung und einen dedizierten IT-Administrator. Rechenzentren im Besitz von Forspace sind sicher, zertifiziert und entsprechen internationalen Standards. Skalierbarkeit ist das größte Problem bei lokalen ERP-Systemen, da sie verbunden sind an vorhandene Hardware-Fähigkeiten des Unternehmens anpassen Cloud-basierte ERP-Systeme bieten Skalierbarkeitsoptionen, wie das Hinzufügen neuer Lizenzen und mehr Benutzer, wenn das Unternehmen neue Mitarbeiter einstellt Darüber hinaus bieten sie zusätzlichen Speicherplatz und bieten Möglichkeiten zur Anpassung an neue Produktionsprozesse und -anlagen ."])},
  "why_web_based_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsame Datenplattform"])},
  "why_web_based_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cloud-ERP-Systeme bieten eine gemeinsame Plattform, auf der verschiedene Abteilungen, darunter Fertigung, Vertrieb, Marketing, Finanz- und Rechnungswesen, Kundenbeziehungsmanagement und Lieferantenbeziehungen, Schlüsseldaten überall, jederzeit und auf jedem Gerät austauschen können. Darüber hinaus können Lokale Lösungen sind in der Regel schwieriger in andere Anwendungen zu integrieren als Cloud-basierte Lösungen."])},
  "what_is_api_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Begriff „API“ wird häufig in der Webentwicklung verwendet. Updates des Betriebssystems, von Webbrowsern und Anwendungen kündigen oft neue APIs für Entwickler an. Was ist also eine API und wie verwenden Entwickler sie?"])},
  "what_is_api_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserte Zusammenarbeit"])},
  "what_is_api_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API ist ein Akronym für Application Programming Interface - ein Software-Vermittler, der es zwei Anwendungen ermöglicht, miteinander zu kommunizieren. Jedes Mal, wenn Sie eine App wie Facebook verwenden, eine Nachricht senden oder das Wetter auf Ihrem Telefon überprüfen, werden Sie Zweck der API-Spezifikation ist die Standardisierung des Datenaustauschs zwischen Webdiensten.In diesem Fall bedeutet Standardisierung die Fähigkeit, verschiedene Systeme, in verschiedenen Programmiersprachen geschrieben und auf verschiedenen Betriebssystemen oder unter Verwendung verschiedener Technologien auszuführen nahtlos miteinander kommunizieren."])},
  "what_is_api_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfache Innovationen"])},
  "what_is_api_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs befinden sich zwischen der Anwendung und dem Webserver und fungieren als Zwischenschicht, die den Datentransfer zwischen den Systemen handhabt."])},
  "what_is_api_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenmonetarisierung"])},
  "what_is_api_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Funktionsweise der API kann in mehreren Schritten erklärt werden:"])},
  "what_is_api_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zusätzliche Sicherheit"])},
  "what_is_api_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Client-Anwendung initiiert einen API-Aufruf, um Informationen abzurufen, und dieser Vorgang wird als Anfrage bezeichnet. Diese Anfrage wird von der Anwendung an den Webserver über den Uniform Resource Identifier (URI) der API verarbeitet und enthält ein Anfragesubjekt, eine Überschrift und manchmal eine Erläuterung der Anfrage."])},
  "what_is_api_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemeinsame Datenplattform"])},
  "what_is_api_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Erhalt einer gültigen Anfrage ruft die API ein externes Programm oder einen Webserver auf."])},
  "what_is_api_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server sendet eine Antwort mit den angeforderten Informationen an die API."])},
  "what_is_api_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die API leitet Daten an die Ursprungsanwendung weiter, die die Anfrage gestellt hat"])},
  "what_is_api_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige der Hauptvorteile der API sind:"])},
  "was_is_api_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs ermöglichen die Integration zweier Plattformen, sodass diese nahtlos miteinander kommunizieren können. Durch diese Integration können Unternehmen Arbeitsabläufe automatisieren und die Zusammenarbeit am Arbeitsplatz verbessern. Ohne APIs würde vielen Unternehmen die Konnektivität fehlen und sie würden unter Informationssilos leiden, die Produktivität und Leistung bedrohen."])},
  "what_is_api_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APIs bieten Flexibilität und ermöglichen es Unternehmen, sich mit neuen Geschäftspartnern zu verbinden, neue Dienste in einem bestehenden Markt anzubieten und letztendlich auf neue Märkte zuzugreifen, die enorme Renditen generieren und die digitale Transformation vorantreiben können."])},
  "what_is_api_p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viele Unternehmen entscheiden sich dafür, APIs zumindest anfangs kostenlos anzubieten, damit sie ein Publikum von Entwicklern rund um ihre Marke aufbauen und Beziehungen zu potenziellen Geschäftspartnern aufbauen können. Wenn die API jedoch Zugriff auf wertvolle digitale Assets gewährt, es kann durch den Verkauf von Zugriffen monetarisiert werden (dies wird als API-Ökonomie bezeichnet)."])},
  "what_is_api_p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie bereits erwähnt, schaffen APIs eine zusätzliche Schutzebene zwischen Ihren Daten und dem Server. Entwickler können die API-Sicherheit weiter stärken, indem sie Token, Signaturen und TLS-Verschlüsselung (Transport Layer Security) verwenden, indem sie ein API-Gateway für die Verwaltung implementieren und Authentifizieren des Datenverkehrs und Praktizieren eines effizienten API-Managements."])},
  "digita_trans_adv_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Konzept der digitalen Transformation impliziert den strategischen Einsatz digitaler Technologien, der Ihr Geschäft vereinfacht und beschleunigt, von Ihrem Geschäftsmodell bis zu Ihren internen Prozessen. Durch die Digitalisierung Ihres Unternehmens ist es Ihr Ziel, die Vorteile der Digitalisierung zu nutzen Transformation, die die Steigerung Ihres Umsatzes, die Verbesserung der Benutzererfahrung und die Erlangung eines Wettbewerbsvorteils umfassen kann."])},
  "digita_trans_adv_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserte Effizienz"])},
  "digita_trans_adv_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einer der größten Vorteile der Implementierung eines ERP-Systems in Ihrem Unternehmen ist eine dramatische Effizienzsteigerung. Die Art und Weise, wie Ihre Mitarbeiter von Abteilung zu Abteilung kommunizieren, der kontinuierliche Datenfluss im gesamten Unternehmen, der reibungslose Übergang von Phase zu Phase Phase während der Lebensdauer des Benutzers - all diese Vorteile tragen zu einem effizienteren Geschäftsprozess bei, der Zeit, Geld und Ressourcen sparen kann."])},
  "digita_trans_adv_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kostenersparnis"])},
  "digita_trans_adv_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit der richtigen ERP-Software gibt es viele Möglichkeiten, Kosten einzusparen, insbesondere in operativen Prozessen. Beispielsweise hat ein produzierendes Unternehmen einen besseren Einblick in die benötigten Mengen und Zeitpläne von Rohstoffen. Durch eine strategische Bestellung von Rohstoffen , datengesteuert, Einsparungen werden unvermeidlich."])},
  "digita_trans_adv_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewinnwachstum"])},
  "digita_trans_adv_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Minimierung der Kosten ist es einfacher, die Gewinne zu steigern. Die Maximierung von Chancen durch die Identifizierung von Lücken in aktuellen Geschäftsprozessen ist für den Fortschritt des Unternehmens von entscheidender Bedeutung. Durch die Verbesserung der Transparenz in verschiedenen Aspekten des Geschäfts können Mitarbeiter und Management ihre Best Practices verbessern und den Umsatz steigern."])},
  "digita_trans_adv_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbesserung des Wettbewerbsvorteils"])},
  "digita_trans_adv_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indem Sie die Infrastruktur Ihres Unternehmens verbessern, verbessern Sie Ihren Marktvorteil. Am besten planen Sie im Voraus die Features und Funktionalitäten, die Ihr neues System benötigt, um Ihr Unternehmen zu stärken. Beteiligen Sie sich in dem, worin Sie gut sind, und finden Sie die Teile des Geschäfts, die Sie verbessern und daran arbeiten können. Die Merkmale und Funktionen der Software, die Sie wählen, sollten die Bedürfnisse widerspiegeln und Ihre Differenzierungsfaktoren verbessern."])},
  "digita_trans_adv_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhöhte Agilität"])},
  "digita_trans_adv_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit zunehmender Automatisierung und verbesserter Produktivität geht eine größere Agilität einher. Nur durch die Entwicklung einer digitalen Strategie können Unternehmen innerhalb von Projekten und Betriebsbereichen wirklich agil werden Ihre Organisationen werden agil. Das beschleunigt Ergebnisse, Informationen, Berichterstattung und Entscheidungsfindung."])},
  "digita_trans_adv_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Server sendet eine Antwort mit den angeforderten Informationen an die API."])},
  "entrepeneur_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Prozess der Unternehmensgründung wird als Unternehmertum bezeichnet. Ein Unternehmer wird normalerweise als Innovator angesehen, als Quelle neuer Ideen, Waren, Dienstleistungen und/oder Geschäftsverfahren."])},
  "entrepeneur_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovatoren"])},
  "entrepeneur_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innovatoren sind die Art von Unternehmern, die völlig neue Ideen entwickeln und sie in tragfähige Unternehmen verwandeln. In den meisten Fällen verändern diese Unternehmer die Denkweise der Menschen und tun Dinge anders. Solche Unternehmer neigen dazu, extrem leidenschaftlich und obsessiv zu sein , weil sie ihre Motivation aus der Einzigartigkeit ihrer Geschäftsidee ziehen. Innovative Unternehmer finden auch neue Wege, ihre Produkte zu vermarkten, indem sie Produktdifferenzierungsstrategien wählen, die ihr Unternehmen von der Masse abheben. Manchmal geht es nicht nur darum, sich von der Masse abzuheben, sondern aber in Wirklichkeitein neues Publikum erschaffen."])},
  "entrepeneur_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hustler"])},
  "entrepeneur_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anders als Innovatoren, deren Vision Gas im Motor ist, arbeiten Hustler härter und sind bereit, sich die Hände schmutzig zu machen. Diese Art von Unternehmern konzentrieren sich am Anfang auf kleine Ziele mit dem Wunsch, in Zukunft größer zu werden. Hustler sind es von ihren Träumen motiviert und sie werden extrem hart arbeiten, um sie zu verwirklichen. Allerdings dauert es viel länger, bis ihre Träume wahr werden, als die meisten anderen Unternehmertypen."])},
  "entrepeneur_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachahmer"])},
  "entrepeneur_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachahmer sind Unternehmer, die bestimmte Geschäftsideen kopieren und verbessern. Sie suchen immer nach Möglichkeiten, ein bestimmtes Produkt zu verbessern, um sich einen Vorteil auf dem Markt zu verschaffen. Nachahmer sind teils Innovatoren und teils Jäger, die halten sich nicht an die Vorgaben anderer Menschen und haben viel Selbstvertrauen."])},
  "entrepeneur_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entdecker"])},
  "entrepeneur_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auch nachdem sie eine Idee haben, nehmen sich Forscher die Zeit, alle relevanten Informationen darüber zu sammeln. Für sie ist Scheitern keine Option, weil sie die Idee aus allen Blickwinkeln analysieren. Diese Art von Unternehmern glaubt normalerweise an Die Gründung eines Unternehmens hat hohe Erfolgsaussichten, da sie viel Zeit und Ressourcen investieren, um alle Aspekte einer Geschäftsidee zu verstehen.Infolgedessen brauchen diese Arten von Unternehmern normalerweise lange, um Produkte auf den Markt zu bringen, um Entscheidungen zu treffen, weil sie sie brauchen eine Grundlage für tiefes Verständnis. Diese Unternehmer verlassen sich viel mehr auf Daten und Fakten als auf Instinkte und Intuition."])},
  "entrepeneur_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden"])},
  "entrepeneur_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Hauptmerkmal dieser Art von Unternehmern ist ihr Vermögen. Diese Arten von Unternehmern haben Geld und sind darauf spezialisiert, vielversprechende Unternehmen zu kaufen. Unternehmer, die Käufer sind, werden das Unternehmen identifizieren und seine Lebensfähigkeit bewerten, mit dem Erwerb fortfahren und das Beste finden geeignete Person, um es zu führen und zu entwickeln."])},
  "HowErp_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-Systeme sind für Unternehmen jeder Größe gedacht, ob klein, mittel oder groß, die ihre Geschäftsprozesse und Daten an einem Ort integrieren möchten. Kein Unternehmen kann zu klein sein, um das Geschäft vollständig zu kontrollieren und zu nutzen intelligente Geschäftslösungen zur Vereinfachung ihrer Geschäftsprozesse. Kleine Unternehmen verwenden ERP-Software, um die Kommunikation zwischen den Abteilungen zu verbessern und Geschäftsprozesse mit vollständiger Transparenz aller Vorgänge zu optimieren."])},
  "HowErp_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informationen teilen"])},
  "HowErp_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Integration von Geschäftsprozessen in eine Plattform wird es einfacher machen, Daten und Informationen zwischen Abteilungen auszutauschen."])},
  "HowErp_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienz steigern"])},
  "HowErp_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effizienzsteigerung ohne Einstellung neuer Mitarbeiter oder Erweiterung des Betriebs durch Automatisierung von Prozessen und Eliminierung redundanter Arbeit. Das wahre Bild kleiner Unternehmen sieht so aus, dass ein Mitarbeiter immer mehrere Jobs erledigt. Jeder wird eingebunden, wo und wann immer es nötig ist. ERP-Systeme ausgerichtet In kleinen Unternehmen automatisieren sie wichtige Geschäftsfunktionen und erleichtern die Arbeit Ihrer Mitarbeiter, damit sie sich auf wichtigere Geschäftsaufgaben konzentrieren können."])},
  "HowErp_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business Intelligence"])},
  "HowErp_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durch die Verwendung eines ERP-Systems haben Sie immer leicht zugängliche und genaue Daten. Diese Arten von Systemen ermöglichen Ihnen eine erweiterte Berichterstattung und Überwachung von Fortschritt, Produktivität und Effekten. Auf diese Weise haben Sie immer einen vollständigen Einblick in Ihre Unternehmen für eine bessere Entscheidungsfindung. Wie können Sie ohne ein umfassendes System, das Daten sammelt, über Ihre Prozesse und Systeme berichtet und das Gesamtbild betrachtet, genau wissen, wo Sie sich befinden?"])},
  "HowErp_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vollständige Transparenz und Geschäftskontrolle"])},
  "HowErp_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um ein profitables Geschäft zu führen, müssen Sie die vollständige Kontrolle über Ihre Geschäftsressourcen haben, d. h. was Ihr Unternehmen an Bargeld, Rohstoffen, Fertigwaren, Waren, Personal und Produktionskapazität hat. Ihre Geschäftsressourcen sind, was Sie sind zur Aufrechterhaltung Ihres Geschäfts verwenden und Sie müssen jederzeit die vollständige Kontrolle über sie haben ERP, was früher stundenlange sorgfältige Datenerfassung und Berichterstellung erforderte, kann automatisch erfolgen, sodass Sie einen umfassenden Überblick über Ihr Unternehmen erhalten und kritische Leistungskennzahlen über Echtzeit-Dashboards messen können. Sie können diese Informationen verwenden, um immer zu wissen, wo Sie sind, um Produktionspläne zu optimieren und die Kapazität zu maximieren und Ihre Effizienz zu steigern."])},
  "software_solutions_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Unternehmen versuchen, die Gesamtleistung durch die Implementierung von ERP-Software zu verbessern. In vielen Fällen ist der ursprüngliche Treiber, der zum Kauf einer ERP-Software führt, nämlich der Austausch der alten durch eine neue, das Ziel, das Geschäft zu vereinfachen Prozesse für einen nachhaltigen Wettbewerbsvorteil und scheitern häufig an ERP-Software-Einführungennicht. Anstatt Kostensenkung, geschäftliche Agilität und Leistungssteigerung zu bringen, schaffen schlechte ERP-Systeme Komplexität, Doppelarbeit und im schlimmsten Fall schlechte Qualität und Kundenservice sowie einen gefährlichen Mangel an Geschäftseinblicken, der ein Unternehmen in den Ruin treiben kann."])},
  "software_solutions_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kommt vor, dass implementierte ERP-Systeme mit Veränderungen nicht Schritt halten. Die Fertigungsbranche ist mit sich ständig ändernden Geschäftsprozessen, Daten und Anforderungen konfrontiert, die es einem typischen, unflexiblen ERP-System fast unmöglich machen, mit dem Schritt zu halten Geschäft wirklich notwendig ist. Unten sind einige Warnzeichen, die Ihnen sagen, dass das Geschäft Ihres Unternehmens gefährdet ist, weil die ERP-Software nicht so funktioniert, wie sie eigentlich sollte, oder weil ihr rechtzeitig Upgrades fehlen."])},
  "software_solutions_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr ERP-System integriert keine Geschäftsdaten, die für das Erreichen der definierten Mission wichtig sind. Ihre Daten sind in Ihrem veralteten ERP-System \"eingesperrt\" und schwer zugänglich. Aus diesem Grund können Sie die für Entscheidungen erforderlichen Daten nicht analysieren. Informationen und Daten im System \"zirkulieren\" nicht durch Abteilungen, sondern sind in ihnen gefangen und existieren unabhängig voneinander. Dies erhöht die Komplexität und verdoppelt den Aufwand mit unterschiedlichen Versionen der Wahrheit, was die Qualität, Zuverlässigkeit und Verfügbarkeit beeinträchtigt lebenswichtige Informationen. Ohne die Transparenz Ihrer gesamten Unternehmenssoftware und eine nahtlose Struktur, die alle Berichtsfunktionen und Abteilungen Ihrer Software verbindet, können Sie nicht alle wichtigen Informationen sammeln, die Ihr Unternehmen für den Erfolg benötigt."])},
  "software_solutions_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Änderung im System ist teuer und stellt einen langfristigen Prozess dar. Der Softwarehersteller stellt Ihnen nicht die Software-Upgrades zur Verfügung, die Sie benötigen. Auch die Softwarepflege ist zu teuer für solch veraltete Software, die das nicht bringt alle Innovationen, die einen Verlust des Wettbewerbsvorteils kosten könnten. Sie stecken zwischen dysfunktionaler Software, die keine Verbesserungen bringt, und explodierenden Kosten."])},
  "software_solutions_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP-Software-Upgrades stören das Geschäft. Wir haben bereits festgestellt, dass Softwareanbieter-Upgrades im Durchschnitt alle 12 bis 24 Monate herauskommen. Sie erfordern oft eine Aktualisierung des Betriebssystems, des Datenbankverwaltungssystems, des Festplattenspeichers, der Hardware usw., .. braucht Zeit zum Planen und Ausführen Sie haben Probleme, wenn Sie Ihr Geschäft für eine Weile ignorieren müssen, um Ihre Software zu aktualisieren Darüber hinaus liefern Software-Upgrades sehr oft nicht das, was Ihr Unternehmen wirklich braucht Wenn und nach a Software-Upgrade, die Software bietet nicht die Einstellungen, die Sie und Ihr Unternehmen für Wachstum benötigen, es ist Zeit für Änderungen."])},
  "software_solutions_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Können Sie nicht auf Ihre Unternehmenssoftware zugreifen, während Sie zu einem Geschäftstermin reisen? Das bedeutet, dass Ihre Unternehmenssoftware es Ihnen nicht erlaubt, unterwegs auf Daten zuzugreifen. Es ist nur allzu offensichtlich, dass die Arbeit nicht aufhört, während Sie unterwegs sind Reisen. Smartphones helfen Ihnen, in Verbindung zu bleiben, aber wenn Ihre ERP-Software keinen mobilen App-Zugriff hat, haben Sie Pech. Drahtlose Verbindungen sind überall, aber Sie sind immer noch eingeschränkt, weil Sie nicht mit Geschäftsaktivitäten in Kontakt bleiben können Ihre Kunden sind nicht alle an einem Ort, und Sie auch nicht immer. Ort. Es ist zwingend erforderlich, dass Sie unterwegs per Knopfdruck auf Daten zugreifen können. Smartphones sind die halbe Lösung, aber wenn Ihr Der ERP-Anbieter bietet keine verbundene Anwendung, mit der Sie alle Ihre Daten in Echtzeit sehen können, Sie erhalten nicht das vollständige Bild Ihres Unternehmens, wenn Sie es brauchen."])},
  "software_solutions_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Mitarbeiter brauchen zu lange, um sich mit dem System vertraut zu machen, weil es zu kompliziert ist. Viele ältere ERP-Systeme sind schwer zu erlernen, und Mitarbeiter können leicht gestresst werden, wenn ihnen gesagt wird, dass sie „F1 drücken, um nachzufragen“ oder \"drücken Sie \"Enter um zu akzeptieren"])},
  "software_solutions_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die ERP-Software von Forspace ist eine umfassende Unternehmenslösung, die ihre Anwendung in verschiedenen Branchen und Geschäftsaktivitäten gefunden hat. Daher muss Forspace ständig aktualisiert, aktualisiert und verbessert werden, um seinen Benutzern die Zufriedenheit der Nutzung zu bieten und qualitativ hochwertige Unterstützung bei der Lösung von Geschäftsproblemen. Wählen Sie Forspace, um eine reibungslose und erfolgreiche Durchführung von Geschäftsaktivitäten zu gewährleisten. Wir sind in jeder Phase bei unseren Kunden und unsere Arbeit hört nicht auf, solange Sie unsere Dienste nutzen. Die Verbesserung Ihres Unternehmens ist wofür wir uns jeden Tag einsetzen, denn: Wir arbeiten nicht für Sie, wir arbeiten mit Ihnen!"])},
  "digital_trans_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die meisten Unternehmen versuchen, die Gesamtperformance durch den Einsatz von ERP-Software zu verbessern. In vielen Fällen ist der ursprüngliche Treiber, der zum Kauf einer ERP-Software führt, also der Austausch der alten gegen eine neue, das Ziel, die Geschäftsprozesse zu vereinfachen für einen nachhaltigen WettbewerbsvorteilERP-Softwareimplementierungen scheitern. Anstatt Kostensenkung, geschäftliche Agilität und Leistungssteigerung zu bringen, schaffen schlechte ERP-Systeme Komplexität, Doppelarbeit und im schlimmsten Fall schlechte Qualität und Kundenservice sowie einen gefährlichen Mangel an Geschäftseinblicken, der ein Unternehmen in den Ruin treiben kann."])},
  "digital_trans_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technologie wird Buchhalter unterstützen, nicht ersetzen"])},
  "digital_trans_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es kommt vor, dass implementierte ERP-Systeme mit den Veränderungen nicht Schritt halten. Die Fertigungsbranche ist mit sich ständig ändernden Geschäftsprozessen, Daten und Anforderungen konfrontiert, die es einem typischen, unflexiblen ERP-System fast unmöglich machen, mit den Veränderungen Schritt zu halten Geschäft wirklich notwendig ist. Unten sind einige Warnzeichen, die Ihnen sagen, dass das Geschäft Ihres Unternehmens gefährdet ist, weil die ERP-Software nicht so funktioniert, wie sie eigentlich sollte, oder weil ihr rechtzeitig Upgrades fehlen."])},
  "digital_trans_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Vorteile von Enterprise Resource Planning (ERP), Cloud Business und Automatisierungssoftware"])},
  "digital_trans_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr ERP-System integriert keine Geschäftsdaten, die für das Erreichen der definierten Mission wichtig sind. Ihre Daten sind in Ihrem veralteten ERP-System \"eingesperrt\" und schwer zugänglich. Aus diesem Grund können Sie die für Entscheidungen erforderlichen Daten nicht analysieren. Informationen und Daten im System \"zirkulieren\" nicht durch Abteilungen, sondern sind in ihnen gefangen und existieren unabhängig voneinander. Dies erhöht die Komplexität und verdoppelt den Aufwand mit unterschiedlichen Versionen der Wahrheit, was die Qualität, Zuverlässigkeit und Verfügbarkeit beeinträchtigt lebenswichtige Informationen. Ohne die Transparenz Ihrer gesamten Unternehmenssoftware und eine nahtlose Struktur, die alle Berichtsfunktionen und Abteilungen Ihrer Software verbindet, können Sie nicht alle wichtigen Informationen sammeln, die Ihr Unternehmen für den Erfolg benötigt."])},
  "digital_trans_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Relevanter, produktiver und kreativer denn je"])},
  "digital_trans_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Änderung im System ist teuer und stellt einen langfristigen Prozess dar. Der Softwarehersteller stellt Ihnen nicht die Software-Upgrades zur Verfügung, die Sie benötigen. Auch die Softwarewartung ist zu teuer für solch veraltete Software, die das nicht bringt alle Innovationen, die einen Verlust des Wettbewerbsvorteils kosten könnten. Sie stecken zwischen dysfunktionaler Software, die keine Verbesserungen bringt, und explodierenden Kosten."])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mehr erfahren"])},
  "appointment_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin vereinbaren"])},
  "appointment_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie eine Präsentation der Software wünschen, hinterlassen Sie Ihre Daten und wir werden Sie kontaktieren."])},
  "appointment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinbaren Sie gerne einen Termin mit unserem Team, um weitere Informationen zu unseren Produkten oder zu Prozessen in Ihrem Unternehmen zu erhalten, die eine Softwarelösung und Beratung erfordern. Möglicherweise haben wir aufgrund unserer Best Practices und Erfahrungen genau die perfekte Lösung für Sie ."])},
  "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gehe zum Formular"])},
  "slogan_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir arbeiten nicht für Sie!"])},
  "slogan_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir arbeiten mit Ihnen!"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einreichen"])},
  "get_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vernetzen Sie sich mit uns über soziale Netzwerke!"])},
  "find_us_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finden Sie uns auf der Karte"])},
  "products_tax_header_main_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDV"])},
  "products_tax_header_main_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatische Verarbeitung und Buchung"])},
  "products_fixed_asset_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kaufanfrage für Anlagegüter"])},
  "products_fixed_asset_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagevermögen registrieren"])},
  "products_fixed_asset_header_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standort und Identifizierung von Anlagevermögen"])},
  "products_fixed_asset_header_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschreibungsberechnung"])},
  "products_fixed_asset_header_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfache Inventarisierung des Anlagevermögens"])},
  "products_fixed_asset_header_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anlagevermögen buchen"])},
  "products_purchase_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfache Bestellung für den Geschäftsalltag"])},
  "products_hr_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effiziente Mitarbeiteraktenführung und Prozessautomatisierung"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück"])},
  "about_us_news_link_paragraf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP (Enterprise Resource Planning) ist ein Computerprogramm, mit dem Unternehmen ihre Ressourcen planen und verwalten können. Es besteht aus Modulen, die aktuelle Prozesse digital überwachen und größtenteils alle Prozessabläufe im Unternehmen abdecken (z. B. von der Kundenbestellung bis zur Auslieferung). ) . ERP vereint das Konzept aller Unternehmen unter einem Dach und ermöglicht so den Informationsfluss auf einer Plattform und die Konsistenz von Informationen und Berichten von unten nach oben. Außerdem ermöglicht es Unternehmen, ihre Prozesse digital zu transformieren und über ERP abzuwickeln, ohne dies zu tun erheblicher Einsatz anderer Tools und Software. Vor diesem Hintergrund ist ERP das wichtigste Werkzeug, mit dem Sie Ihr heutiges Geschäft verwalten, egal ob Sie in der Fertigung, im Vertrieb, im Service oder in anderen Bereichen tätig sind. Dabei geht es nicht um die Software selbst , es gibt viele Herausforderungen bei der Implementierung selbst. Es handelt sich um einen umfangreichen und komplexen Prozess, und es gibt zahlreiche Fälle von gescheiterten Implementierungen. Aufgrund unserer Erfahrung in verschiedenen Branchen und zahlreichen Implementierungen kann das Forspace-Team gemeinsam mit Ihnen eine erfolgreiche ERP-Implementierung durchführen Du!'"])},
  "about_us_news_link:hadding_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine Auswahl unserer Produkte oder eine ‚maßgeschneiderte‘ Lösung, was ist die Beste? Wir entscheiden gemeinsam mit Ihnen!"])},
  "header_secondary_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die erste inländische Marke auf dem ERP-Softwaremarkt, die die Reduzierung des Einsatzes schriftlicher Dokumentation ermöglicht, Zeit und Geld spart und dem Endbenutzer eine vollständig maßgeschneiderte und verständliche Softwarelösung präsentiert"])},
  "fintech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fintech"])},
  "fintech_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir liefern hochmoderne Lösungen im Fintech-Sektor und gehen auf die besonderen Bedürfnisse und Anforderungen von Finanzinstituten, Fintech-Start-ups und Unternehmen der Finanzdienstleistungsbranche ein."])},
  "fintech_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wir verbinden Finanzen und Technologie"])},
  "digital_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitale Zahlungen"])},
  "payment_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahlungsabwicklung, Geldtransferplattformen, mobile Wallets, kontaktlose Technologien"])},
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banking"])},
  "banking_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösungen für digitale Bankdienstleistungen."])},
  "finance_and_wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanz- und Vermögensverwaltung"])},
  "finance_and_wealth_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werkzeuge und Plattformen für Privatpersonen zur effektiven Verwaltung ihrer Finanzen"])},
  "bi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BI"])},
  "bi_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Breite Palette von Finanzberichten"])},
  "fintech_bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fintech revolutioniert die Finanzlandschaft durch den Einsatz von Technologie, um innovative und zugängliche Finanzlösungen zu liefern."])},
  "web_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webanwendungen"])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterstützung"])}
}