export default {
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna"])},
  "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novosti"])},
  "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
  "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodi"])},
  "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usluge"])},
  "appointment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termin"])},
  "our_team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naš tim"])},
  "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jezik"])},
  "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezerviši"])},
  "nav_news_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zašto kompanije trebaju DMS?"])},
  "nav_news_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistem baziran na web-u"])},
  "nav_news_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API integracija"])},
  "nav_news_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalizacija"])},
  "nav_news_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kako ERP sistem moze pomoci malom biznisu da raste ?"])},
  "nav_news_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znakovi da je Vašem poslovanju potrebno naprednije softversko rješenje"])},
  "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "faq-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preview"])},
  "promo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promocije"])},
  "presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Želim prezentaciju"])},
  "about_presentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Želim prezentaciju"])},
  "widget_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli"])},
  "widget_company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace Solutions"])},
  "widget_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalizacijom Vaših poslovnih procesa uz Forspace-ov ERP program želimo omogućiti da efikasnije vodite kompaniju te kontinuirano pratite, planirate i kontrolišete poslovne aktivnosti u svakom momentu."])},
  "widget_small_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za sva pitanja obratite nam se putem telefona ili maila"])},
  "widget_phone_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33 770 991"])},
  "widget_icon_one_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručnjaka"])},
  "widget_icon_two_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspješnih projekata"])},
  "widget_icon_three_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Godina iskustva"])},
  "home_icon_square": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brza i jednostavna rješenja"])},
  "home_icon_companies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša kompanija pod jednim krovom"])},
  "home_icon_companies_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralno upravljanje procesima."])},
  "home_icon_cloud": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistem baziran na web-u"])},
  "home_icon_cloud_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Java, WEB based, MySQL."])},
  "home_icon_config": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svi procesi u jednom toku"])},
  "home_icon_config_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapiranje stvarnih procesa u digitalne."])},
  "home_icon_circle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moderan koncept, jednostavan dizajn"])},
  "home_icon_circle_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podržava jednostavnu integraciju s drugim rješenjima."])},
  "home_forspace_erp_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP & Računovodstvo"])},
  "home_forspace_erp_accounting_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All-in-one ERP rješenje"])},
  "home_forspace_erp_accounting_text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software design and development"])},
  "recension_primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recenzije"])},
  "recension_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šta naši klijenti kažu o nama"])},
  "recension_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sve naše klijente ponosno ističemo i gradimo partnerske odnose na obostrano zadovoljstvo. Ukoliko neki od naših klijenata nisu još uvijek dodani na listu, ne znači da ih manje cijenimo ili da su nam manje važni."])},
  "recension_question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da li imate nekih pitanja ?"])},
  "recension_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte nas."])},
  "about_us_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli"])},
  "about_us_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum, dolor sit amet consectetur adipisicing elit. Corrupti, atque amet architecto vitae sapiente explicabo."])},
  "about_us_news_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novosti"])},
  "about_us_news_link_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP jedinstveno all-in-one cloud softversko rješenje za standardizaciju i digitalizaciju poslovnih procesa"])},
  "about_us_news_link_paragraf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP (Enterprise Resource Planning) je računarski program koji omogućava kompanijama da planiraju i upravljaju svojim resursima. Sastoji se od modula koji digitalno prate aktualne procese i uglavnom pokrivaju sve procesne tokove u kompaniji (npr. od narudžbe kupca do isporuke). ERP ima koncept svo poslovanje pod jednim krovom i to omogućava tok informacija u jednoj platformi i konzistentnost informacija i izvještaja od dna ka vrhu. Takođe omogućava kompanijama da se digitalno transformišu i vode svoje procese kroz ERP bez značajnog korištenja drugih alata i softvera. Uzimajući sve ovo u obzir, ERP je glavni alat kojim upravljate današnjim poslovanjem, bilo da se bavite proizvodnjom, prodajom, uslugama ili bilo čim drugim. Obzirom da nije pitanje samog softvera, postoje mnogi izazovi u samoj implementaciji. To je ekstenzivan i kompleksan proces, i postoje brojni slučajevi neuspješnih implementacija. Na osnovu našeg iskustva u različitim industrijama i brojnih implementacija, Forspace tim može izvršiti uspješnu implementaciju ERP-a zajedno sa vama!'"])},
  "about_us_news_link:hadding_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Selekcija naših proizvoda ili 'custom made' rješenje, šta je najbolje? Odlučujemo zajedno sa vama!"])},
  "about_us_news_link_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zašto kompanije trebaju DMS?"])},
  "about_us_news_link_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vođenje biznisa može biti stresno na mnogo načina a poseban izazov u ovom pogledu je i način na koji kompanija upravlja dokumentima."])},
  "about_us_news_link_heading_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zašto je poslovni program baziran na web-u pravi izbor?"])},
  "about_us_news_right_flex_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koji ste tip preduzetnika?"])},
  "about_us_news_right_flex_one_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preduzetništvo"])},
  "about_us_news_right_flex_one_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preduzetnik se obično smatra inovatorom, izvorom novih ideja, roba, usluga i/ili poslovnih procedura."])},
  "about_us_news_right_flex_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znakovi da je Vašem poslovanju potrebno naprednije softversko rješenje"])},
  "about_us_news_right_flex_two_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Softverska rješenja"])},
  "about_us_news_right_flex_two_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Većina kompanija implementacijom ERP softvera nastoji poboljšati ukupne performanse."])},
  "about_us_news_right_flex_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kako ERP može pomoći malom biznisu da raste?"])},
  "about_us_news_right_flex_three_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistem"])},
  "about_us_news_right_flex_three_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem za planiranje resursa preduzeća, takozvani ERP, upravlja svim segmentima poslovanja, uključujući proizvodnju, nabavku, prodaju, distribuciju, računovodstvo, ljudske resurse, sistem za uprav.."])},
  "about_us_news_right_flex_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kako digitalna transformacija pospješuje i olakšava posao računovođa?"])},
  "about_us_news_right_flex_four_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalna transformacija"])},
  "about_us_news_right_flex_four_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionalci u svim industrijama suočavaju se s velikim promjenama a mnoge od njih vođene su novim tehnologijama."])},
  "about_us_next_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slijedeće"])},
  "about_us_next_additional_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace Preview"])},
  "about_us_next_overlay_left_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prednosti digitalne transformacije"])},
  "about_us_next_overlay_left_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ubrzanje digitalnog svijeta zahtijeva prilagođavanje organizacija, zbog čega većina kompanija u svijetu radi na digitalnoj transform..."])},
  "about_us_next_overlay_right_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šta je API?"])},
  "about_us_next_overlay_right_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izraz “API” je često korišten u web razvoju. Ažuriranja operativnog sistema, web pretraživača i aplikacija često najavljuju nove API-je za programere. Stoga, šta je API i kako ga programeri.."])},
  "about_us_choose_small_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zašto izabrati nas"])},
  "about_us_choose_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posao iz jednog izvora"])},
  "about_us_aditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U našoj kompaniji"])},
  "about_us_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Forspace tim daje bezrezervnu podršku u svim fazama korištenja, a posebno smo ponosni na našu post-prodajnu uslugu, jer prihvatanjem ovog softvera možete računati na naš stalni angažman i kontakt. Mi smo prvi domaći brend na tržištu ERP softvera sa predznakom njemačke tehnologije koji omogućava smanjenje korištenja pisane dokumentacije, uštedu vremena i novca, te predstavlja potpuno prilagođeno i razumljivo softversko rješenje za krajnjeg korisnika"])},
  "about_check_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podržava jednostavnu integraciju s drugim rješenjima."])},
  "about_check_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mapiranje stvarnih procesa u digitalne."])},
  "about_check_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralno upravljanje procesima."])},
  "about_us_video_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace Preview"])},
  "about_us_video_paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogledajte naš kratki video"])},
  "about_us_nums_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace u brojkama"])},
  "about_us_nums_established": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osnovano"])},
  "about_us_nums_clients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klijenti"])},
  "about_us_nums_employed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaposlenih"])},
  "about_us_client_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Kada je u pitanju automatizacija računovodstva i potpuno prilagođavanje našim potrebama, ne tražimo dalje od tima Forspace Solutions. Njihov konkurentski krajolik je strm, ali Forspace to čini ostvarivim.\" "])},
  "about_us_client_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Kada je u pitanju automatizacija računovodstva i potpuno prilagođavanje našim potrebama, ne tražimo dalje od tima Forspace Solutions. Njihov konkurentski krajolik je strm, ali Forspace to čini ostvarivim.\" "])},
  "about_us_client_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" \"Kada je u pitanju automatizacija računovodstva i potpuno prilagođavanje našim potrebama, ne tražimo dalje od tima Forspace Solutions. Njihov konkurentski krajolik je strm, ali Forspace to čini ostvarivim.\" "])},
  "about_us_reserve_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rezervišite termin"])},
  "about_us_reserve_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osjećajte se slobodnim da rezervirate termin za sastanak sa našim timom za više informacija o našim proizvodima ili o procesima u vašoj kompaniji za koje je potrebno softversko rješenje i savjetovanje. Možda imamo upravo savršeno rješenje za vas na osnovu naše najbolje prakse i iskustva."])},
  "products_purchase_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabava"])},
  "products_purchase_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Forspace modul nabave vam omogućava jednostavnu narudžbu svega što vam treba za svakodnevno poslovanje. Sistem se brine o ispravnosti procesa: ko treba da odobri narudžbu? Informiše odjel nabave da treba prikupiti ponude. Provjerava da li su nabavke u skladu sa budžetom (ovdje je omogućen i automatski workflow za odobravanje prekoračenja budžeta od strane odgovorne osobe, kako proces ne bi bio u zastoju). Dozvoljava višestuko odobravanje predefinisanih osoba."])},
  "products_sales_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodaja"])},
  "products_sales_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maloprodaja - Veleprodaja"])},
  "products_sales_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Naš standardizirani modul veleprodaje ili maloprodaje zadovoljit će sve vaše potrebe pri poslovanju. Modul maloprodaje je dodatno prilagođen za rad sa POS uređajima i svim ostalim zakonskim potrebama i obavezama. Kontaktirajte nas za više detalja."])},
  "products_production_header_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodnja"])},
  "products_production_header_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kako proizvodnju staviti pod kontrolu?"])},
  "products_production_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodni modul je najveći izazov u implementaciji ERP sistema. Sa tim dolazi potreba kulturološke promjene unutar organizacije. Uposlenici treba da se prilagode različitim promjenama i optimizacijama procesa koje dolaze. ERP sistemi su izgrađeni na najboljim praksama u različitim industrijama, ali nekada softver nije baš najbolje prilagođen procesima koje određena kompanija ima. I u tim slučajevima imamo rješenje kroz custom-made prilagođavanja koje brzo i efikasno sprovodimo. Forspace tim nije sa vama samo tokom implementacije, mi radimo sa vama i nakon toga i dijelimo znanje o procedurama i metodologijama pomoću kojih ćete nastaviti uspješno koristiti EPR sistem."])},
  "products_warehouse_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladište"])},
  "products_warehouse_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centralna tačka logističkih aktivnosti"])},
  "products_warehouse_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["  Skladišno poslovanje je umjetnost premiještanja i čuvanja materijala u skladištu i proizvodnji. Pomoću tog modula pratimo kretanje materijala, poluproizvoda i proizvoda na transparentan način i u skladu sa planom i definisanim procesima. Uključuje fizički prostor i smještaj materijala, kao i softversko praćenje svih aktivnosti i transakcija."])},
  "products_warehouse_bold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neke od glavnih funkcionalnosti su:"])},
  "products_warehouse_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnost kreiranja jednog ili više skladišta unutar kompanije, sa više skladišnih nivoa."])},
  "products_warehouse_text_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definisanje skladišnih ćelija po tipovima artikala koji će se nalaziti na tim ćelijama (ćelija materijala, robe, rezervnih dijelova i slično)"])},
  "products_warehouse_text_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaprimanje artikala sa tranzita, skenerima ili ručno, na odgovarajuće skladišne ćelije, uz potpuno praćenje svake transakcije preko barkoda artikla."])},
  "products_warehouse_text_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatsko kreiranje nabavnih naloga za zaprimljene stavke (veza ulazne fakture i dopremnice). - Printanje labela i barkodova artikala."])},
  "products_warehouse_text_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premještanje artikala između skladišnih ćelija, uz prateće izvještaje. Uvid u stanja skladišta uz informacije kao što su: ident, opis, količina, cijena, dobavljač, šarža, ćelija skladišta i slično."])},
  "products_warehouse_text_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inventura. Uvid u historiju kretanja stavki skladišta uz transparentan prikaz svih potrebnih informacija."])},
  "products_dms_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokument Manadžment Sistem (DMS)"])},
  "products_dms_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupan i praktičan"])},
  "products_dms_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Dokument Manadžment Sistem (DMS) se koristi za upravljanje svim dokumentima u kompaniji. Omogućava pohranjivanje svih vrsta dokumenata istim principom kako pohranjujemo fizičku dokumentaciju. Ako fizičku dokumentaciju zaprimamo i razvrstavamo u registratorima, isto radimo u DMS-u pomoću elektronsih registratora."])},
  "products_dms_text_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ovo je samo jedan mali dio funkcionalnosti našeg DMS sistema koji je u potpunosti integrisan i sa finansijskim tokom dokumentacije koju pohranjujemo, tako da sve podatke koji su bitni (kupci, dobavljači, iznosi, brojevi dokumenta i slično) unosimo u sistem i dalje ih koristimo za knjiženje i izvještavanje."])},
  "products_row_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos ulaznih faktura i započinjanje postupka odobrenja kroz unaprijed definisani radni tok"])},
  "products_row_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Izdavanje izlaznih faktura i pripremanje potrebnih dokumenata"])},
  "products_row_three": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnost kloniranja ponavljajućih izlaznih faktura."])},
  "products_row_four": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnost povezivanja ulaznih faktura sa odobrenim narudžbama."])},
  "products_row_five": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatska provjera potrošnje budžeta"])},
  "products_row_six": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integracija sa POS terminalima"])},
  "products_row_seven": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Rezervacija robe (ulazne i izlazne skladišne transakcije)"])},
  "products_row_eight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Praćenje zaliha - detaljne informacije o protoku robe na osnovu stavki na fakturama"])},
  "products_row_nine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatsko računovodstvo na osnovu unaprijed definisanih profila rezervacija"])},
  "products_finance_heading_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transparentno i tačno"])},
  "products_finance_heading_two": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahtjev za budžet"])},
  "products_finance_text_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naš modul Finansija i računovodstva je napravljen na osnovu najboljih svjetskih praksi, gdje planirate i trošite shodno planu. Također, poredite planirano sa realizovanim. Forspace se brine da sve to bude tačno i transparentno."])},
  "products_finance_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje budžetom kompanije prema vrstama troška"])},
  "products_finance_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje budžetom kompanije prema mjestima troška i vrstama troška"])},
  "products_finance_workflow_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raditi sa protokom ili Raditi u skladu sa tokom procesa"])},
  "products_finance_workflow_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ulazni dokumenti - Koraci u toku servisiranja fakture"])},
  "products_finance_assets_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stalna sredstva"])},
  "products_finance_assets_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabava, upotreba i amortizacija stalnih sredstava"])},
  "products_paydesk_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blagajna"])},
  "products_paydesk_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijem i isplata"])},
  "products_paydesk_header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sve što treba da upravljate vašom blagajnom tačno, transparentno i u skladu sa odobrenjima i budžetom."])},
  "products_paydesk_01_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos i potvrda prijema gotovine po unaprijed definisanom radnom toku"])},
  "products_paydesk_02_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos i provjera isplate gotovine po unaprijed definisanom radnom toku"])},
  "products_paydesk_03_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijem gotovine i obračun isplate"])},
  "products_paydesk_04_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled dnevnika blagajne i generisanje dokumenta"])},
  "products_paydesk_05_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pregled stavki dnevnika blagajne i generisanje dokumenata"])},
  "products_paydesk_06_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatsko knjiženje dnevnih transakcija u glavnu knjigu na osnovu računa dodijeljenih blagajni"])},
  "products_bank_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankovni izvodi"])},
  "products_bank_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatska obrada i knjiženje"])},
  "products_bank_1_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import u različitim formatima"])},
  "products_bank_1_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import elektroniskih bankovnih izvoda u različitim formatima"])},
  "products_bank_1_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektronski format banke"])},
  "products_bank_1_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programi za automatsku konverziju za posebne elektronske formate banke"])},
  "products_bank_1_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Radni tok"])},
  "products_bank_1_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatsko knjiženje i obrada bankovnih izvoda"])},
  "products_bank_2_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opći unosi u dnevnik"])},
  "products_bank_2_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatski unosi u dnevnik iz bankovnog izvoda"])},
  "products_bank_2_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registar partnera"])},
  "products_bank_2_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostavan pristup svim podacima o partnerskim bankama i bankovnim računima"])},
  "products_bank_2_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matični podaci"])},
  "products_bank_2_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnost prepoznavanja tipova stavki bankovnog izvoda i povezanih računa"])},
  "products_tax_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRIJAVA PDV-a"])},
  "products_tax_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glavne knjige izdatih i zaprimljenih računa"])},
  "products_tax_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOKUMENT O POVRATU PDV-a"])},
  "products_tax_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatsko kreiranje dokumenta za povrat PDV-a."])},
  "products_tax_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KNJIŽENJE PDV"])},
  "products_tax_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatsko knjiženje PDV"])},
  "products_tax_heading_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTROLNI IZVJEŠTAJI"])},
  "products_tax_text_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knjige izdatih i zaprimljenih faktura"])},
  "products_hr_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ljudski resursi i obračun plaća"])},
  "products_hr_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koristeći Forspace HR modul i modul OBRAČUNA PLAĆA vaša kompanija ima mogućnost vođenja evidencije uposlenih po najsavremenijim standardima. Od prilagodljivih podataka u personalnom kartonu, historiji kretanja poslovne karijere i slično, do automatiziranog modula za kreiranje ugovora koji u mnogome olakšava rad vaših HR uposlenika. Za modul obračuna plaća imate brojne olakšice i automatizacije, također i regularne postavke koje zadovoljavaju standard zakonodavstva i GDPR-a."])},
  "hr_heading_1_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registar uposlenih"])},
  "hr_heading_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidencija članova porodica, nerezidenata/stranih lica, radnih dozvola, različitih certifikata"])},
  "hr_heading_1_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametri za obračun plata"])},
  "hr_heading_2_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadgledanje različitih izostanaka"])},
  "hr_heading_2_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidencija radnih sati"])},
  "hr_heading_2_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informacije o zdravlju zaposlenika"])},
  "hr_heading_3_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evidencija o zaduženjima, otplatama dugova, izdržavanju djece"])},
  "hr_heading_4_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibilno definisanje postavki obračuna - profila za obračun plata"])},
  "hr_heading_4_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različite vrste obračuna na osnovu vrste radnog odnosa"])},
  "hr_heading_4_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različite metode za vrste obračuna u zavisnosti od vrste ustanove"])},
  "hr_heading_5_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Različite metode za obračun osnovne plate"])},
  "hr_heading_5_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unos, uređivanje ili brisanje podataka za više zaposlenika istovremeno koristeći importere"])},
  "hr_heading_5_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnost ponovnog obračuna za pojedinog zaposlenika ili za sve zaposlenike"])},
  "hr_heading_6_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje kratkoročnim i dugoročnim kreditima i drugim odbicima"])},
  "hr_heading_6_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje dodatnim uplatama (bonusi, naknade za topli obrok i prevoz, povećanje radnog staža)"])},
  "hr_heading_6_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mogućnost ispisa uplatnica u PDF-u ili slanja e-mailom"])},
  "hr_heading_7_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatsko knjiženje u glavnu knjigu"])},
  "hr_heading_7_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola istovremene upotrebe i uređivanja podataka u dosijeima zaposlenika tokom pripreme obračuna plata"])},
  "hr_heading_7_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenti o elektronskom plaćanju"])},
  "services_slider_heading_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Razvijanje Web i desktop aplikacija"])},
  "services_slider_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web i desktop aplikacije"])},
  "services_slider_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Olakšavanje poslovnih procesa uz mobilne aplikacije"])},
  "services_slider_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilne aplikacije"])},
  "services_slider_heading_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poboljšavanje poslovnih rjesenja pomocu API integracije"])},
  "services_slider_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API integracija"])},
  "services_container_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programerske usluge i razvoj"])},
  "services_icon_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web i desktop aplikacije"])},
  "services_icon_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilne aplikacije"])},
  "services_icon_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Api integracija"])},
  "services_grid_div4_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistemi plaćanja"])},
  "services_grid_div4_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Provider aplikacije za platne sisteme u eCommerce sektoru i integracija u cjelokupan lanac procesa."])},
  "services_grid_div5_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rješenja po mjeri"])},
  "services_grid_div5_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stručnjaci smo u pretvaranju različitih poslovnih potreba/ideja naših klijenata u digitalne proizvode namijenjene unapređenju njihovih poslovnih procesa, razvoju i digitalnoj transformaciji."])},
  "contact_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošaljite nam poruku"])},
  "contact_form_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše ime"])},
  "contact_form_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš email"])},
  "contact_form_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poruka"])},
  "contact_form_nameInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo unesite Vaše ime"])},
  "contact_form_emailInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo unesite Vaš email"])},
  "contact_form_messageInvalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo unesite poruku"])},
  "contact_form_google_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karta"])},
  "email_sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email poslan"])},
  "company": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompanija"])},
  "footer_forspace_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE PROIZVODI I USLUGE"])},
  "footer_accounting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["računovodstvo"])},
  "footer_controlling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kontroling"])},
  "footer_human_resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ljudski resursi"])},
  "footer_all_in_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all-in-one erp rješenje"])},
  "footer_sales_menagment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["upravljenje prodajom"])},
  "footer_mobile_app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilne aplikacije"])},
  "footer_gdpr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zakon o zaštiti"])},
  "footer_services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FORSPACE Usluge"])},
  "footer_contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktirajte nas"])},
  "footer_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulevar Meše Selimovića 16"])},
  "footer_address2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bosna i Hercegovina"])},
  "footer_address_telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["+387 33/770-949"])},
  "footer_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022 Forspace - All Rights Reserved"])},
  "form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji poruku"])},
  "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabava"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prodaja"])},
  "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodnja"])},
  "warehouse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skladište"])},
  "dms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS"])},
  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finansije"])},
  "hr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])},
  "services-_icon_text_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linux, Unix, macOS, Windows"])},
  "services-_icon_text_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podržanih na Android i iOS operativnim sistemima"])},
  "services-_icon_text_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na serveru"])},
  "why_companies_need_dms_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Vođenje biznisa može biti stresno na mnogo načina a poseban izazov u ovom pogledu je i način na koji kompanija upravlja dokumentima. Srećom, danas postoje softverska rješenja koja vode brigu o dokumentaciji kompanije."])},
  "why_companies_need_dms_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šta je DMS sistem za upravljanje dokumentima?"])},
  "why_companies_need_dms_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem za upravljanje dokumentima (DMS) je softver koji omogućava kompanijama da kreiraju, skladište, modifikuju, upravljaju i preuzimaju digitalne dokumente potrebne za poslovanje. Najveći broj ovakvih softvera baziran je na cloud-u što znači da zaposlenici kompanije dokumente mogu imati dostupne, kreirati ih i mijenjati u bilo koje vrijeme i na bilo kojem mjestu. Sistem za upravljanje dokumentima olakšava protok kritičnih informacija kroz organizaciju jer se dokumenti mogu dijeliti digitalno sa svim zaposlenicima u kompaniji."])},
  "why_companies_need_dms_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zašto je kompaniji potreban DMS - sistem za upravljanje dokumentima?"])},
  "why_companies_need_dms_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistem za upravljanje dokumentima je nezamjenjiv alat za svaku vrstu djelatnosti u današnje vrijeme. Prva najveća prednost je, naravno, smanjena potrošnja papira. Budući da dokumente možete pohranjivati ​​i dijeliti digitalno u DMS-u, potrošnja papira biće značajno smanjenja a vaš doprinos zaštiti okoliša će se povećati. Postoje i druge prednosti korištenja DMS-a za vaše poslovanje a neke od njih donosimo u nastavku."])},
  "why_companies_need_dms_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sigurno skladištenje datoteka i podataka"])},
  "why_companies_need_dms_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Većina dokumenata koji se odnose na vaše poslovanje sadrže ključne informacije kojima s vremena na vrijeme trebate pristupiti. Fizički dokumenti su uvijek u opasnosti da budu izgubljeni tokom skladištenja ili transporta ili da budu oštećeni zbog nepredviđenih nezgoda poput požara ili poplava. S vremenom se i fizički dokumenti istroše i moraju se ponovo kreirati kako bi bili u dobrom stanju. Sa sistemom za upravljanje dokumentima, ovi problemi su riješeni zauvijek. Budući da nema fizičkih dokumenata o kojima treba voditi računa, ne morate brinuti o gubitku ili šteti. Dakle, vaši podaci se ne mogu izgubiti osim ako ih ne želite izbrisati. Također, uz Forspace DMS možete odlučiti ko treba da ima pristup kojem dokumentu tako da će svi osjetljivi podaci biti sigurni, omogućavajući pristup samo onim ljudima s kojima ih odlučite podijeliti."])},
  "why_companies_need_dms_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizička dokumentacija postaje prošlost"])},
  "why_companies_need_dms_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za neke poslove, kao što su računovodstvo i pravni problemi, ovakav softver pravi je spas. Umjesto da morate pregledavati hrpu papira za svaku sitnicu, možete jednostavno digitalizovati svoju dokumentaciju i vjerovati vodećoj OCR-pretrazi koja će vam pomoći da pronađete sve svoje datoteke čak i ako su u formatima samo za čitanje poput slika, skeniranja ili PDF-ove."])},
  "why_companies_need_dms_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podaci su lahko djeljivi"])},
  "why_companies_need_dms_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikacija između zaposlenika treba da bude jednostavna kao da sjede jedan pored drugog. Forspace DMS sistem omogućit će vam brzo, jednostavno i lahko dijeljenje svih informacija i dokumenata sa kolegama tako da nećete morati trošiti vrijeme na pisanje e-maila ili telefonski poziv."])},
  "why_companies_need_dms_h6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povećana produktivnost na radnom mjestu"])},
  "why_companies_need_dms_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kada preduzeće fizički skladišti svoje dokumente, gubi se mnogo vremena i radne snage u tom procesu. Čuvanje i dijeljenje dokumenata postaje glomazan proces koji zahtijeva mnogo nepotrebnog truda. Ovo zauzvrat smanjuje ukupnu produktivnost zaposlenika. Fizičko traženje dokumenta a zatim njegovo dostavljanje do radnog stola zaposlenika koji je u potrebi za njim je uzaludna potrošnja vremena i resursa. Sistem za upravljanje dokumentima pojednostavljuje pronalaženje dokumenata, jer omogućava zaposlenima da pristupe onome što im je potrebno sa bilo kojeg mjesta putem jednostavne pretrage. Koristeći DMS, možete lahko podijeliti datoteku u roku od nekoliko sekundi sa svima koji su vam potrebni. Vaši zaposlenici su oslobođeni vremena utrošenog na fizičku pretragu datoteka i to vrijeme se umjesto toga može iskoristiti pametnije, povećavajući njihovu produktivnost."])},
  "why_companies_need_dms_h7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značajne uštede"])},
  "why_companies_need_dms_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korištenjem sistema za upravljanje dokumentima štedite i mnogo novca. U početku može izgledati kao investicija jer biste trebali kupiti softver, ali povrat ulaganja je također značajan. Prvo, možete mnogo uštediti na papiru i srodnim proizvodima. Osim toga, kako zaposleni postaju produktivniji eliminacijom tradicionalnog sistema za upravljanje datotekama, to rezultira poboljšanom efikasnošću i većom zaradom."])},
  "why_companies_need_dms_h8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz Forspace ERP dolazi integrisan i sistem za upravljanje dokumentima DMS, čime je omogućeno pohranjivanje, arhiviranje i procesiranje svih vrsta dokumenta.  Forspace DMS omogućava povezivanje između korisnika, potpuno usklađene podatke i pregled nad cjelokupnim poslovanjem."])},
  "why_companies_need_dms_h8_additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za besplatnu prezentaciju Forspace DMS-a kontaktirajte nas putem telefona 033/774-873 ili e-maila"])},
  "why_web_based_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Cloud bazirane aplikacije značajno su napredovale u posljednjih nekoliko godina i koriste se sve više jer omogućavaju kompanijama u različitim industrijama da kreiraju, uređuju, pristupaju i dijele ključne poslovne podatke. Kako kompanije napreduju, poslovanje u oblaku postaje dominantan igrač za poslovne podatke u smislu veće sigurnosti, brže implementacije i nadogradnje te smanjenja ukupnih troškova. Sve više i više kompanija svoj ERP sistem prebacuju u cloud, a u nastavku saznajte zašto je to tako."])},
  "why_web_based_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niži troškovi postavljanja i implementacije"])},
  "why_web_based_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistemi bazirani na cloud-u imaju niske troškove implementacije za razliku od tradicionalnog, hostovanog modela. Lokalna implementacija obično zahtijeva značajna ulaganja u hardver i stručno osoblje. Uz softver baziran na cloud-u, hosting van lokacije i stručnost provajdera eliminišu potrebu za dodatnim hardverom ili osobljem. Smanjenje ukupnog troška je prvi i ključni razlog zašto kompanije prelaze na cloud baziran ERP."])},
  "why_web_based_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fleksibilno skladištenje podataka"])},
  "why_web_based_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatni hardver je potrebno kupiti kada su serveri na licu mjesta popunjeni. Uz cloud baziran ERP, kompanije mogu brzo povećati ili smanjiti prostor za skladištenje po potrebi."])},
  "why_web_based_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostavnije sigurnosno kopiranje i vraćanje podataka"])},
  "why_web_based_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalni ERP podrazumijeva složen proces sigurnosnog kopiranja podataka i obično zahtijeva plan održavanja u SQL serveru, plus druge proizvode/usluge. Uz ERP baziran na cloud-u, redovna sigurnosna kopija sistema se pravi svakodnevno i dostupna je korisnicima, uz mogućnost kreiranja sigurnosnih kopija korisnika."])},
  "why_web_based_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povećana sigurnost i skalabilnost"])},
  "why_web_based_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalna okruženja su obično manje bezbijedna i zahtjevaju skupo dodatno praćenje i namjenskog IT administratora. Data centri u vlasništvu Forspace-a su sigurni, certifikovani i usklađeni s međunarodnim standardima. Skalabilnost je najveći problem sa lokalnim ERP sistemima jer su povezani sa postojećim hardverskim mogućnostima preduzeća. ERP sistemi bazirani na cloud-u nude opcije skalabilnosti, kao što je dodavanje novih licenci i više korisnika kada kompanija zapošljava nove zaposlenike. Osim toga, oni pružaju dodatni prostor za skladištenje i pružaju mogućnosti za smještaj novih proizvodnih procesa i postrojenja."])},
  "why_web_based_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajednička platforma podataka"])},
  "why_web_based_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistemi u cloud-u pružaju zajedničku platformu na kojoj različiti odjeli, uključujući proizvodnju, prodaju, marketing, finansije i računovodstvo, upravljanje odnosima s kupcima i odjele za odnose s dobavljačima, mogu dijeliti ključne podatke bilo gdje, bilo kada i na bilo kojem uređaju. Osim toga, lokalna rješenja je obično teže integrisati s drugim aplikacijama nego rješenja bazirana na cloud-u."])},
  "what_is_api_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izraz “API” je često korišten u web razvoju. Ažuriranja operativnog sistema, web pretraživača i aplikacija često najavljuju nove API-je za programere. Stoga, šta je API i kako ga programeri koriste ?"])},
  "what_is_api_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poboljšanu saradnju"])},
  "what_is_api_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API je akronim za Application Programming Interface - softverski posrednik koji omogućava dvjema aplikacijama da razgovaraju jedna s drugom. Svaki put kada koristite aplikaciju kao što je Facebook, pošaljete poruku ili provjerite vremensku prognozu na svom telefonu, koristite API. Cilj API specifikacija je standardizacija razmjene podataka između web servisa. U ovom slučaju, standardizacija znači sposobnost različitih sistema, napisanih na različitim programskim jezicima i koji rade na različitim operativnim sistemima, ili koristeći različite tehnologije, da neprimjetno međusobno komuniciraju."])},
  "what_is_api_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lakše inovacije"])},
  "what_is_api_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-ji se nalaze između aplikacije i web servera, djelujući kao posrednički sloj koji obrađuje prijenos podataka između sistema."])},
  "what_is_api_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monetizaciju podataka"])},
  "what_is_api_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcionisanje API-ja može se objasniti kroz nekoliko koraka:"])},
  "what_is_api_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatnu sigurnost"])},
  "what_is_api_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacija klijenta pokreće API poziv za dohvaćanje informacija i ova operacija poznata je kao zahtjev. Ovaj zahtjev se obrađuje od aplikacije do web servera preko API-jevog Uniform Resource Identifier-a (URI) i uključuje predmet zahtjeva, zaglavlje, a ponekad i objašnjenje zahtjeva."])},
  "what_is_api_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zajednička platforma podataka"])},
  "what_is_api_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakon što primi valjan zahtjev, API upućuje poziv eksternom programu ili web serveru."])},
  "what_is_api_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server šalje odgovor API-ju sa traženim informacijama."])},
  "what_is_api_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API prenosi podatke u početnu aplikaciju koja je tražila"])},
  "what_is_api_p9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neke od glavnih prednosti API-ja uključuju:"])},
  "what_is_api_p10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-ji omogućavaju integraciju dviju platformi tako da one mogu neprimjetno komunicirati jedna s drugom. Kroz ovu integraciju, kompanije mogu automatizirati tokove posla i poboljšati saradnju na radnom mjestu. Bez API-ja, mnogim preduzećima bi nedostajala povezanost i patila bi od informacijskih silosa koji ugrožavaju produktivnost i performanse."])},
  "what_is_api_p11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["API-ji nude fleksibilnost, omogućavajući kompanijama da se povežu s novim poslovnim partnerima, ponude nove usluge na postojećem tržištu i, na kraju, pristupe novim tržištima koja mogu generisati ogromne povrate i pokrenuti digitalnu transformaciju."])},
  "what_is_api_p12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnoge kompanije odlučuju ponuditi API-je besplatno, barem u početku, tako da mogu izgraditi publiku programera oko svog brenda i uspostaviti odnose s potencijalnim poslovnim partnerima. Međutim, ako API odobri pristup vrijednim digitalnim sredstvima, moguće ga je unovčiti prodajom pristupa (ovo se naziva API ekonomija)."])},
  "what_is_api_p13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kao što je ranije navedeno, API-ji stvaraju dodatni sloj zaštite između vaših podataka i servera. Programeri mogu dodatno ojačati sigurnost API-ja korištenjem tokena, potpisa i enkripcije Transport Layer Security (TLS) implementacijom API gateway-a za upravljanje i provjeru autentičnosti prometa i praktikovanjem efikasnog upravljanja API-jem."])},
  "digita_trans_adv_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koncept digitalne transformacije podrazumijeva korištenje digitalnih tehnologija na strateški način koji pojednostavljuje i ubrzava vaše poslovanje, od vašeg poslovnog modela do vaših internih procesa. Digitalizacijom vaše kompanije, vaš cilj je da steknete prednosti digitalne transformacije, što može uključivati povećanje vašeg prihoda, poboljšanje korisničkog iskustva i sticanje konkurentske prednosti."])},
  "digita_trans_adv_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poboljšana efikasnost"])},
  "digita_trans_adv_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedna od najvećih prednosti implementacije ERP sistema u vaše poslovanje je dramatično poboljšanje efikasnosti. Način na koji vaši zaposlenici komuniciraju od odjela do odjela, kontinuirani protok podataka u cijeloj organizaciji, neometani prijelaz iz faze u fazu tokom životnog vijeka korisnika – sve ove prednosti doprinose efikasnijem poslovnom procesu koji može uštediti vrijeme, novac i sredstva."])},
  "digita_trans_adv_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uštede troškova"])},
  "digita_trans_adv_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uz pravi ERP softver, postoji mnogo mogućnosti za uštedu troškova, posebno u operativnim procesima. Na primjer, proizvodna kompanija ima bolji uvid u potrebne količine i vremenske rokove sirovina. Naručivanjem sirovina na strateški način zasnovan na podacima, uštede postaju neizbježne."])},
  "digita_trans_adv_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rast dobiti"])},
  "digita_trans_adv_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimiziranjem troškova, lakše je povećati dobit. Maksimiziranje mogućnosti kroz prepoznavanje nedostataka u trenutnim poslovnim procesima ključno je za napredak kompanije. Poboljšanjem transparentnosti u različitim aspektima poslovanja, zaposleni i menadžment mogu poboljšati svoje najbolje prakse i povećati prihod."])},
  "digita_trans_adv_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poboljšanje konkurentske prednosti"])},
  "digita_trans_adv_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poboljšanjem infrastrukture vašeg poslovanja, poboljšavate prednost koju imate na tržištu. Najbolji način da to učinite je da unaprijed planirate karakteristike i funkcionalnosti koje će vaš novi sistem trebati da učini vašu kompaniju jačom. Uključite se u ono u čemu ste dobri i pronađite dijelove poslovanja koje možete poboljšati i radite na tome. Karakteristike i funkcionalnosti softvera koji odaberete trebale bi odražavati potrebe i poboljšati vaše faktore diferencijacije."])},
  "digita_trans_adv_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povećana agilnost"])},
  "digita_trans_adv_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sa povećanjem automatizacije i poboljšanom produktivnošću dolazi i do veće agilnosti. Samo u razvoju digitalne strategije kompanije mogu zaista postati agilne unutar projekata i operativnih područja. Rješavanje upotrebe digitalnih tehnologija koje obavljaju težak zadatak u vašim procesima osigurava da timovi u cijeloj vašoj organizaciji postanu agilni. Ovo ubrzava rezultate, informacije, izvještavanje i donošenje odluka."])},
  "digita_trans_adv_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Server šalje odgovor API-ju sa traženim informacijama."])},
  "entrepeneur_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proces osnivanja preduzeća poznat je kao preduzetništvo. Preduzetnik se obično smatra inovatorom, izvorom novih ideja, roba, usluga i/ili poslovnih procedura."])},
  "entrepeneur_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inovatori"])},
  "entrepeneur_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inovatori su tipovi preduzetnika koji dolaze sa potpuno novim idejama i pretvaraju ih u održiva preduzeća. U većini slučajeva, ovi preduzetnici mijenjaju način na koji ljudi razmišljaju i rade stvari drugačije. Takvi preduzetnici imaju tendenciju da budu izuzetno strastveni i opsesivni, jer svoju motivaciju crpe iz jedinstvene prirode svoje poslovne ideje. Inovativni preduzetnici također pronalaze nove načine plasiranja svojih proizvoda birajući strategije diferencijacije proizvoda koje čine da se njihova kompanija ističe iz gomile. Ponekad to nije samo izdvajanje iz gomile, već, zapravo, stvaranje nove gomile."])},
  "entrepeneur_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lovci (tzv. “Hustlers”)"])},
  "entrepeneur_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Za razliku od inovatora čija je vizija gas u njihovom motoru, lovci više rade i spremni su uprljati ruke. Ovakvi tipovi preduzetnika se na početku fokusiraju na male ciljeve sa željom da u budućnosti postanu veći. Hustleri su motivisani svojim snovima i radit će izuzetno naporno kako bi ih ostvarili. Međutim, ostvarivanje njihovih snova traje mnogo duže od većine drugih vrsta preduzetnika."])},
  "entrepeneur_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imitatori"])},
  "entrepeneur_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imitatori su tipovi preduzetnika koji kopiraju određene poslovne ideje i unapređuju ih. Oni uvijek traže načine da poboljšaju određeni proizvod kako bi ostvarili prednost na tržištu. Imitatori su dijelom inovatori, a dijelom lovci koji se ne pridržavaju uslova koje postavljaju drugi ljudi i imaju puno samopouzdanja."])},
  "entrepeneur_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Istraživači"])},
  "entrepeneur_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čak i nakon što imaju ideju, istraživači će odvojiti vrijeme da prikupe sve relevantne informacije o njoj. Za njih neuspjeh nije opcija jer ideju analiziraju iz svih uglova. Ova vrsta preduzetnika obično vjeruje u pokretanje posla koji ima velike šanse za uspjeh jer oni ulažu puno vremena i resursa kako bi razumjeli sve aspekte poslovne ideje. Kao rezultat toga, ovakvim tipovima preduzetnika obično je potrebno mnogo vremena da lansiraju proizvode kako bi donijeli odluke jer im je potreban temelj dubokog razumijevanja. Ovi preduzetnici se mnogo više oslanjaju na podatke i činjenice nego na instinkte i intuiciju."])},
  "entrepeneur_h5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupci"])},
  "entrepeneur_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osnovna karakteristika ove vrste preduzetnika je njihovo bogatstvo. Ovakvi tipovi preduzetnika imaju novca i specijalizirani su za kupovinu perspektivnih poslova. Kupci preduzetnici će identificirati posao i procijeniti njegovu održivost, nastaviti sa sticanjem i pronaći najprikladniju osobu koja će ga voditi i razvijati."])},
  "HowErp_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP sistemi namijenjeni su kompanijama svih veličina, bilo da se radi o malim, srednjim ili velikim, koje žele integrisati svoje poslovne procese i podatke na jedno mjesto. Nijedna kompanija ne može biti suviše mala za stavljanje poslovanja pod potpunu kontrolu i korištenje pametnih poslovnih rješenja kako bi pojednostavila svoje poslovne procese. Mala preduzeća koriste ERP softver za poboljšanje komunikacije između odjela i optimizaciju poslovnih procesa uz potpunu transparentnost svih operacija."])},
  "HowErp_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijeljenje informacija"])},
  "HowErp_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Integrisanjem poslovnih procesa u jednu platformu bit će jednostavnije dijeljenje podataka i informacija između odjela."])},
  "HowErp_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povećanje efikasnosti"])},
  "HowErp_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povećanje efikasnosti bez zapošljavanja novog osoblja ili proširenja operacija automatizacijom procesa i eliminacijom suvišnog posla. Realna slika malih kompanija jeste da jedan zaposlenih uvijek radi više poslova. Svi se upuštaju gdje god i kad god se za to ukaže potreba. ERP sistemi usmjereni prema malim kompanijama automatiziraju ključne poslovne funkcije i olakšavaju rad vaših zaposlenika kako bi se mogli fokusirati na važnije poslovne zadatke."])},
  "HowErp_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poslovna inteligencija"])},
  "HowErp_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korištenjem ERP sistema uvijek ćete imati lahko dostupne i tačne podatke. Ovakvi sistemi omogućavaju vam napredno izvještavanje i praćenje napretka, produktivnosti i učinaka. Time ćete uvijek imati potpun uvid u vaše poslovanje za bolje donošenje odluka. Bez sveobuhvatnog sistema koji prikuplja podatke, izvještava o vašim procesima i sistemima i sagledava širu sliku, kako možete tačno znati gdje se nalazite?"])},
  "HowErp_h4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potpuna transparentnost i kontrola poslovanja"])},
  "HowErp_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da biste vodili profitabilan posao, morate imati potpunu kontrolu nad vašim poslovnim resursima tj. onim što vaše poslovanje ima u smislu gotovine, sirovina, gotovih proizvoda, roba, osoblja, proizvodnih kapaciteta. Vaši poslovni resursi su ono što koristite za održavanje vašeg poslovanja i nad njima trebate imati potpunu kontrolu u svakom momentu. Jedna od najvećih prednosti ERP sistema je njegova sposobnost da pruži potpunu vidljivost vašeg cjelokupnog procesa, dajući vam uvid u to kako vaš proces funkcioniše. Sa ERP-om, ono što je nekada zahtijevalo sate pažljivog prikupljanja podataka i kreiranja izveštaja može se obaviti automatski, pružajući vam sveobuhvatan pregled vašeg poslovanja, omogućavajući vam da mjerite kritične metrike performansi kroz kontrolne table u realnom vremenu. Ove informacije možete koristiti da uvijek znate gdje se nalazite, da optimizirate proizvodne rasporede i da maksimizirate kapacitet i povećate svoju efikasnost."])},
  "software_solutions_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Većina kompanija implementacijom ERP softvera nastoji poboljšati ukupne performanse. U mnogim slučajevima, izvorni pokretač koji dovodi do kupovine ERP softvera, odnosno, zamjene starog za novi, jeste cilj pojednostavljenja poslovnih procesa radi održive konkurentske prednosti. Nerijetko, implementacije ERP softvera bivaju neuspješne. Umjesto da donesu smanjenje troškova, poslovnu agilnost i poboljšanje performansi, loši ERP sistemi stvaraju složenost, udvostručavanje napora, a u najgorem slučaju loš kvalitet i korisničku uslugu, te opasan nedostatak uvida u poslovanje koji može kompaniju odvesti u propast."])},
  "software_solutions_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dešava se da implementirani ERP sistemi ne idu u korak s promjenama. Proizvodni sektor suočava se s konstantno promjenjivim poslovnim procesima, podacima i zahtjevima koji čine gotovo nemogućim tipičan, nefleksibilan ERP sistem da bude u korak sa onim što je poslovanju zaista potrebno. U nastavku donosimo nekoliko znakova upozorenja koji vam govore da je poslovanje vaše kompanije ugroženo jer ERP softver ne funkcioniše kako bi zaista trebao ili jer mu nedostaju pravovremene nadogradnje."])},
  "software_solutions_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš ERP sistem ne integriše poslovne podatke važne za ostvarenje definisane misije. Vaši podaci su „zaključani“ u vašem zastarjelom ERP sistemu i teško im je pristupiti. Zbog toga, ne možete analizirati podatke potrebne za donošenje odluka. Informacije i podaci u sistemu „ne cirkulišu“ kroz odjele, već su u njima zarobljeni i postoje neovisno jedni o drugima. To povećava složenost i udvostručava napore s različitim verzijama istine, što ugrožava kvalitet, pouzdanost i dostupnost vitalnih informacija. Bez transparentnosti cijelog vašeg poslovnog softvera i besprijekorne strukture koja povezuje sve funkcije izvještavanja i odjele vašeg softvera, ne možete prikupiti sve ključne informacije koje su vašem poslu potrebne za uspjeh."])},
  "software_solutions_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svaka promjena u sistemu je skupa i predstavlja dugotrajan proces. Dobavljač softvera ne pruža vam nadogradnje softvera koje su vam neophodne. Također, održavanja softvera su isuviše skupa za takav zastario softver koji ne donosi nikakve novitete, a što bi vas moglo koštati gubitka konkurentske prednosti. Zaglavljeni ste između nefunkcionalnog softvera koji ne donosi poboljšanja i vrtoglavih troškova."])},
  "software_solutions_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadogradnje ERP softvera ometaju poslovanje. Već smo primijetili da nadogradnje prodavača softvera obično izlaze u prosjeku svakih 12-24 mjeseca. Često zahtijevaju ažuriranje operativnog sistema, sistema za upravljanje bazama podataka, prostora na disku, hardvera itd,.. Nadogradnjama je potrebno vrijeme za planiranje i izvršavanje. U nevolji ste ako neko vrijeme morate zanemariti svoje poslovanje da bi se vaš softver nadogradio. Uz to, nadogradnje softvera vrlo često ne donose ono što je vašem poslovanju zaista potrebno. Ukoliko, i nakon nadogradnje softvera, softver ne nudi one postavke koje su vama i vašem poslovanju neophodne za rast, vrijeme je za promjene."])},
  "software_solutions_p6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne možete pristupiti svom poslovnom softveru dok putujete na poslovni sastanak? To znači da vaš poslovni softver ne omogućava pristup podacima u pokretu. Isuviše je očigledno da posao ne staje dok putujete. Pametni telefoni pomažu vam da ostanete u kontaktu, ali ako vaš ERP softver nema pristup putem mobilne aplikacije, nemate sreće. Bežična povezivanja su posvuda, ali ipak ste ograničeni jer ne možete ostati u kontaktu s poslovnim aktivnostima. Vaši klijenti nisu svi na jednom mjestu, a to znači da ni vi niste uvijek na jednom mjestu. Imperativ je da možete pristupiti podacima pritiskom na dugme tokom putovanja. Pametni telefoni polovica su rješenja, ali ako vaš ERP dobavljač ne nudi povezanu aplikaciju koja vam omogućuje da vidite sve svoje podatke u stvarnom vremenu, nećete dobiti potpunu sliku o svom poslu kada vam zatreba."])},
  "software_solutions_p7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novim zaposlenicima je potrebno previše vremena da se nauče koristiti sistem jer je isuviše komplikovan. Mnoge starije ERP sisteme je teško naučiti, a radnicima se lahko digne pritisak kada im se kaže da „pritisnu F1 da se raspitaju“ ili „pritisnu Enter da prihvate“. Ako ste često u potrazi za zaposlenima a vaše poslovanje trpi zbog spore adaptacije, gubite novac dok novi ljudi uče prečice za pokretanje i zatvaranje sistema."])},
  "software_solutions_p8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forspace ERP softver sveobuhvatno je poslovno rješenje koje je svoju primjenu našlo u različitim industrijama i poslovnim djelatnostima. Odatle i potreba da se Forspace stalno ažurira, nadograđuje i unaprijeđuje, kako bi svojim korisnicima omogućio zadovoljstvo korištenja i kvalitetnu pomoć pri rješavanju poslovnih zadataka. Kako bi osigurali nesmetano i uspješno obavljanje poslovnih aktivnosti, birajte Forspace. U svakoj smo fazi sa svojim klijentima, i naš posao ne prestaje sve dok koristite naše usluge. Poboljšanje Vašeg poslovanja je ono čemu svakodnevno težimo, jer: Mi ne radimo za Vas. Mi radimo sa Vama!"])},
  "digital_trans_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Većina kompanija implementacijom ERP softvera nastoji poboljšati ukupne performanse. U mnogim slučajevima, izvorni pokretač koji dovodi do kupovine ERP softvera, odnosno, zamjene starog za novi, jeste cilj pojednostavljenja poslovnih procesa radi održive konkurentske prednosti. Nerijetko, implementacije ERP softvera bivaju neuspješne. Umjesto da donesu smanjenje troškova, poslovnu agilnost i poboljšanje performansi, loši ERP sistemi stvaraju složenost, udvostručavanje napora, a u najgorem slučaju loš kvalitet i korisničku uslugu, te opasan nedostatak uvida u poslovanje koji može kompaniju odvesti u propast."])},
  "digital_trans_h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehnologija će podržati, a ne zamijeniti, računovođe"])},
  "digital_trans_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dešava se da implementirani ERP sistemi ne idu u korak s promjenama. Proizvodni sektor suočava se s konstantno promjenjivim poslovnim procesima, podacima i zahtjevima koji čine gotovo nemogućim tipičan, nefleksibilan ERP sistem da bude u korak sa onim što je poslovanju zaista potrebno. U nastavku donosimo nekoliko znakova upozorenja koji vam govore da je poslovanje vaše kompanije ugroženo jer ERP softver ne funkcioniše kako bi zaista trebao ili jer mu nedostaju pravovremene nadogradnje."])},
  "digital_trans_h2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prednosti softvera za planiranje resursa kompanije (ERP), poslovanja u cloudu i automatizacije"])},
  "digital_trans_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš ERP sistem ne integriše poslovne podatke važne za ostvarenje definisane misije. Vaši podaci su „zaključani“ u vašem zastarjelom ERP sistemu i teško im je pristupiti. Zbog toga, ne možete analizirati podatke potrebne za donošenje odluka. Informacije i podaci u sistemu „ne cirkulišu“ kroz odjele, već su u njima zarobljeni i postoje neovisno jedni o drugima. To povećava složenost i udvostručava napore s različitim verzijama istine, što ugrožava kvalitet, pouzdanost i dostupnost vitalnih informacija. Bez transparentnosti cijelog vašeg poslovnog softvera i besprijekorne strukture koja povezuje sve funkcije izvještavanja i odjele vašeg softvera, ne možete prikupiti sve ključne informacije koje su vašem poslu potrebne za uspjeh."])},
  "digital_trans_h3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buduće računovođe: Relevantniji, produktivniji i kreativniji više nego ikad"])},
  "digital_trans_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Svaka promjena u sistemu je skupa i predstavlja dugotrajan proces. Dobavljač softvera ne pruža vam nadogradnje softvera koje su vam neophodne. Također, održavanja softvera su isuviše skupa za takav zastario softver koji ne donosi nikakve novitete, a što bi vas moglo koštati gubitka konkurentske prednosti. Zaglavljeni ste između nefunkcionalnog softvera koji ne donosi poboljšanja i vrtoglavih troškova."])},
  "read_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saznaj više"])},
  "appointment_schedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dogovorite sastanak"])},
  "appointment_heading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukoliko želite prezentaciju softvera ostavite podatke i kontaktirat ćemo vas."])},
  "appointment_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osjećajte se slobodnim da rezervirate termin za sastanak sa našim timom za više informacija o našim proizvodima ili o procesima u vašoj kompaniji za koje je potrebno softversko rješenje i savjetovanje. Možda imamo upravo savršeno rješenje za vas na osnovu naše najbolje prakse i iskustva."])},
  "termin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idi na formu"])},
  "services_grid_div1_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP Sistemi"])},
  "services_grid_div1_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ERP (Enterprise Resource Planning) sistem je poslovni program koji omogućava kompanijama da planiraju svoje resurse i upravljaju cjelokupnim poslovanjem i pokriva sve procesne tokove uslužne, prodajne ili proizvodne djelatnosti."])},
  "services_grid_div2_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS Sistemi"])},
  "services_grid_div2_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DMS (Document Management System) je poslovni program za praćenje, upravljanje i digitalno arhiviranje dokumenata. Vodi evidenciju o različitim vrstama dokumenata kreiranih i arhiviranih od strane različitih korisnika (praćenje historije)."])},
  "services_grid_div3_header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS Sistemi"])},
  "services_grid_div3_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WMS (Warehouse Management System) je poslovni program za upravljanje skladištem koji ima za cilj da automatizuje, pojednostavi i racionalizuje radne zadatke i aktivnosti unutar skladišta, čime se povećava produktivnost i smanjuje mogućnost greške na minimum."])},
  "header_secondary_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvi domaći brend na tržištu ERP softvera koji omogućava smanjenje korištenja pisane dokumentacije, uštedu vremena i novca, te predstavlja potpuno prilagođeno i razumljivo softversko rješenje za krajnjeg korisnika"])},
  "header_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odluke zasnovane na činjenicama, a ne na pretpostavkama!"])},
  "slogan_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi ne radimo za vas!"])},
  "slogan_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi radimo sa vama!"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošalji"])},
  "get_connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povežite se sa nama na društvenim mrežama!"])},
  "find_us_on_map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronađite nas na karti"])},
  "company_url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL kompanije"])},
  "products_tax_header_main_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDV"])},
  "products_tax_header_main_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatska obrada i knjiženje"])},
  "products_fixed_asset_header_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahtjev za nabavku stalnog sredstva"])},
  "products_fixed_asset_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registar stalnih sredstava"])},
  "products_fixed_asset_header_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lokalizacija i identifikovanje stalnog sredstva"])},
  "products_fixed_asset_header_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Računjanje amortizacije"])},
  "products_fixed_asset_header_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostavno inventurisanje stalnih sredstava"])},
  "products_fixed_asset_header_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Knjiženje stalnih sredstava"])},
  "products_purchase_header_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednostavna narudžba za svakodnevno poslovanje"])},
  "products_hr_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efikasno vođenje evidencije uposlenih i automatizacija procesa"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nazad"])},
  "fill_the_form": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molimo da popunite formu ispravno"])},
  "fintech": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fintech"])},
  "fintech_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi isporučujemo vrhunska rješenja u fintech sektoru, zadovoljavajući jedinstvene potrebe i zahtjeve finansijskih institucija, fintech startupa i preduzeća koja posluju u industriji finansijskih usluga."])},
  "fintech_heading_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kombiniramo financije i tehnologiju"])},
  "digital_payments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digitalna plaćanja"])},
  "payment_processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Obrada plaćanja, platforme za prijenos novca, mobilni novčanici, beskontaktne tehnologije."])},
  "banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankarstvo"])},
  "banking_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rješenja za usluge digitalnog bankarstva."])},
  "finance_and_wealth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravljanje financijama i novčanim sredstvima"])},
  "finance_and_wealth_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alati i platforme za učinkovito upravljanje finansijama."])},
  "bi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BI"])},
  "bi_p": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Širok raspon finansijskih izvještaja."])},
  "fintech_bottom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Fintech revolucionizira financijski krajolik iskorištavanjem tehnologije za isporuku inovativnih i pristupačnih financijskih rješenja."])},
  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrška"])},
  "web_development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web aplikacije"])}
}