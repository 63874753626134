<template>
  <div class="widget-container mt-5">
    <div
      class="widget-box"
      data-aos="fade-down-right"
      data-aos-easing="ease-in"
      data-aos-duration="500"
    >
      <div class="widget-frame"></div>
      <div class="widget-pic"></div>
    </div>
    <div
      class="text"
      data-aos="fade-down-left"
      data-aos-easing="ease-in"
      data-aos-duration="500"
    >
      <small class="">{{ $t("widget_heading") }}</small>
      <h2 class="mb-5 text-secondary underline">{{ $t("widget_company") }}</h2>
      <Line />
      <p class="mb-5 text-secondary">
        {{ $t("widget_text") }}
      </p>

      <small>{{ $t("widget_small_text") }}</small>
      <br />
      <br />
      <div class="contact">
        <p>
          <font-awesome-icon icon="fa-solid fa-envelope" />
          info@forspace.ba
        </p>
        <p>
          <font-awesome-icon icon="fa-solid fa-phone" />
          +387 33 770 987
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Widget",
  data() {
    return {
      widgets: [
        { small: "30", p: "widget_icon_one_p" },
        { small: "10", p: "widget_icon_two_p" },
        { small: "130", p: "widget_icon_three_p" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.widget-container {
  width: 73.5%;
  margin: auto;
  margin-bottom: 5%;
  height: 55vh;
  display: flex;
  justify-content: center;
  gap: 40px;

  .widget-box {
    width: 100%;
    height: 100%;
    position: relative;

    .widget-frame {
      position: absolute;
      width: 52%;
      height: 100%;
      border: 23px solid rgba(8, 40, 67, 0.87);
      // border: 23px solid rgb(255, 97, 0);
      top: 0;
    }

    .widget-pic {
      position: absolute;
      top: 15%;
      left: 22%;
      width: 78%;
      height: 70%;
      background: url("@/assets/images/forspace_info.jpg") no-repeat center
        center/cover;
    }
  }

  .text {
    width: 100%;
    padding: 30px 0px 30px 60px;
    text-align: right;

    @media screen and (max-width: 900px) {
      width: 95%;
      margin: auto;
      padding: 30px 0px;
      text-align: left;
    }
svg{
  margin-right:7px;
}
    .d-flex {
      svg {
        display: block;
        margin: auto;
        width: 30px;
        height: 30px;
        color: rgba(8, 40, 67, 0.87);
      }

      small {
        font-size: 30px;
      }

      p {
        color: gray;
      }
    }

    small {
      font-size: 20px;
      color: rgb(55, 165, 255);
      margin-bottom: 4%;
    }

    h2 {
      color: rgb(37, 37, 37);
    }

    p {
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 1400px) {
  .widget-container {
    width: 100%;
  }
}

@media screen and (max-width: 900px) {
  .widget-container {
    width: 100%;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0%;
    padding: 7px;

    .widget-box {
      height: 50vh;

      .widget-frame {
        width: 50%;
        height: 50vh;
        left: 5%;
      }

      .widget-pic {
        width: 80%;
        top: 20%;
        left: 10%;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .iso {
    flex-direction: column;

    img {
      width: 80%;
      margin: auto;
    }

    a {
      text-align: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .widget-flex {
    display: flex;
    flex-direction: column;
  }

  .icons-container {
    width: 100% !important;
  }

  .widget-pic {
    position: relative !important;
    left: 35px !important;
    width: 89% !important;
  }

  .widget-frame {
    position: absolute;
    width: 57% !important;
    height: 100%;
    border: 15px solid rgba(8, 40, 67, 0.87) !important;
    // border: 23px solid rgb(255, 97, 0);
    top: 0;
    left: 8px !important;
  }
}
</style>
