<template>
  <div class="sidenav">
    <div
      class="sidenav-content"
      :class="{ sidenav_content_visible: getSideNav }"
    >
      <div>
        <div class="top-bar">
          <img alt="Forspace Solutions" src="@/assets/svg/logo.svg" />
          <div class="sidenav_times">
            <ClosingButton @click="showSideNav" />
          </div>
        </div>
        <ul class="nav-links-vertical">
          <Links
            :routes="routes"
            :sidenav_class="true"
            :showSideNav="showSideNav"
          />
          <div
            v-if="isProductsOpened"
            class="mobile-sub-nav"
            data-aos="fade"
            data-aos-duration="500"
          >
            <router-link
              to="/products/purchase"
              @click="
                showSideNav();
                $router.push('/products/purchase');
              "
              >{{ $t("purchase") }}</router-link
            >
            <router-link
              to="/products/sales"
              @click="
                showSideNav();
                $router.push('/products/sales');
              "
              >{{ $t("sales") }}</router-link
            >
            <router-link
              to="/products/production"
              @click="
                showSideNav();
                $router.push('/products/production');
              "
              >{{ $t("production") }}</router-link
            >
            <router-link
              to="/products/warehouse"
              @click="
                showSideNav();
                $router.push('/products/warehouse');
              "
              >{{ $t("warehouse") }}</router-link
            >
            <router-link
              to="/products/dms"
              @click="
                showSideNav();
                $router.push('/products/dms');
              "
              >{{ $t("dms") }}</router-link
            >
            <router-link
              to="/products/finance"
              @click="
                showSideNav();
                $router.push('/products/finance');
              "
              >{{ $t("finance") }}</router-link
            >
            <router-link
              to="/products/human-resources"
              @click="
                showSideNav();
                $router.push('/products/human-resources');
              "
              >{{ $t("hr") }}</router-link
            >
            <hr />
          </div>
          <router-link
            v-if="$isMobile()"
            :to="'/contact'"
            class="nav"
            :class="[{ sidenav_r: sidenav_class }, { navy: navy }]"
            @click="showSideNav()"
            @mouseover="giveindex(5)"
            ref="mainRoutes"
          >
            <div class="link-title">
              {{ $t("contact") }}
            </div>
          </router-link>
          <li>
            <LanguageBar
              class="mt-4 languages"
              @click="showSideNav"
            ></LanguageBar>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import Links from "./NavigationLinks.vue";

import ClosingButton from "../Forms/buttons/ClosingButton.vue";
import LanguageBar from "../LanguageBar.vue";

export default {
  name: "Sidenav",

  components: {
    Links,
    ClosingButton,
    LanguageBar,
  },
  props: {
    routes: { type: Array },

    showSideNav: { type: Function },
  },
  data() {
    return {
      presentation: true,
      sidenav_routes: [
        {
          to: "/",
          name: "home",
          isActive: true,
          id: 0,

          classname: "sidenav",
        },
        {
          to: "/",
          name: "about",
          isActive: false,
          id: 1,

          classname: "sidenav",
        },
        {
          to: "/",
          name: "services",
          isActive: false,
          id: 3,

          classname: "sidenav",
        },
        {
          to: "/",
          name: "contact",
          isActive: false,
          id: 4,

          classname: "sidenav",
        },
      ],
    };
  },
  methods: {
    ...mapMutations({
      setSideNav: "setSideNav",
      setIsProductsActive: " setIsProductsActive",
      setCurrentSubroute: "setCurrentSubroute",
    }),
  },

  computed: {
    ...mapGetters(["getSideNav", "getIsProductsActive"]),

    isProductsOpened() {
      return this.getIsProductsActive;
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.mobile-sub-nav {
  display: flex;
  flex-direction: column;
  font-size: 17px;
  margin-top: -15px;
  position: relative;

  li {
    line-height: 1rem !important;
  }
}

hr {
  padding: 0;
}

a {
  height: 30px;
}

.languages {
  margin-left: 4px;
  margin-top: 50px !important;
}

.sidenav {
  .sidenav-content {
    position: fixed;
    display: flex;
    justify-content: center;
    text-align: center;
    top: 0;
    padding-top: 25%;
    right: -28%;
    width: 0%;
    height: 100vh !important;
    background-color: #ffffff;
    transition: all 0.4s;

    a {
      color: rgb(19, 114, 138);

      &:hover {
        border-bottom: none;
        color: gray;
      }
    }
  }

  .nav-links-vertical {
    display: inline-flex;
    flex-direction: column;
    line-height: 3rem;
    padding-left: 4%;

    ul {
      li {
        text-align: left;

        a {
          color: gray;
          font-size: 23px;
        }
      }
    }
  }

  .nav {
    font-size: 20px;
    margin-left: 28px;
    color: rgb(156, 156, 156) !important;
  }

  .sidenav_times {
    position: absolute;
    top: 2.5%;
    left: 83%;
    width: 35px;
    height: 35px;
  }

  .top-bar {
    width: 200px;
    height: 50px;
  }

  @media screen and (min-width: 1500px) {
    .sidenav_content_visible {
      right: 0%;
    }
  }

  @media screen and (max-width: 1450px) {
    .sidenav_content_visible {
      width: 22.5%;
      right: 0%;
    }
  }

  @media screen and (max-width: 1400px) {
    .sidenav_content_visible {
      right: 0%;
      width: 27.5%;
    }
  }

  @media screen and (max-width: 1024px) {
    .sidenav_content_visible {
      right: 0%;
      width: 32.5%;
    }
  }

  @media screen and (max-width: 900px) {
    .sidenav_content_visible {
      right: 0%;
      width: 35.5%;
    }
  }

  @media screen and (max-width: 768px) {
    .sidenav_content_visible {
      right: 0%;
      width: 40.5%;
    }
  }

  @media screen and (max-width: 600px) {
    .sidenav_content_visible {
      right: 0%;
      width: 50.5%;
    }
  }

  @media screen and (max-width: 560px) {
    .grid {
      z-index: 7;
      left: -210%;
      width: 100%;
      height: 120vh;
      overflow-y: scroll;
    }

    .sidenav_content_visible {
      width: 100%;
      right: 0%;
      height: 130vh;
    }

    z-index: 0;

    .sidenav-content {
      .button_device {
        left: 50%;
      }
    }
  }
}
</style>
