<template>
  <div class="fixed-assets text-start">
    <div class="fixed-assets-header orange-text-main">
      <h2>{{ $t('products_finance_assets_text_1') }}</h2>
      <h4 class="text-muted">{{ $t('products_finance_assets_text_2') }}</h4>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="assets-cover d-flex justify-content-center align-self-center gap-5 p-5 mt-5 mb-5">
        <div class="asset-item">
          <div class="d-flex flex-column align-items-center mb-2">
            <font-awesome-icon icon="fa-file-contract" size="2xl" />
            <h5 class="text">{{ $t('products_fixed_asset_header_1') }}</h5>
          </div>
        </div>
        <div class="asset-item">
          <div class="d-flex flex-column align-items-center mb-2">
            <font-awesome-icon icon="fa-file-invoice" size="2xl" />
            <h5 class="text">{{ $t('products_fixed_asset_header_2') }}</h5>
          </div>
        </div>
        <div class="asset-item">
          <div class="d-flex flex-column align-items-center mb-2">
            <font-awesome-icon icon="fa-magnifying-glass-location" size="2xl" />
            <h5 class="text">{{ $t('products_fixed_asset_header_3') }}</h5>
          </div>
        </div>
        <div class="asset-item">
          <div class="d-flex flex-column align-items-center mb-2">
            <font-awesome-icon icon="fa-table" size="2xl" />
            <h5 class="text">{{ $t('products_fixed_asset_header_4') }}</h5>
          </div>
        </div>
        <div class="asset-item">
          <div class="d-flex flex-column align-items-center mb-2">
            <font-awesome-icon icon="fa-qrcode" size="2xl" />
            <h5 class="text">{{ $t('products_fixed_asset_header_5') }}</h5>
          </div>
        </div>
        <div class="asset-item">
          <div class="d-flex flex-column align-items-center mb-2">
            <font-awesome-icon icon="fa-chart-pie" size="2xl" />
            <h5 class="text">{{ $t('products_fixed_asset_header_6') }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
h2 {
  color: #25435b;
}

.assets-cover {
  width: 100%;
  background-color: white;
  border-radius: 20px;
  border-color: rgb(0, 59, 114);
  border-style: solid;
}

.asset-item {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.text {
  text-align: center;
  margin-top: 10px;
}

@media screen and (max-width: 767px) {
  .assets-cover {
    flex-direction: column;
    align-items: center;
  }

  .asset-item {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 576px) {
  .assets-cover {
    padding: 20px;
  }
}
@media screen and (max-width: 991px) {
  .assets-cover {
    flex-direction: column;
    align-items: center;
  }

  .asset-item {
    margin-bottom: 20px;
  }
}
</style>
