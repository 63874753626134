<template>
  <page-news-layout>
    <div>
      <h2>{{ $t("about_us_news_right_flex_two") }}</h2>
    </div>
    <div v-for="c in content" :key="c">
      <div>
        <p>
          {{ $t(c.p) }}
        </p>
      </div>
    </div>
    <div class="mt-5 mb-5">
      <base-link-button link="/services"></base-link-button>
    </div>
  </page-news-layout>
</template>
<script>
import BaseLinkButton from "@/components/BaseLinkButton.vue"
import PageNewsLayout from "@/components/PageNewsLayout.vue"
export default {
  components: { BaseLinkButton, PageNewsLayout },
  name: "SoftwareSolutions",
  data() {
    return {
      chips: [
        { chip: "#software-development" },
        { chip: "#erp" },
        { chip: "#design" }
      ],

      content: [
        { p: "software_solutions_p1" },
        { p: "software_solutions_p2" },
        { p: "software_solutions_p3" },
        { p: "software_solutions_p4" },
        { p: "software_solutions_p5" },
        { p: "software_solutions_p6" },
        { p: "software_solutions_p7" },
        { p: "software_solutions_p8" }
      ]
    }
  }
}
</script>
