<template>
  <div class="choose backgound">
    <div class="choose-flex">
      <div class="mobile">
        <small>{{ $t("about_us_choose_small_heading") }}</small>
        <h2 class="text-dark">{{ $t("about_us_choose_heading") }}</h2>
        <p>{{ $t("about_us_aditional") }}</p>
        <p class="line"></p>
      </div>
      <div class="choose-widget-frame">
        <div class="widget-frame"></div>
        <div class="widget-photo"></div>
        <div class="widget-video"></div>
      </div>
      <div class="choose-text">
        <div class="desktop">
          <small>{{ $t("about_us_choose_small_heading") }}</small>
          <h2 class="text-dark">{{ $t("about_us_choose_heading") }}</h2>
          <p>{{ $t("about_us_aditional") }}</p>
          <p class="line"></p>
        </div>

        <p>
          {{ $t("about_us_paragraph") }}
        </p>
        <div class="checkmarks">
          <div v-for="check in checkmarks" class="checkmark-tab" :key="check">
            <p>
              <font-awesome-icon icon="fa-solid fa-check" />
            </p>
            <p>{{ $t(check.p) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BaseButton from "../Forms/buttons/BaseButton.vue";

export default {
  name: "AboutChoose",
  components: { BaseButton },
  data() {
    return {
      routes: { route: "/", name: "read" },
      checkmarks: [
        { p: "about_check_one" },
        { p: "about_check_two" },
        { p: "about_check_three" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.choose {
  width: 100%;
  height: 60vh;
  margin-top: 10%;
  padding: 40px;
  background-color: rgb(238, 238, 238);

  .desktop {
    display: block !important;
  }

  .mobile {
    display: none !important;
  }

  .choose-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 93%;
    margin: auto;
    height: 100%;
    gap: 5px;

    .choose-text {
      width: 70%;
      height: 100%;
      // border: 1px solid black;
      text-align: left;
      padding-top: 5%;
      padding-right: 9%;

      .line {
        width: 50px;
        height: 2px;
        background-color: cornflowerblue;
        display: inline-flex;
        justify-content: left;
      }
    }

    .choose-widget-frame {
      width: 100%;
      height: 100%;

      // border: 1px solid black;
      position: relative;

      .widget-frame {
        position: absolute;
        width: 42%;
        height: 90%;
        border: 23px solid rgba(8, 40, 67, 0.87);
        // border: 23px solid rgb(255, 97, 0);
        top: 0;
        right: 5%;
      }

      .widget-photo {
        width: 50%;
        height: 60%;
        background: linear-gradient(
            to right,
            rgba(8, 40, 67, 0.4),
            rgba(8, 40, 67, 0.4),
            rgba(8, 40, 67, 0.4)
          ),
          url("@/assets/images/widget.png") no-repeat center center/cover;
        position: absolute;
        top: 8%;
        left: 30%;
      }

      .widget-video {
        width: 50%;
        height: 50%;
        background: linear-gradient(
            to right,
            rgba(8, 40, 67, 0.4),
            rgba(8, 40, 67, 0.4),
            rgba(8, 40, 67, 0.4)
          ),
          url("@/assets/images/product_item_1.jpg") no-repeat center
            center/cover;
        position: absolute;
        top: 32%;
        left: 15.5%;

        iframe {
          width: 100%;
          height: 100%;
        }
      }
    }

    .checkmarks {
      margin-top: 5%;

      .checkmark-tab {
        display: flex;
        gap: 10px;
        align-items: center;

        svg {
          color: white;
          background-color: cornflowerblue;
          border-radius: 50%;
          padding: 3px;
          font-size: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    height: 100%;
    padding: 10px;

    .background {
      padding-top: 25px !important;
    }

    .choose-text {
      position: relative !important;
      z-index: 1 !important;
      top: -100px !important;
      left: 5px;
      width: 100% !important;
    }

    .choose-flex {
      width: 100%;
      height: 70% !important;
      flex-direction: column;
      gap: 15px;

      .choose-widget-frame {
        width: 100%;
        height: 50vh;

        // border: 1px solid black;
        position: relative;

        .widget-frame {
          display: none;
        }

        .widget-photo {
          display: none;
        }

        .widget-video {
          top: 0%;
          left: 0;
          width: 100%;
          height: 70%;
        }
      }
    }

    .desktop {
      display: none !important;
      margin-top: 25px !important;
    }

    .mobile {
      display: block !important;
      margin-top: 25px !important;
      width: 100%;
    }

    p {
      font-size: 18px;
    }
  }
}
</style>
