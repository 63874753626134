<template lang="">
  <page-layout>
    <h2>{{ $t("products_warehouse_heading") }}</h2>
    <h4 class="text-muted mb-4">{{ $t("products_warehouse_heading_two") }}</h4>

    <h5 class="mb-4">
      {{ $t("products_warehouse_text_one") }}
    </h5>
    <h5 class="mb-2">
      {{ $t("products_warehouse_bold") }}
    </h5>
    <div class="col-12">
      <div
        v-for="product in products"
        :key="product"
        class="d-inline-flex pt-2"
      >
        <div class="align-self-center">
          <img src="@/assets/images/check.png" alt="check" />
        </div>
        <div>
          <h5 class="pt-1">{{ $t(product.text) }}</h5>
        </div>
      </div>
    </div>
    <br />
  </page-layout>
</template>
<script>
import PageLayout from "@/components/PageLayout.vue"
export default {
  components: {
    PageLayout
  },
  name: "TheWarehouse",
  data() {
    return {
      products: [
        { text: "products_warehouse_text_two" },
        { text: "products_warehouse_text_three" },
        { text: "products_warehouse_text_four" },
        { text: "products_warehouse_text_five" },
        { text: "products_warehouse_text_seven" },
        { text: "products_warehouse_text_six" }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
h2 {
  color: #25435b;
}
img {
  width: 35px;
  padding-right: 15px;
}
</style>
